import { useCallback, useEffect } from 'react';

import Skeleton from '@mui/material/Skeleton';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import { Banner } from '@/components/atoms/Banner/Banner';
import Value from '@/components/organisms/Entities/Value/Value';
import SubHeaderEntity from '@/components/organisms/SubHeader/SubHeaderEntity';
import SubNavEntity from '@/components/organisms/SubNav/SubNavEntity';
import { makeItemsName } from '@/components/organisms/SubNav/utils';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import { useAccount } from '@/hooks/useAccount';
import useDialogNodes from '@/hooks/useDialogNodes';
import useEntities from '@/hooks/useEntities';
import useValues from '@/hooks/useValues';
import { actions } from '@/models/permissions';
import { PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { popModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { setEntityName } from '@/redux/session/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { clearEntityValues, setEntity } from '@/redux/values/actions';
import { pageView } from '@/segment/segment';

import styles from './Entity.module.scss';

const Entity = () => {
  const { t } = useTranslation();
  const { brainId, entityName } = useParams();
  const sessionBrainId = useSelector(selectBrainId);
  const { entity, entities } = useEntities(brainId, entityName);

  useDialogNodes({ type: 'entity' });

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  const { slug } = useAccount();
  const { values, findConnectedDialogsForValues, isDraft } = useValues(
    brainId,
    entityName
  );

  useTitle(t('pages.entity', { 0: entityName }));
  useEffect(() => {
    pageView(PageName.ENTITY);
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDialogNameClick = useCallback(
    (dialog_id) => {
      dispatch(popModal());
      navigate(`/${slug}/brains/${brainId}/dialogs/${dialog_id}`);
    },
    [brainId, dispatch, navigate, slug]
  );

  useEffect(() => {
    if (sessionBrainId && !isDraft) {
      dispatch(setEntityName(entityName));
    }
  }, [dispatch, entityName, isDraft, sessionBrainId]);

  useEffect(() => {
    if (entity && !isDraft) {
      dispatch(setEntity(entity));
      return () => {
        dispatch(clearEntityValues());
      };
    }
  }, [dispatch, entity, isDraft]);

  useEffect(() => {
    if (isDraft) {
      const newEntityName = makeItemsName(entities, 'entity', 'Entity_name');
      dispatch(
        setEntity({
          entity: newEntityName,
          values: [],
        })
      );
      return () => {
        clearEntityValues();
      };
    }
  }, [dispatch, entities, entityName, isDraft]);

  const renderSkeletons = useCallback(
    () =>
      Array(5)
        .fill('')
        .map((_, i) => (
          <Skeleton
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            className="entity__input"
            animation="wave"
            variant="rectangular"
            height={48}
          />
        )),
    []
  );

  return (
    <>
      {!canWrite && (
        <Banner variant="neutral" relativePosition centered padding="small">
          {t('permissions.read_only')}
        </Banner>
      )}

      <div
        className={cn(styles.wrapper, {
          [styles.readOnly]: !canWrite,
        })}
      >
        <SubNavEntity />
        <SubHeaderEntity />
        <PageContentWrapper fullHeight>
          <ul>
            {!values && renderSkeletons()}
            {values &&
              values.map(({ id }) => (
                <Value
                  key={id}
                  id={id}
                  readOnly={!canWrite}
                  findConnectedDialogs={findConnectedDialogsForValues}
                  handleDialogNameClick={handleDialogNameClick}
                />
              ))}
            {values && (
              <Value id="" readOnly={!canWrite} key={values?.length} />
            )}
          </ul>
        </PageContentWrapper>
      </div>
    </>
  );
};

export default Entity;
