import { Props } from '@/models/icon';

export const ImportIcon = ({
  size = 16,
  color = 'var(--icon-default-blue)',
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.5 5.282h2a1 1 0 0 1 1 1V12.5a1 1 0 0 1-1 1h-10a1 1 0 0 1-1-1V6.282a1 1 0 0 1 1-1h2m1 2.164 2 2.473m0 0 2-2.473m-2 2.473V2.5"
    />
  </svg>
);
