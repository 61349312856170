import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Backdrop from '@mui/material/Backdrop';
import Breadcrumbs from '@mui/material/Breadcrumbs/Breadcrumbs';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import Button from '@/components/atoms/Button/Button/Button';
import IconButton from '@/components/atoms/IconButton/IconButton';
import IconClose from '@/components/atoms/Icons/Close';
import useBrains from '@/hooks/useBrains';
import { ImportIcon } from '@/modules/aiAgents/icons/ImportIcon';
import { BreadcrumpIcon } from '@/modules/onboarding/icons/BreadcrumpIcon';
import Separator from '@/modules/onboarding/icons/Separator';
import { popModal } from '@/redux/modals/actions';
import { aiAgentSchemas } from '@/util/validator';

import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';

import styles from './ModalAIAgentCreate.module.scss';

type BreadcrumbState = 'current' | 'not-reached' | 'completed';
type Step1Form = {
  brain_type: string;
};
type Step2Form = {
  name: string;
  language: string;
};
type Step3Form = {
  company_name: string;
  company_info: string;
};
export type Form = Step1Form & Partial<Step2Form> & Partial<Step3Form>;

// Constants
const BASIC_STEPS = [
  { component: Step1, validationSchema: aiAgentSchemas.step1Schema },
  {
    component: Step2,
    validationSchema: aiAgentSchemas.step2Schema,
  },
] as const;

const EXTENDED_STEPS = [
  ...BASIC_STEPS,
  {
    component: Step3,
    validationSchema: aiAgentSchemas.step3Schema,
  },
] as const;

export const ModalAIAgentCreate = () => {
  // Custom hooks
  const { t } = useTranslation();
  const { hasSellerBrain } = useBrains();
  const dispatch = useDispatch();

  // Local state
  const [step, setStep] = useState(0);

  // Local variables
  const BREADCRUMB_TEXTS = [
    t('ai_agents.modal.steps.type'),
    t('ai_agents.modal.steps.setup'),
    t('ai_agents.modal.steps.info'),
  ] as const;
  const showLastStep = !hasSellerBrain;
  const isLastStep = showLastStep ? step === 2 : step === 1;
  const steps = showLastStep ? EXTENDED_STEPS : BASIC_STEPS;
  const currentSchema = steps[step].validationSchema as yup.ObjectSchema<Form>;
  const CurrentStepComponent = steps[step].component;

  // RHF
  const methods = useForm<Form>({
    resolver: yupResolver(currentSchema) as Resolver<Form>,
    mode: 'onChange',
  });
  const {
    handleSubmit,
    trigger,
    formState: { isSubmitting, isValid },
    getValues,
  } = methods;

  // Handlers
  const increaseStep = async () => {
    if (step === 2) {
      return;
    }
    const isStepValid = await trigger();
    if (isStepValid) {
      setStep((prev) => prev + 1);
    }
  };

  const decreaseStep = async () => {
    if (step === 0) {
      return;
    }

    setStep((prev) => prev - 1);
  };

  const closeModal = () => {
    dispatch(popModal());
  };

  const handleImportBrainClick = () => {
    // TODO: This will implemented in the future
  };

  const onSubmit = (_data: Form) => {
    // TODO: This will implemented in the future
  };

  // Helpers
  const getBreadcrumbState = (breadCrumbIndex: number) => {
    if (breadCrumbIndex === step) {
      return 'current';
    } else if (breadCrumbIndex <= step - 1) {
      return 'completed';
    } else {
      return 'not-reached';
    }
  };

  const getStateConfig = (state: BreadcrumbState) => {
    const configMap = {
      current: {
        textColor: 'var(--icon-default-blue)',
        fill: '',
        stroke: 'var(--icon-default-blue)',
      },
      completed: {
        textColor: 'var(--text-default-gray)',
        fill: 'var(--text-default-gray)',
        stroke: 'var(--text-default-gray)',
      },
      'not-reached': {
        textColor: 'var(--text-placeholder-gray)',
        fill: 'var(--text-placeholder-gray)',
        stroke: 'var(--text-placeholder-gray)',
      },
    };

    return configMap[state];
  };

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={true}
        onClose={closeModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={true}>
          <div className={styles.modal}>
            <IconButton
              className={styles.modal__closeIcon}
              onClick={closeModal}
              ariaLabel={t('modals.close')}
            >
              <IconClose color="var(--icon-default-white)" size={20} />
            </IconButton>

            <section className={styles.modal__content}>
              <header className={styles.modal__header}>
                <Typography variant="h2-semi-bold" component="h1">
                  {t('ai_agents.modal.title')}
                </Typography>

                <Breadcrumbs
                  className={styles.breadcrumbs}
                  separator={<Separator />}
                >
                  {BREADCRUMB_TEXTS.map((breadcrumb, index) => {
                    const config = getStateConfig(getBreadcrumbState(index));

                    return (
                      <div
                        key={breadcrumb}
                        className={styles.breadcrumbs__item}
                      >
                        <BreadcrumpIcon
                          fill={config.fill}
                          stroke={config.stroke}
                        />
                        <Typography
                          color={config.textColor}
                          variant="label-caps-large"
                        >
                          {breadcrumb}{' '}
                        </Typography>
                      </div>
                    );
                  })}
                </Breadcrumbs>
              </header>

              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className={styles.modal__step}>
                    <CurrentStepComponent />
                  </div>

                  <footer className={styles.modal__footer}>
                    <div className={styles.modal__buttons}>
                      {step === 0 ? (
                        <Button
                          onClick={handleImportBrainClick}
                          variant="tertiary"
                          type="button"
                          size="medium"
                        >
                          <ImportIcon />
                          {t('ai_agents.import')}
                        </Button>
                      ) : (
                        <Button
                          onClick={decreaseStep}
                          variant="tertiary"
                          type="button"
                          size="medium"
                        >
                          {t('common.back')}
                        </Button>
                      )}

                      <Button
                        onClick={() => {
                          closeModal();
                        }}
                        variant="tertiary"
                        type="button"
                        size="large"
                      >
                        {t('common.cancel')}
                      </Button>

                      <Button
                        onClick={() => {
                          if (!isLastStep) {
                            increaseStep();
                            return;
                          }
                        }}
                        disabled={!isValid}
                        isLoading={isSubmitting}
                        size="large"
                        type={isLastStep ? 'submit' : 'button'}
                      >
                        {isLastStep ? t('common.create') : t('common.continue')}
                      </Button>
                    </div>
                  </footer>
                </form>
              </FormProvider>
            </section>

            <aside className={styles.modal__preview}>
              <img
                src={`/assets/aiAgents/modal/${getValues('brain_type') ?? 'default'}.png`}
                width="400"
                height="650"
                alt=""
              />
            </aside>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};
