import { useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InferType } from 'yup';

import FormFieldset from '@/components/atoms/Fieldset/templates/FormFieldset';
import Input from '@/components/atoms/Input/Input';
import { ZendeskIntegration } from '@/models/integration';
import { getZendeskAuthUrl } from '@/util/constants';
import { zendeskOAuthSchema } from '@/util/validator';

import styles from './styles.module.scss';

type Form = InferType<typeof zendeskOAuthSchema>;

interface AuthorizationProps {
  callbackUrl?: string;
  isUpdated: boolean;
  isUpdating: boolean;
  integration: ZendeskIntegration;
  updateIntegration: (integration: ZendeskIntegration) => void;
}

const createOAuthURL = (integration: ZendeskIntegration) => {
  const queryParams = new URLSearchParams({
    redirect_uri: integration?.config?.redirect_uri ?? '',
    subdomain: integration?.config?.subdomain ?? '',
    client_id: integration?.config?.client_id ?? '',
    response_type: 'code',
    scope: 'read write chat',
  });

  return `${getZendeskAuthUrl(integration?.config?.subdomain)}?${queryParams}`;
};

const AuthorizationFieldset = ({
  callbackUrl = '',
  isUpdated,
  isUpdating,
  integration,
  updateIntegration,
}: AuthorizationProps) => {
  const { t } = useTranslation();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    getValues,
  } = useForm<Form>({
    resolver: yupResolver(zendeskOAuthSchema) as Resolver<Form>,
    mode: 'onChange',
    defaultValues: integration?.config,
  });

  const zendeskUrl = createOAuthURL(integration);

  useEffect(() => {
    if (shouldRedirect && isUpdated && !isUpdating) {
      window.open(zendeskUrl, '_self');
      setShouldRedirect(false);
    }
  }, [isUpdated, isUpdating, shouldRedirect, zendeskUrl]);

  const onSubmit = useCallback<(form: Form) => void>(() => {
    const { client_secret, client_id, subdomain } = getValues();

    updateIntegration({
      ...integration,
      config: {
        client_secret,
        client_id,
        subdomain,
        redirect_uri: callbackUrl,
      },
    });

    setShouldRedirect(true);
  }, [callbackUrl, getValues, integration, updateIntegration]);

  return (
    <div>
      <FormFieldset
        title={t('integrations.zendesk.auth.title')}
        subtitle={t('integrations.zendesk.auth.subtitle')}
        primaryButton={{
          children: t('integrations.zendesk.auth.connect'),
          isLoading: false,
          disabled: !isValid,
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={styles.auth_inputs}>
          <Input
            name="client_id"
            label={t('integrations.zendesk.auth.client_id')}
            size="large"
            placeholder=""
            autoComplete="off"
            required
            error={!!errors.client_id}
            register={register('client_id', { required: true })}
          />
          <Input
            name="client_secret"
            label={t('integrations.zendesk.auth.client_secret')}
            size="large"
            placeholder=""
            autoComplete="off"
            required
            error={!!errors.client_secret}
            register={register('client_secret', { required: true })}
          />
          <Input
            name="subdomain"
            label={t('integrations.zendesk.auth.subdomain')}
            size="large"
            autoComplete="off"
            placeholder="mycompany"
            tooltip={t('integrations.zendesk.auth.identifier')}
            required
            error={!!errors.subdomain}
            register={register('subdomain', { required: true })}
          />
        </div>
      </FormFieldset>
    </div>
  );
};
export default AuthorizationFieldset;
