import { ReactNode, useCallback, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useTitle } from 'react-use';

import Button from '@/components/atoms/Button/Button/Button';
import NotFoundIcon from '@/components/atoms/Icons/NotFound';
import { PageName } from '@/models/segment';
import { pageView } from '@/segment/segment';

import styles from './NotFound.module.scss';

type NotFoundProps = {
  entity?: string;
  value?: string;
  children?: ReactNode;
};

const NotFound: React.FC<NotFoundProps> = (props: NotFoundProps) => {
  const { t } = useTranslation();
  const entity = props?.entity || t('pages.page');
  const value = props?.value || '';
  useTitle(t('pages.not_found'));

  useEffect(() => {
    pageView(PageName.NOT_FOUND);
  }, []);

  const onButtonClick = useCallback(() => {
    document.location.href = '/';
  }, []);

  return (
    <div className={styles.container}>
      <NotFoundIcon />
      <Typography
        component="div"
        fontWeight={500}
        fontSize="var(--space-36"
        lineHeight="var(--space-44)"
        className={styles.header}
      >
        {t('errors.not_found', { 0: entity, 1: value ? value + ' ' : '' })}
      </Typography>
      <Typography
        component="div"
        variant="h3-regular"
        className={styles.subheader}
      >
        {t('errors.not_found_link', { 0: entity })}
      </Typography>
      <div className={styles.button}>
        <Button onClick={onButtonClick}>{t('error_page.return_home')}</Button>
      </div>
    </div>
  );
};

export default NotFound;
