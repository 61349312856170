import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useAccount } from '@/hooks/useAccount';
import useDialogNodesCache from '@/hooks/useDialogNodesCache';
import useEntities from '@/hooks/useEntities';
import useValues from '@/hooks/useValues';
import { selectEntityName } from '@/redux/session/selectors';
import { selectEntityDraft } from '@/redux/values/selectors';

import SubNav from './SubNav';
import { makeCollectionName, makeCollections } from './utils';

interface CollectionProps {
  collection: string;
  new_collection: string;
}

const SubNavEntity = () => {
  const { t } = useTranslation();
  const { brainId, entityName } = useParams();
  const { new_entity: draftEntity, previous_entity } =
    useSelector(selectEntityDraft);
  const { slug } = useAccount();
  const sessionEntityName = useSelector(selectEntityName);

  const {
    entities,
    createDraftEntity,
    updateCollection,
    updateEntity,
    createStatus,
    handleCollectionUpdate,
  } = useEntities(brainId, entityName);
  const [lastCreatedCollection, setLastCreatedCollection] =
    useState<string>('');

  const { isDraft } = useValues(brainId, entityName);
  const entityDraft = useSelector(selectEntityDraft);

  const { findUsedNodes } = useDialogNodesCache();

  const getCollections = useCallback(() => {
    let formattedEntities =
      entities
        ?.map(({ entity: name, collection }) => {
          const isUsed = findUsedNodes({ brainId, name });
          const isSameNameAsPrevious = name === previous_entity;
          const displayName = isSameNameAsPrevious ? draftEntity : name;

          return {
            name: displayName,
            id: displayName,
            collection,
            link: `/${slug}/brains/${brainId}/entities/${name}`,
            usedInDialog: isUsed,
          };
        })
        .sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }) || [];

    if (isDraft) {
      const previousCollection = entities?.find(
        (e) => e.entity === sessionEntityName
      )?.collection;
      formattedEntities = [
        {
          name: entityDraft.new_entity,
          id: 'draft',
          collection: previousCollection ?? '',
          link: `/${slug}/brains/${brainId}/entities/draft`,
          usedInDialog: null,
        },
        ...formattedEntities,
      ];
    }

    const newCollections = makeCollections(formattedEntities);

    return newCollections;
  }, [
    brainId,
    draftEntity,
    entities,
    entityDraft.new_entity,
    findUsedNodes,
    isDraft,
    previous_entity,
    sessionEntityName,
    slug,
  ]);

  const handleCreateClick = useCallback(async () => {
    createDraftEntity();
  }, [createDraftEntity]);

  const onCreateCollection = useCallback(
    ({ id }) => {
      const collectionName = makeCollectionName(getCollections());
      updateEntity(
        { name: id, entity: { collection: collectionName } },
        {
          onSuccess: () => {
            setLastCreatedCollection(collectionName);
          },
        }
      );
    },
    [getCollections, updateEntity]
  );

  const onCollectionSubmit = useCallback(
    (data: CollectionProps) => {
      updateCollection(data);
    },
    [updateCollection]
  );
  return (
    <SubNav
      title={t('common.entity')}
      navTitle={t('common.entities')}
      skeleton={!getCollections()}
      collections={getCollections()}
      onCreateClick={handleCreateClick}
      onCollectionUpdate={handleCollectionUpdate}
      onCreateCollection={onCreateCollection}
      onCollectionSubmit={onCollectionSubmit}
      lastCreatedCollection={lastCreatedCollection}
      isCreating={createStatus === 'pending'}
      subnavType="entities"
    />
  );
};

export default SubNavEntity;
