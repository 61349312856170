import React, { useCallback, useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import isNil from 'lodash/isNil';
import moment, { Moment } from 'moment';
import { DayPickerSingleDateController } from 'react-dates';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useClickAway } from 'react-use';

import DropdownList from '@/components/atoms/DropdownList/DropdownList';
import Calendar from '@/components/atoms/Icons/Calendar';
import Clock from '@/components/atoms/Icons/Clock';
import Select from '@/components/atoms/Select/Select';
import Switch from '@/components/atoms/Switch/Switch';
import TitleSubtitle from '@/components/atoms/TitleSubtitle/TitleSubtitle';
import {
  TIME_DROPDOWN_INTERVAL,
  timeIntervalsMaker,
} from '@/components/pages/BusinessHours/utils';
import useBrains from '@/hooks/useBrains';
import { OptionBase } from '@/models/common';
import { actions as permissionsActions } from '@/models/permissions';
import { EventName } from '@/models/segment';
import { RootState } from '@/models/state';
import ActionConfiguration from '@/modules/rules/components/ActionConfiguration/ActionConfiguration';
import { getPermissions } from '@/redux/permissions/selectors';
import { trackEvent } from '@/segment/segment';
import { delay } from '@/util/util';

import { Form, BrainVersion, FieldsetStatus } from '../../models';
import { actions } from '../../redux/actions';
import { selectBroadcast } from '../../redux/selectors';
import { SEND_NOW } from '../../utils';
import StepFieldset from '../StepFieldset/StepFieldset';

import styles from './SettingsFieldset.module.scss';

export const periodOptions = [
  { title_key: 'analytics.periods.send_now' },
  { title_key: 'analytics.periods.today', days: 0 },
  { title_key: 'analytics.periods.tomorrow', days: 1 },
  { title_key: 'analytics.periods.in_a_week', days: 7 },
  { title_key: 'analytics.periods.in_a_month', days: 30 },
  { title_key: 'analytics.periods.choose_date' }, // custom period must remain last in this array
];

export const optionsSessionTimeout = Array.from({ length: 7 }, (_, index) => ({
  label: (index + 1).toString(),
  value: (index + 1) * 60 * 60 * 24,
}));

const getHourOptions = (isScheduledForToday = false) => {
  const hourOptions = timeIntervalsMaker(TIME_DROPDOWN_INTERVAL);
  if (isScheduledForToday) {
    const currentMinutes =
      Number(moment().format('H')) * 60 + Number(moment().format('m'));

    const newHourOptions = hourOptions.filter(
      (option) => currentMinutes <= Number(option.value)
    );
    return newHourOptions;
  }
  return hourOptions;
};
interface Props {
  isReadOnly: boolean;
}

export const SettingsFieldset = ({ isReadOnly }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const brainRef = useRef(null);
  const [openBrainModal, setOpenBrainModal] = useState(false);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'broadcasts', permissionsActions.WRITE)
  );

  const broadcast = useSelector(selectBroadcast);
  const [showSessionTimeout, setShowSessionTimeout] = useState(false);

  const { setValue, watch, control, register } = useFormContext<Form>();
  const [stepStatus, setStepStatus] = useState<FieldsetStatus>('disabled');
  const scheduleDate = watch('scheduleDate');
  const scheduleDateExists = scheduleDate && scheduleDate !== SEND_NOW;
  const scheduleHour = watch('scheduleHour');
  const template = watch('template');
  const calendarRef = useRef(null);
  const [calendarState, setCalendarState] = useState(
    scheduleDateExists ? moment(scheduleDate) : moment()
  );
  const [focusedInput, setFocusedInput] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);

  useEffect(() => {
    if (broadcast?.session_timeout) {
      setShowSessionTimeout(true);
    }
  }, [broadcast?.session_timeout]);

  useEffect(() => {
    if (template && stepStatus !== 'completed') {
      setStepStatus('active');
    }
    if (template && scheduleDate) {
      setStepStatus('completed');
    }
  }, [scheduleDate, stepStatus, template]);

  const { brain } = useBrains(broadcast?.brain_parent_id);

  useClickAway(brainRef, () => {
    setOpenBrainModal(false);
  });

  const handleCalendarClose = () => {
    setValue('scheduleDate', calendarState.format('YYYY-MM-DD'));
    setShowCalendar(false);
  };

  useClickAway(calendarRef, async () => {
    await delay(200);
    if (showCalendar) {
      handleCalendarClose();
    }
  });

  const options = periodOptions.map((option) => ({
    label: t(option.title_key),
    value: !isNil(option?.days) ? String(option.days) : null,
  }));
  const handleDateClick = (data: OptionBase) => {
    if (data.label === t(periodOptions[0].title_key)) {
      trackEvent(EventName.ClickBroadCastSendNow);
      setValue('scheduleDate', SEND_NOW);
      return;
    }
    trackEvent(EventName.ClickBroadCastSchedule);

    if (isNil(data.value)) {
      setShowCalendar((prev) => !prev);
      return;
    }
    const date = moment().add(data.value, 'days').format('YYYY-MM-DD');
    setValue('scheduleDate', date);
  };

  const handleSingleDayClick = (data: Moment) => {
    setCalendarState(data);
    setValue('scheduleDate', data.format('YYYY-MM-DD'));
  };

  const handleHourClick = (data) => {
    const hour = getHourOptions().find((option) => option.value === data.value);
    setValue('scheduleHour', hour.label);
  };

  const handleBrainSubmit = (newBrain: BrainVersion) => {
    setOpenBrainModal(false);
    dispatch(actions.updateBroadcast(newBrain));
  };

  const handleSessionTimeoutSelect = useCallback(() => {
    if (!showSessionTimeout) {
      setValue('session_timeout', optionsSessionTimeout[6].value);
    } else {
      setValue('session_timeout', null);
    }
    setShowSessionTimeout((prev) => !prev);
  }, [setValue, showSessionTimeout]);

  const isScheduledForToday = scheduleDate === moment().format('YYYY-MM-DD');
  const isDisabled = !canWrite || stepStatus === 'disabled';
  const isHourSelectionDisabled = scheduleDate === SEND_NOW || isDisabled;

  const showAssignedBrainName = useCallback(() => {
    const showDeletedBrainName =
      broadcast?.metadata?.brain_name &&
      !broadcast.brain_parent_id &&
      broadcast.status === 'sent';

    if (showDeletedBrainName) {
      return `${broadcast?.metadata?.brain_name} - v${broadcast?.metadata?.brain_version}`;
    }
    if (broadcast?.brain_parent_id) {
      return `${brain?.name} - v${broadcast.brain_version}`;
    }
    return t('common.select');
  }, [
    brain?.name,
    broadcast.brain_parent_id,
    broadcast.brain_version,
    broadcast?.metadata?.brain_name,
    broadcast?.metadata?.brain_version,
    broadcast.status,
    t,
  ]);

  return (
    <>
      <StepFieldset
        title={t('common.settings')}
        step={3}
        status={isReadOnly ? 'completed' : stepStatus}
      >
        <TitleSubtitle
          title={t('broadcasts.schedule')}
          subtitle={t('broadcasts.select_schedule')}
          size="large"
        />

        <div className={styles.dateContainer}>
          <div>
            <DropdownList
              options={options}
              size="auto"
              height="medium"
              optionClick={handleDateClick}
              disabled={isDisabled || isReadOnly}
            >
              <div className={styles.buttonContainer}>
                <Calendar color="var(--icon-default-gray)" />
                {!scheduleDate ? (
                  <>{t('broadcasts.select_date')}</>
                ) : (
                  <>{scheduleDate}</>
                )}
              </div>
            </DropdownList>
            {showCalendar && (
              <div ref={calendarRef} className={styles.calendar}>
                <DayPickerSingleDateController
                  onDateChange={handleSingleDayClick}
                  onFocusChange={({ focused }) => setFocusedInput(focused)}
                  date={calendarState}
                  focused={focusedInput}
                  initialVisibleMonth={null}
                  hideKeyboardShortcutsPanel
                  minDate={moment()}
                  isOutsideRange={(day) => day.isBefore(moment())}
                />
              </div>
            )}
          </div>
          <div className={styles.hourPicker}>
            <DropdownList
              optionClick={handleHourClick}
              options={getHourOptions(isScheduledForToday)}
              height="medium"
              noGutter
              size="auto"
              disabled={isHourSelectionDisabled || isReadOnly}
            >
              <div className={styles.buttonContainer}>
                <Clock size={14} color="var(--icon-default-gray)" />
                {!scheduleHour ? (
                  <>{t('common.select')}</>
                ) : (
                  <>{scheduleHour}</>
                )}
              </div>
            </DropdownList>
          </div>
        </div>

        <Box mt={2}>
          <TitleSubtitle
            title={t('broadcasts.brain')}
            subtitle={t('broadcasts.select_brain')}
            size="large"
          />
          <div className={styles.brainContainer}>
            <DropdownList
              options={[]}
              size="xlarge"
              height="medium"
              disabled={isDisabled || isReadOnly}
              optionCallbackFn={() => {
                setOpenBrainModal(true);
                throw new Error();
              }}
            >
              <div className={styles.brainsLabel} ref={brainRef}>
                <Typography>{showAssignedBrainName()}</Typography>
              </div>
            </DropdownList>

            {openBrainModal && (
              <ActionConfiguration
                ref={brainRef}
                onSubmit={handleBrainSubmit}
                disconnectButton
                renderOverElement
              />
            )}
          </div>
        </Box>
        <Box className={styles.switchColumn}>
          <Controller
            name="force_new_sessions"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Switch
                checked={value}
                onChange={onChange}
                disabled={stepStatus === 'disabled' || isReadOnly}
              />
            )}
          />
          <TitleSubtitle
            title={t('broadcasts.new_conversation')}
            subtitle={t('broadcasts.new_conversation_subtitle')}
          />
        </Box>
        <Box>
          <div className={styles.switchColumn}>
            <Switch
              checked={showSessionTimeout}
              onChange={handleSessionTimeoutSelect}
              disabled={stepStatus === 'disabled' || isReadOnly}
            />
            <TitleSubtitle
              title={t('conversations.expiration_time')}
              subtitle={t('conversations.expiration_time_subtitle')}
            />
          </div>
          {showSessionTimeout && (
            <div className={styles.switchColumn}>
              <Select
                placeholder={t('common.select')}
                name="session_timeout"
                register={register('session_timeout')}
                options={optionsSessionTimeout}
                label={t('broadcasts.days')}
                disabled={stepStatus === 'disabled' || isReadOnly}
              />
            </div>
          )}
        </Box>
      </StepFieldset>
    </>
  );
};
