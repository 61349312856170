import { useMemo, memo, useCallback, useState, useRef, useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { UseMutateFunction } from '@tanstack/react-query';
import cn from 'classnames';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Banner } from '@/components/atoms/Banner/Banner';
import IconButton from '@/components/atoms/IconButton/IconButton';
import CollectionIcon from '@/components/atoms/Icons/Collection';
import DatasourceDocument from '@/components/atoms/Icons/DatasourceDocument';
import Disconnect from '@/components/atoms/Icons/Disconnect';
import Duplicate from '@/components/atoms/Icons/Duplicate';
import Edit from '@/components/atoms/Icons/Edit';
import Globe from '@/components/atoms/Icons/Globe';
import KnowledgeBase from '@/components/atoms/Icons/KnowledgeBase';
import Trash from '@/components/atoms/Icons/Trash';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import {
  MODAL_FORM,
  MODAL_DELETE,
  MODAL_WARN,
} from '@/components/organisms/Modals/ModalConductor';
import { USAGE_PERCENTAGE_THRESHOLD } from '@/components/pages/Collections/util';
import { getFragmentsPercentage } from '@/components/pages/Datasource/utils';
import { useAccount } from '@/hooks/useAccount';
import useBrains from '@/hooks/useBrains';
import useCollections from '@/hooks/useCollections';
import useDatasources from '@/hooks/useDatasources';
import { Brain } from '@/models/brain';
import {
  Collection,
  Datasource,
  DatasourceType,
  PartialCollection,
} from '@/models/collections';
import { actions } from '@/models/permissions';
import { EventName } from '@/models/segment';
import { RootState } from '@/models/state';
import { popModal, pushModal } from '@/redux/modals/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountSlug } from '@/redux/session/selectors';
import { trackEvent } from '@/segment/segment';
import { preventClickThrough } from '@/util/util';
import { collectionRules } from '@/util/validator';

import Tile from '../Tile';
import TileMenu from '../TileMenu';

import styles from './TileCollection.module.scss';

interface DeleteProps {
  collection_id: string;
  force: boolean;
}
interface Props {
  collection: Collection;
  brain_id?: string;
  deleteCollection?: UseMutateFunction<Collection, Error, DeleteProps, unknown>;
  updateCollection?: UseMutateFunction<
    Collection,
    Error,
    PartialCollection,
    unknown
  >;
}

export const getIcon = (
  type: DatasourceType,
  color = 'var(--icon-default-blue)',
  size = 16
) => {
  switch (type) {
    case DatasourceType.WEBSITE:
      return <Globe size={size} color={color} />;
    case DatasourceType.FILES:
      return <DatasourceDocument size={size} color={color} />;
    case DatasourceType.ZENDESK_KB:
    case DatasourceType.INTERCOM_KB:
      return <KnowledgeBase size={size} color={color} />;
    default:
      return null;
  }
};

const MAX_DATASOURCE_LENGTH = 10;

const TileCollection = ({
  collection,
  brain_id,
  deleteCollection,
  updateCollection,
}: Props) => {
  const [tooltipMessage, setTooltipMessage] = useState('');
  const { t } = useTranslation();
  const slug = useSelector(selectAccountSlug);
  const navigate = useNavigate();
  const { account } = useAccount();
  const { collection_id } = collection ?? {};

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'collections', actions.WRITE)
  );
  const canDelete = useSelector((state: RootState) =>
    getPermissions(state, 'collections', actions.DELETE)
  );

  const { collectionNames } = useCollections();
  const { datasources } = useDatasources(collection_id);
  const { updateBrain, brainsByLanguage } = useBrains(brain_id);
  const intervalId = useRef(null);

  const { name, language } = collection ?? {};

  const dispatch = useDispatch();

  const handleDelete = useCallback(async () => {
    const connectedBrains = brainsByLanguage(collection?.language)?.filter(
      (b) => b.collection_id === collection_id
    );

    const brainWarning = (
      <Banner<Partial<Brain>>
        relativePosition
        variant="critical"
        referenceProp="brain_id"
        references={connectedBrains}
        labelKey="name"
        onRefClick={(id) => {
          dispatch(popModal());
          navigate(`/${slug}/brains/${id}`);
        }}
      >
        <Trans
          i18nKey="collections.delete_warning_brains"
          values={{
            collection: name,
          }}
        />
      </Banner>
    );

    const warnProps = {
      title: t('common.warning'),
      children: brainWarning,
      primaryButtonText: t('common.close'),
    };

    if (connectedBrains?.length > 0) {
      dispatch(popModal());
      dispatch(pushModal(MODAL_WARN, warnProps));
    } else {
      const warningMessage =
        datasources.length > 0
          ? 'collections.delete_warning_no_empty'
          : 'collections.delete_warning';
      const deleteProps = {
        subtitle: (
          <Trans i18nKey={warningMessage} values={[collection?.name]} />
        ),
        confirm: datasources?.length > 0,
        onDelete: () =>
          deleteCollection(
            {
              collection_id,
              force: datasources?.length > 0,
            },
            {
              onSuccess: () => {
                dispatch(popModal());
              },
            }
          ),
      };
      dispatch(pushModal(MODAL_DELETE, deleteProps));
    }
  }, [
    brainsByLanguage,
    collection?.language,
    collection?.name,
    collection_id,
    datasources?.length,
    deleteCollection,
    dispatch,
    name,
    navigate,
    slug,
    t,
  ]);

  const onEditClick = useCallback(() => {
    trackEvent(EventName.ClickEditCollection, { collection_id });
    const renameProps = {
      title: t('collections.edit_collection'),
      primaryButtonText: t('common.edit'),
      onCreate: async (
        data: {
          name: string;
        },
        onError
      ) => {
        updateCollection(
          {
            name: data.name,
            collection_id,
          },
          {
            onSuccess: () => {
              dispatch(popModal());
            },
            onError,
          }
        );
      },
      fields: [
        {
          fieldName: 'name',
          fieldValue: name,
          rules: collectionRules.name(collectionNames),
        },
      ],
    };
    dispatch(pushModal(MODAL_FORM, renameProps));
  }, [collectionNames, collection_id, dispatch, name, t, updateCollection]);

  useEffect(() => {
    return () => clearInterval(intervalId.current);
  }, []);

  let tileMenuItems = useMemo(
    () => [
      {
        name: t('common.edit'),
        icon: <Edit />,
        onClick: onEditClick,
      },

      {
        name: t('collections.copy_id'),
        icon: <Duplicate />,
        keepOpen: true,
        tooltipMessage,
        onClick: () => {
          trackEvent(EventName.ClickCopyCollectionId, {
            collection_id: collection.collection_id,
          });
          const text = collection.collection_id;
          navigator.clipboard.writeText(text);
          setTooltipMessage(t('common.copied'));
          intervalId.current = setTimeout(() => {
            setTooltipMessage('');
          }, 1200);
        },
      },

      {
        name: t('common.delete'),
        type: 'delete',
        disabled: !canDelete,
        icon: <Trash />,
        onClick: handleDelete,
      },

      {
        name: 'Disconnect',
        icon: <Disconnect />,
        disabled: !canWrite,
        onClick: () => {
          updateBrain({
            brain_id,
            collection_id: null,
          });
        },
      },
    ],
    [
      brain_id,
      canDelete,
      canWrite,
      collection?.collection_id,
      handleDelete,
      onEditClick,
      t,
      tooltipMessage,
      updateBrain,
    ]
  );

  if (brain_id) {
    tileMenuItems = [tileMenuItems[tileMenuItems.length - 1]];
  } else {
    tileMenuItems.splice(3);
  }

  const handleDatasourceNavigation = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
      if (e) {
        preventClickThrough(e);
      }
      navigate(`/${slug}/collections/${collection_id}/datasources/${id}`);
    },
    [collection_id, navigate, slug]
  );

  const dataSourceItems = useMemo(
    () =>
      datasources?.map((datasource: Datasource) => (
        <IconButton
          ariaLabel={datasource.name}
          tooltip={datasource.name}
          key={datasource.datasource_id}
          onClick={(e) =>
            handleDatasourceNavigation(e, datasource.datasource_id)
          }
        >
          {getIcon(datasource.type, 'var(--icon-default-gray)')}
        </IconButton>
      )) ?? [],
    [datasources, handleDatasourceNavigation]
  );

  const getDataSourceIcons = useCallback(() => {
    if (datasources?.length === 0) {
      return t('collections.datasources.no_datasources');
    }
    if (dataSourceItems?.length > MAX_DATASOURCE_LENGTH) {
      return (
        <>
          {dataSourceItems?.slice(0, MAX_DATASOURCE_LENGTH)}
          <span>+{dataSourceItems.length - MAX_DATASOURCE_LENGTH}</span>
        </>
      );
    }
    return dataSourceItems;
  }, [dataSourceItems, datasources?.length, t]);

  const percentage = getFragmentsPercentage(
    collection?.fragment_count,
    account?.max_fragments
  );

  const showFragmentBadge = percentage > USAGE_PERCENTAGE_THRESHOLD;
  return (
    <Link
      aria-label={name}
      className={cn(styles.link, styles.tile, 'tile')}
      to={`/${slug}/collections/${collection_id}`}
    >
      <Tile className={styles.wrapper}>
        <div className={styles.icon}>
          <CollectionIcon />
        </div>
        <div className={styles.info}>
          <div className={styles.header}>
            <Typography variant="subheading-semi-bold" className={styles.name}>
              {name}
            </Typography>
            <Typography
              className={styles.country}
            >{`(${language})`}</Typography>
            {showFragmentBadge && (
              <Box ml={1.5} mt={0.2}>
                <StatusBadge variant="warning" label={t('limits.data_heavy')} />
              </Box>
            )}
          </div>
          <Typography className={styles.desc}>
            {t('collections.datasources.datasources')}: {getDataSourceIcons()}
          </Typography>

          <Typography variant="label-caps-large" className={styles.footer}>
            {collection?.last_used_at ? (
              <>
                {t('common.last_used')}:{' '}
                {moment(collection?.last_used_at).fromNow()}
              </>
            ) : (
              t('common.not_used_yet')
            )}
          </Typography>
        </div>
        <div className={styles.menu}>
          <TileMenu menuItems={tileMenuItems} />
        </div>
      </Tile>
    </Link>
  );
};

export default memo(TileCollection);
