import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import { Accept, ErrorCode, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@/components/atoms/Button/Button/Button';
import useFileSubmit from '@/components/atoms/FileUpload/useFileSubmit';
import IconButton from '@/components/atoms/IconButton/IconButton';
import Attachment from '@/components/atoms/Icons/Attachment';
import Close from '@/components/atoms/Icons/Close';
import Emoji from '@/components/atoms/Icons/Emoji';
import Response from '@/components/atoms/Icons/Response';
import { EmojiPicker } from '@/components/organisms/EmojiPicker/EmojiPicker';
import { MODAL_CANNED_RESPONSES } from '@/components/organisms/Modals/ModalConductor';
import UploadCard from '@/modules/humanChat/components/Conversation/MessageArea/FileUploadChatBox/UploadCard/UploadCard';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { pushModal } from '@/redux/modals/actions';
import { selectSessionId } from '@/redux/session/selectors';
import { MAX_UPLOAD_SIZE } from '@/util/constants';
import { delay, preventClickThrough } from '@/util/util';

import QuickResponses from './QuickResponses';
import { ChatContext } from '../../context';
import { UploadedData, useChatBox } from '../ChatBox/useChatBox';

import styles from '../ChatBox/ChatBox.module.scss';

const ICON_SIZE = 20;
const MAX_FILES = 5;
// Uncomment when allow file upload implmementation is ready
// const CHECKBOX_CUSTOM_STYLE = {
//   color: 'var(--icon-default-gray)',
// };

type FileUploadChatBoxProps = {
  stream?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  files: any;
  setFiles: (x: string[]) => void;
  setUrls: (x: UploadedData[]) => void;
  uploading: boolean;
  setUploading: (x: boolean) => void;
  accept?: Accept;
  totalFiles?: number;
};

const FileUploadChatBox = ({
  accept,
  stream = false,
}: FileUploadChatBoxProps) => {
  const { t } = useTranslation();
  const [countdownToReady, setCountdownToReady] = useState(0);
  const dispatch = useDispatch();
  const [emojiPicker, setEmojiPicker] = useState(false);
  // Uncomment when allow file upload implmementation is ready
  // const [allowFileUpload, setAllowFileUpload] = useState(false);

  const { uploadFile, data } = useFileSubmit({ stream, isTempFile: true });

  const {
    files,
    isClosed,
    isActive,
    inputRef,
    isLoading,
    uploading,
    totalFiles,
    filteredOptions,
    isButtonDisabled,
    showQuickResponses,
    setUrls,
    setFiles,
    handleClick,
    handleChange,
    setUploading,
    changeInputTo,
    handleKeyPress,
    handleKeyDown,
    setShowQuickResponses,
    updateInputText,
    updateFilesUploaded,
  } = useChatBox();

  const { chat } = useContext(ChatContext);
  const sessionId = useSelector(selectSessionId);

  useEffect(() => {
    if (inputRef?.current) {
      // Persist input text on session change
      updateInputText(chat[sessionId] || '');

      // Reset files on session change
      setFiles([]);
      updateFilesUploaded([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef, sessionId, setFiles]);

  const onDrop = useCallback(
    async (acceptedFiles, rejectedFiles) => {
      const canUpload = totalFiles + acceptedFiles.length <= MAX_FILES;
      try {
        if (!acceptedFiles.length) {
          throw rejectedFiles[0].errors[0];
        }
        if (!canUpload) {
          throw new Error(t('chatBox.upload_error', { max_files: MAX_FILES }));
        }
        setCountdownToReady(acceptedFiles.length);
        setUploading(true);

        for await (const file of acceptedFiles) {
          uploadFile(file);
          await delay(100);
        }

        setFiles((oldFiles) => {
          const newAccepted = acceptedFiles.map((file) => {
            const source = {
              preview: URL.createObjectURL(file),
            };
            return Object.assign(file, source);
          });
          return [...oldFiles, ...newAccepted];
        });
      } catch (e) {
        setUploading(false);
        setCountdownToReady(0);
        if (e.code === ErrorCode.FileTooLarge) {
          dispatch(addTemporalToast('error', t('chatBox.file_too_large')));
        } else if (e.code === ErrorCode.TooManyFiles) {
          dispatch(
            addTemporalToast(
              'error',
              t('chatBox.upload_error', { max_files: MAX_FILES })
            )
          );
        } else {
          dispatch(addTemporalToast('error', e.message));
        }
      }
    },
    [totalFiles, setUploading, setFiles, t, uploadFile, dispatch]
  );

  useEffect(() => {
    if (data) {
      setUrls((prev) => [...prev, { url: data.url, name: data.name }]);
      setCountdownToReady((prev) => prev - 1);
    }
  }, [data, setUploading, setUrls]);

  useEffect(() => {
    if (countdownToReady === 0) {
      setUploading(false);
    }
  }, [countdownToReady, setUploading]);

  const handleOnRemoveFile = useCallback(
    async (e, index: number, name: string) => {
      preventClickThrough(e);
      setFiles((files) => files.filter((_, i) => i !== index));
      setUrls((urls) => urls.filter((url) => url.name !== name));
    },
    [setFiles, setUrls]
  );

  const handlePaste = useCallback((e) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    e.clipboardData = text;
    document.execCommand('insertText', false, text.trim());
  }, []);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept,
    maxFiles: MAX_FILES,
    maxSize: MAX_UPLOAD_SIZE,
    disabled: uploading,
    multiple: true,
    noDragEventsBubbling: true,
    onDrop,
    noClick: true,
    noKeyboard: true,
  });
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filesToRender = useMemo(
    () =>
      files.map((file, idx) => {
        const fileName = file.name;
        if (file.type.includes('image')) {
          return (
            <div className={styles.thumbsWrapper} key={file.path}>
              <div className={styles.thumbs}>
                <img
                  src={file.preview}
                  alt={fileName}
                  onLoad={() => {
                    URL.revokeObjectURL(file.preview);
                  }}
                  onDragStart={(e) => preventClickThrough(e)}
                />

                <Close
                  color="var(--icon-default-white)"
                  onClick={(e) => handleOnRemoveFile(e, idx, fileName)}
                  onKeyDown={(e) => handleOnRemoveFile(e, idx, fileName)}
                  className={styles.closeIcon}
                  size={16}
                />
              </div>
            </div>
          );
        }
        return (
          <UploadCard
            fileName={file.name.split('.')[0]}
            fileType={file.name.split('.').pop()}
            handleClose={(e) => handleOnRemoveFile(e, idx, fileName)}
            key={file.path}
          />
        );
      }),
    [files, handleOnRemoveFile]
  );

  const handleQuickResponseClick = useCallback(
    (text: string) => {
      setShowQuickResponses(false);
      changeInputTo(text);
    },
    [changeInputTo, setShowQuickResponses]
  );

  return (
    <>
      {emojiPicker && (
        <EmojiPicker
          className={styles.emojiPicker}
          onClickAway={() => {
            setEmojiPicker(false);
          }}
          onEmojiClick={({ emoji }) => {
            inputRef.current.innerHTML += emoji;
            setEmojiPicker(false);
          }}
          width="100%"
          height={200}
        />
      )}

      <div
        {...getRootProps({
          className: cn({
            [styles.inputWrapper]: true,
            [styles.isDragActive]: isDragActive,
          }),
        })}
      >
        <div
          className={cn({
            [styles.hide]: isDragActive,
          })}
        >
          <div
            ref={inputRef}
            className={cn(styles.input, {
              [styles.isClosed]: isClosed,
            })}
            aria-label="input"
            role="textbox"
            contentEditable={isActive}
            tabIndex={0}
            onKeyUp={handleKeyPress}
            onKeyDown={handleKeyDown}
            onInput={handleChange}
            onPaste={handlePaste}
            data-testid="file-upload-chat-box"
            data-placeholder={t('chatBox.open')}
          />
          {files && <div className={styles.fileZone}>{filesToRender}</div>}
          <div
            className={cn(styles.chatBoxFooter, {
              [styles.isClosed]: isClosed,
              [styles.isDragAccept]: isDragActive,
            })}
          >
            <ul className={styles.icons}>
              <IconButton
                onClick={() =>
                  dispatch(pushModal(MODAL_CANNED_RESPONSES, { changeInputTo }))
                }
                ariaLabel={t('chatBox.quick_responses')}
                tooltip={t('chatBox.quick_responses')}
              >
                <Response size={ICON_SIZE} />
              </IconButton>

              <IconButton
                onClick={open}
                tooltip={t('chatBox.attach_file')}
                ariaLabel={t('chatBox.attach_file')}
              >
                <Attachment size={ICON_SIZE} />
              </IconButton>
              <IconButton
                onClick={() => setEmojiPicker(true)}
                tooltip={t('emojiPicker.emoji_panel')}
                ariaLabel={t('emojiPicker.emoji_panel')}
              >
                <Emoji size={ICON_SIZE} color="var(--icon-default-gray)" />
              </IconButton>
              {/* Uncomment when allow file upload implmementation is ready */}
              {/* <Divider
                className={styles.divider}
                orientation="vertical"
                flexItem
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allowFileUpload}
                    onChange={() => setAllowFileUpload((prev) => !prev)}
                    name="checkbox"
                    color="primary"
                    size="small"
                    sx={CHECKBOX_CUSTOM_STYLE}
                  />
                }
                label={
                  <Typography variant="label-regular" color="var(--text-default-gray-light)">
                    {t('chatBox.allow_file_upload')}
                  </Typography>
                }
              /> */}
            </ul>

            <input {...getInputProps()} />
            <Button
              isLoading={isLoading}
              disabled={isButtonDisabled}
              onClick={handleClick}
            >
              {t('chatBox.send')}
            </Button>
          </div>
        </div>
        {isDragActive && (
          <div className={styles.dropFile}>{t('chatBox.drop_files')}</div>
        )}
      </div>

      {showQuickResponses && (
        <QuickResponses
          handleClick={handleQuickResponseClick}
          filteredOptions={filteredOptions}
        />
      )}
    </>
  );
};

export default FileUploadChatBox;
