import useCollections from '@/hooks/useCollections';
import { TryItCollection } from '@/models/tryIt';
import { LogMessage } from '@/modules/analytics/models';

import { removeDuplicateDocuments } from '../utils/helper';

export const useDetailsSection = (
  tryItCollection: TryItCollection,
  message?: LogMessage
) => {
  const collectionId = tryItCollection?.collection_id ?? message?.collection_id;

  const { collection } = useCollections(collectionId);

  const uniqueDatasources = removeDuplicateDocuments(
    tryItCollection?.fragments ?? message?.collection_sources
  );

  return { collection, uniqueDatasources };
};
