import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UploadedFile } from '@/models/server';
import { OldOnboardingState } from '@/modules/onboarding/oldModel';

const defaultState: OldOnboardingState = {
  goal: { brain: false, chat: false },
  goalNew: { brain: false, collections: false },
  brainId: null,
  language: '',
  logsFile: null,
  roles: [],
  emails: [],
  isCollectionsFlow: true,
};

const slice = createSlice({
  name: 'oldOnboarding',
  initialState: defaultState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },

    setGoal: (state, action) => {
      state.goal = action.payload;
    },

    setGoalNew: (state, action) => {
      state.goalNew = action.payload;
    },

    setRoles: (state, action) => {
      state.roles = action.payload;
    },

    setEmails: (state, action) => {
      state.emails = action.payload;
    },

    setTemplateBrainId: (state, action) => {
      state.brainId = action.payload;
    },

    setLogsFile: (state, action: PayloadAction<UploadedFile | null>) => {
      state.logsFile = action.payload;
    },

    setIsCollectionsFlow: (state, action) => {
      state.isCollectionsFlow = action.payload;
    },
  },
});

export const {
  setLanguage,
  setGoal,
  setGoalNew,
  setRoles,
  setEmails,
  setTemplateBrainId,
  setLogsFile,
  setIsCollectionsFlow,
} = slice.actions;

export default slice.reducer;
