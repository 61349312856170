import { useCallback, useMemo } from 'react';

import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { dialogsEndpoints as endpoints } from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import { Dialog, Dialogs, PartialDialog } from '@/models/dialog';
import { Node, UsedNode } from '@/models/node';
import { RootState } from '@/models/state';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { getRandomElement, randId } from '@/redux/dialogs/helper';
import {
  getActionsSelector,
  noSystemVariables,
  selectAllVariables,
  selectCustomVariables,
  selectDraftDialog,
} from '@/redux/dialogs/selectors';
import { popModal } from '@/redux/modals/actions';
import {
  saveDialog as onDialogSave,
  importDialog as importNodes,
  clearDialogNodes,
  setDialog,
} from '@/redux/nodes/actions';
import { selectAccountSlug, selectDialogId } from '@/redux/session/selectors';
import {
  exportDialog as downloadDialog,
  importDialog as uploadDialog,
} from '@/util/file-manager';
import { clearNewDialog, sameCollection } from '@/util/util';

import { EntityDraft } from './useEntities';
import useHomeCheckList, { AccountUserPrefsEnum } from './useHomeCheckList';
import useWebhooks from './useWebhooks';

type CollectionsUpdateProps = {
  collection: string;
  new_collection: string;
};

export const API = Object.freeze({
  listDialogs: async (brainId: string): Promise<Dialogs> =>
    callGet(endpoints.dialogs(brainId)),

  getDialog: async (brainId: string, dialogId: string): Promise<Dialog> =>
    callGet(endpoints.dialog(brainId, dialogId)),

  createDialog: async ({
    brainId,
    dialog,
  }: {
    brainId: string;
    dialog: Partial<Dialog>;
  }): Promise<Dialog> => callPost(endpoints.dialogs(brainId), dialog),

  updateCollection: async (
    brain_id: string,
    dialog: CollectionsUpdateProps
  ): Promise<Dialogs> => callPut(endpoints.collection(brain_id), dialog),

  updateDialog: async (
    brainId: string,
    { dialog_id, ...dialog }: PartialDialog
  ): Promise<Dialog> => callPut(endpoints.dialog(brainId, dialog_id), dialog),

  deleteDialog: async ({
    brainId,
    dialogId,
  }: {
    brainId: string;
    dialogId: string;
  }): Promise<Dialog> => callDelete(endpoints.dialog(brainId, dialogId)),
});

export const onDialogCreated = (
  queryClient: QueryClient,
  resp: Dialog,
  brainId: string
) => {
  queryClient.setQueryData<Dialogs>([endpoints.dialogs(brainId)], (prev) => ({
    dialogs: [...(prev?.dialogs || []), resp],
  }));
};

export const onDialogRemoved = (
  queryClient: QueryClient,
  brainId: string,
  dialogId: string
) => {
  queryClient.setQueryData<Dialogs>(
    [endpoints.dialogs(brainId)],
    (prev: Dialogs) => {
      return {
        dialogs: prev?.dialogs.filter((d) => d.dialog_id !== dialogId),
      };
    }
  );
  queryClient.removeQueries({
    queryKey: [endpoints.dialog(brainId, dialogId)],
  });
};

export const onDialogUpdated = (
  queryClient: QueryClient,
  brainId: string,
  dialog: Dialog
) => {
  queryClient.setQueryData<Dialog>(
    [endpoints.dialog(brainId, dialog.dialog_id)],
    (prev: Dialog) => {
      if (prev) {
        return { ...prev, ...dialog };
      }
      return dialog;
    }
  );

  queryClient.setQueryData<Dialogs>(
    [endpoints.dialogs(brainId)],
    (prev: Dialogs) => ({
      dialogs: prev?.dialogs.map((item) =>
        item.dialog_id === dialog.dialog_id ? { ...item, ...dialog } : item
      ),
    })
  );
};

export const onDialogCollectionUpdated = (
  queryClient: QueryClient,
  brainId: string,
  oldCollectionName: string,
  newCollectionName: string
) => {
  queryClient.setQueryData<Dialogs>(
    [endpoints.dialogs(brainId)],
    (prev: Dialogs) => ({
      dialogs: prev?.dialogs.map((item) => {
        if (item.collection === oldCollectionName) {
          return { ...item, collection: newCollectionName };
        }
        return item;
      }),
    })
  );
};

const useDialogs = (brainId?: string, dialogId?: string, nodeId?: string) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { webhooks } = useWebhooks(brainId);
  const { dialogId: paramsDialogId } = useParams();
  const isDraft = paramsDialogId === 'draft';
  const { markAsComplete } = useHomeCheckList();

  const sessionDialogId = useSelector(selectDialogId);

  const dialogDraft: PartialDialog = useSelector(
    (state: RootState) => selectDraftDialog(state),
    shallowEqual
  );
  const slug = useSelector(selectAccountSlug);
  const queryClient = useQueryClient();

  const { data: dialogs, status: listStatus } = useQuery<Dialogs, Error>({
    queryKey: [endpoints.dialogs(brainId)],
    queryFn: () => API.listDialogs(brainId),
    enabled: !!brainId,
  });

  const { data: dialog, status: getStatus } = useQuery<Dialog, Error>({
    queryKey: [endpoints.dialog(brainId, dialogId)],
    queryFn: () => API.getDialog(brainId, dialogId),
    enabled: !!brainId && !!dialogId && !isDraft,
  });

  const hasHandover = useCallback(() => {
    if (!dialogs?.dialogs) {
      return false;
    } else {
      return getActionsSelector(dialogs.dialogs).some(
        (dialog) => dialog.type === 'handover'
      );
    }
  }, [dialogs]);

  const dialogName = useMemo(() => dialog?.name, [dialog]);
  const { mutate: updateDialog, status: updateStatus } = useMutation<
    Dialog,
    Error,
    PartialDialog
  >({
    mutationFn: (data) => API.updateDialog(brainId, data),
    onSuccess: (resp, variables) => {
      const isDialogRename = !('nodes' in variables);
      if (!isDialogRename) {
        dispatch(onDialogSave());
      }

      onDialogUpdated(queryClient, brainId, {
        ...resp,
        dialog_id: variables.dialog_id,
      });
    },

    onError: (error: Error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: createDialog, status: createStatus } = useMutation<
    Dialog,
    Error,
    Partial<Dialog>
  >({
    mutationFn: (data) => API.createDialog({ brainId, dialog: data }),
    onSuccess: (resp) => {
      markAsComplete(AccountUserPrefsEnum.GENERATE_DIALOG);

      dispatch(setDialog(resp));
      onDialogCreated(queryClient, resp, brainId);
      setTimeout(() => {
        navigate(`/${slug}/brains/${brainId}/dialogs/${resp.dialog_id}`);
      }, 0);
    },
    onError: (error: Error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: deleteDialog, status: deleteStatus } = useMutation<
    Dialog,
    Error
  >({
    mutationFn: () => API.deleteDialog({ brainId, dialogId }),
    onSuccess: () => {
      dispatch(popModal());
      const dialogsInCollection = sameCollection(
        'dialogs',
        'dialog_id',
        dialogId,
        dialogs
      );
      dispatch(clearDialogNodes());
      onDialogRemoved(queryClient, brainId, dialogId);

      dispatch(
        addTemporalToast(
          'success',
          t('dialog.dialog_deleted', { 0: dialogName })
        )
      );

      const url = `/${slug}/brains/${brainId}/dialogs`;
      if (dialogsInCollection.length === 0) {
        navigate(url);
      } else {
        navigate(`${url}/${dialogsInCollection[0].dialog_id}`);
      }
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: updateCollection, status: updateCollectionStatus } =
    useMutation<Dialogs, Error, CollectionsUpdateProps>({
      mutationFn: (data) => API.updateCollection(brainId, data),
      onSuccess: (resp, variables) => {
        const newCollectionName = resp.dialogs[0]?.collection;
        onDialogCollectionUpdated(
          queryClient,
          brainId,
          variables.collection,
          variables.new_collection
        );

        dispatch(
          addTemporalToast(
            'success',
            t('dialog.collection_updated', { 0: newCollectionName })
          )
        );
      },
    });

  const saveDialog = useCallback(async () => {
    if (isDraft) {
      return new Promise<void>((resolve, reject): void => {
        const previousCollection = dialogs?.dialogs?.find(
          (e) => e.dialog_id === sessionDialogId
        )?.collection;

        const newDialog = {
          ...dialogDraft,
          collection: previousCollection,
        };
        createDialog(newDialog, {
          onSuccess: (resp) => {
            resolve();

            dispatch(
              addTemporalToast(
                'success',
                t('dialog.dialog_updated', { 0: resp.name })
              )
            );
          },
          onError: () => {
            reject();
          },
        });
      });
    }
    return new Promise<void>((resolve, reject): void => {
      updateDialog(dialogDraft, {
        onSuccess: (resp) => {
          dispatch(popModal());

          dispatch(
            addTemporalToast(
              'success',
              t('dialog.dialog_updated', { 0: resp.name })
            )
          );
          resolve();
        },
        onError: () => {
          reject();
        },
      });
    });
  }, [
    createDialog,
    dialogDraft,
    dialogs?.dialogs,
    dispatch,
    isDraft,
    sessionDialogId,
    t,
    updateDialog,
  ]);

  const updateDialogName = useCallback(
    async (newDialog?: PartialDialog) => {
      return new Promise<void>((resolve, reject): void => {
        updateDialog(newDialog ?? dialogDraft, {
          onSuccess: (resp) => {
            dispatch(popModal());

            dispatch(
              addTemporalToast(
                'success',
                t('dialog.dialog_updated', { 0: resp.name })
              )
            );
            resolve();
          },
          onError: () => {
            reject();
          },
        });
      });
    },
    [dialogDraft, dispatch, t, updateDialog]
  );

  const createDraftDialog = useCallback(() => {
    navigate(`/${slug}/brains/${brainId}/dialogs/draft`);
  }, [brainId, slug, navigate]);

  const createCollection = useCallback(() => {
    const newDialog = {
      name: `Untitled-${randId()}`,
      collection: `Collection-${randId()}`,
    };

    createDialog(newDialog);
  }, [createDialog]);

  const exportDialog = useCallback(async () => {
    const eDialog = await callGet(
      `/www/api/v1/brains/${brainId}/dialogs/${dialogId}?export=true`
    );
    downloadDialog(eDialog);
    dispatch(
      addTemporalToast(
        'success',
        t('dialog.dialog_exported', { 0: eDialog.name })
      )
    );
  }, [brainId, dialogId, dispatch, t]);

  const importDialog = useCallback(
    async ({ e, intents, entities }) => {
      uploadDialog(dialog, e.target?.files[0])
        .then((newDialog: { nodes: Node[] }) => {
          clearNewDialog(newDialog, intents, entities);
          dispatch(importNodes(newDialog));
        })
        .then(() => {
          // Set dialog name to draft if it's empty
          const name = dialogName ?? t('common.draft');

          dispatch(
            addTemporalToast(
              'success',
              t('dialog.dialog_imported', { 0: name })
            )
          );
        })
        .catch((error: Error) => {
          dispatch(addErrorTemporalToast(error));
        });
    },
    [dialog, dialogName, dispatch, t]
  );

  const eventsOptions = useMemo(() => {
    const dialogNodes = dialogs?.dialogs.reduce(
      (acc, curr) => [...acc, ...curr.nodes],
      [] as { node_id: string; name: string }[]
    );

    const newNodes = dialogDraft?.nodes?.reduce(
      (acc, curr) => {
        if (
          dialogNodes?.filter((n) => n.node_id === curr.node_id).length === 0
        ) {
          return [...acc, { node_id: curr.node_id, name: curr.name }];
        }
        return acc;
      },
      [] as { node_id: string; name: string }[]
    );
    const allNodes = dialogNodes?.concat(newNodes);
    return (
      allNodes?.map(({ node_id, name }) => ({
        value: node_id,
        label: name,
        disabled: nodeId ? node_id === nodeId : false,
      })) ?? []
    );
  }, [dialogDraft?.nodes, dialogs?.dialogs, nodeId]);

  // returns every node from every dialog including draft dialog
  const detailedOptions = useMemo(() => {
    const dialogNodes = [];
    if (dialogs && dialogs.dialogs) {
      for (let i = 0; i < dialogs.dialogs.length; i++) {
        const dialog = dialogs.dialogs[i];
        for (let j = 0; j < dialog.nodes.length; j++) {
          const node = dialog.nodes[j];
          dialogNodes.push({
            dialog_name: dialog.name,
            value: node.node_id,
            label: node.name,
            node_type: node.type,
          });
        }
      }
    }
    if (dialogDraft && dialogDraft.nodes) {
      for (let i = 0; i < dialogDraft.nodes.length; i++) {
        const node = dialogDraft.nodes[i];
        const isNodeExists = dialogNodes.some(
          (dialogNode) => dialogNode.value === node.node_id
        );
        if (!isNodeExists) {
          dialogNodes.push({
            dialog_name: dialogDraft.name,
            value: node.node_id,
            label: node.name,
            node_type: node.type,
          });
        }
      }
    }
    return dialogNodes;
  }, [dialogDraft, dialogs]);

  const updateIntentNameOnDialogs = async (
    usedNodes: UsedNode[],
    payload: { intent: string }
  ) => {
    const usedNodesIdsCollection = [];
    usedNodes.forEach((x) => usedNodesIdsCollection.push(x.nodeId));

    const dialogsToMutate = [];

    dialogs?.dialogs.forEach((d) =>
      usedNodes.forEach((node) => {
        if (d.dialog_id === node.dialogId && !dialogsToMutate.includes(d)) {
          const mutatedNodes = d.nodes.map((mutatedNode) =>
            usedNodesIdsCollection.includes(mutatedNode.node_id)
              ? {
                  ...mutatedNode,
                  ...payload,
                }
              : mutatedNode
          );
          updateDialog({
            dialog_id: d.dialog_id,
            nodes: mutatedNodes,
          });
        }
      })
    );
  };

  const updateEntityNameAndValuesOnDialogs = useCallback(
    async (old_draft: EntityDraft, new_draft: EntityDraft) => {
      const oldName = `@${old_draft.new_entity}`;
      const newName = `@${new_draft.new_entity}`;
      const oldValues = old_draft.values;
      const newValues = new_draft.values;
      const renamedValues = oldValues.reduce(
        (acc, oldVal) => {
          const sameIdValue = newValues.find(
            (newVal) => newVal?.id === oldVal?.id
          );
          return sameIdValue?.value !== oldVal?.value
            ? [
                ...acc,
                { name: newName, old: oldVal?.value, new: sameIdValue?.value },
              ]
            : acc;
        },
        [] as { name: string; old: string; new: string }[]
      );
      if (!isEmpty(renamedValues)) {
        dialogs?.dialogs.forEach((dialog) => {
          let updateFlag = false;
          const newNodes = dialog.nodes.map((node) => {
            const newConditions = node?.conditions?.map((cond) => {
              const newRules = cond?.rules.map((rule) => {
                const renamed = renamedValues.find(
                  (val) => val?.name === rule?.name && val?.old === rule?.value
                );
                if (renamed) {
                  updateFlag = true;
                  return { ...rule, value: renamed.new };
                }
                return rule;
              });
              return { ...cond, rules: newRules };
            });
            return { ...node, conditions: newConditions };
          });
          if (updateFlag) {
            updateDialog({
              dialog_id: dialog.dialog_id,
              nodes: newNodes,
            });
          }
        });
      }
      if (oldName !== newName) {
        dialogs?.dialogs.forEach((dialog) => {
          let updateFlag = false;
          const newNodes = dialog.nodes.map((node) => {
            const newRequisites =
              node?.requisites?.map((req) => {
                if (req?.check_for === oldName) {
                  updateFlag = true;
                  return { ...req, check_for: newName };
                }
                return req;
              }) ?? undefined;
            const newConditions =
              node?.conditions?.map((cond) => {
                const newRules = cond.rules.map((rule) => {
                  if (rule?.name === oldName) {
                    updateFlag = true;
                    return { ...rule, name: newName };
                  }
                  return rule;
                });
                return { ...cond, rules: newRules };
              }) ?? undefined;
            return {
              ...node,
              requisites: newRequisites,
              conditions: newConditions,
            };
          });
          if (updateFlag) {
            updateDialog({
              dialog_id: dialog.dialog_id,
              nodes: newNodes,
            });
          }
        });
      }
    },
    [dialogs?.dialogs, updateDialog]
  );

  const dialogTags = useMemo(() => {
    const allTags: Set<string> = new Set();
    dialogs?.dialogs?.forEach((dialog) => {
      dialog?.nodes?.forEach((node) => {
        node?.actions?.forEach((action) => {
          if (action.type === 'tag') {
            action.tags?.forEach((tag) => allTags.add(tag));
          }
        });
        node?.conditions?.forEach((cond) => {
          cond?.actions?.forEach((action) => {
            if (action.type === 'tag') {
              action.tags?.forEach((tag) => allTags.add(tag));
            }
          });
        });
      });
    });
    return allTags;
  }, [dialogs?.dialogs]);

  const getContextVariables = useCallback(
    (includeSystemVariables = true) =>
      includeSystemVariables
        ? selectAllVariables(dialogs?.dialogs, dialogDraft, webhooks)
        : noSystemVariables(dialogs?.dialogs, dialogDraft, webhooks),
    [dialogDraft, dialogs, webhooks]
  );

  const getCustomVariables = useCallback(
    () => selectCustomVariables(dialogs?.dialogs, dialogDraft, webhooks),
    [dialogDraft, dialogs, webhooks]
  );

  const findConnectedDialogsForWebhooks = useCallback(
    (webhook_id: string) => {
      const usedDialogs = [];
      if (dialogs?.dialogs) {
        dialogs.dialogs?.forEach((dialog) => {
          for (let i = 0; i < dialog.nodes.length; i++) {
            const node = dialog.nodes[i];
            for (let j = 0; j < node?.actions?.length; j++) {
              const action = node?.actions[j];
              if (
                action?.type === 'webhook' &&
                action.webhook_id === webhook_id
              ) {
                usedDialogs.push({
                  url: dialog.dialog_id,
                  label: dialog.name,
                });
                return;
              }
            }
            for (let j = 0; j < node?.conditions?.length; j++) {
              const condition = node?.conditions[j];
              for (let k = 0; k < condition?.actions.length; k++) {
                const action = condition?.actions[k];
                if (
                  action?.type === 'webhook' &&
                  action.webhook_id === webhook_id
                ) {
                  usedDialogs.push({
                    url: dialog.dialog_id,
                    label: dialog.name,
                  });
                  return;
                }
              }
            }
          }
        });
      }
      return usedDialogs;
    },
    [dialogs]
  );

  const dialogNames = useMemo(
    () => dialogs?.dialogs?.map((i) => i.name.toLowerCase()),
    [dialogs]
  );

  const randomNode = useMemo(
    () => getRandomElement(detailedOptions),
    [detailedOptions]
  );

  return {
    dialogs: useMemo(() => dialogs?.dialogs, [dialogs?.dialogs]),
    dialog,
    hasHandover,
    isDraft,
    dialogDraft,
    createDialog,
    createDraftDialog,
    createCollection,
    updateCollection,
    saveDialog,
    updateDialogName,
    exportDialog,
    importDialog,
    getStatus,
    listStatus,
    updateCollectionStatus,
    updateStatus,
    deleteStatus,
    createStatus,
    deleteDialog,
    updateDialog,
    eventsOptions,
    detailedOptions,
    updateIntentNameOnDialogs,
    updateEntityNameAndValuesOnDialogs,
    dialogTags,
    getContextVariables,
    getCustomVariables,
    findConnectedDialogsForWebhooks,
    dialogNames,
    randomNode,
  };
};

export default useDialogs;
