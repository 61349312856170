import he from 'he';
import isHotkey from 'is-hotkey';
import isArray from 'lodash/isArray';
import rehypeStringify from 'rehype-stringify';
import breaks from 'remark-breaks';
import markdown from 'remark-parse';
import remarkRehype from 'remark-rehype';
import slate from 'remark-slate';
import {
  Editor,
  Transforms,
  Descendant,
  Element as SlateElement,
  Range,
  Node,
  Text,
  BaseElement,
} from 'slate';
import { ReactEditor } from 'slate-react';
import { unified } from 'unified';

import { isUrl } from '@/util/util';

import serialize from './serialize';

export type LinkElement = {
  type: 'link';
  link: string;
  children: Descendant[];
};

interface CustomElement extends BaseElement {
  type: 'paragraph' | 'list_item' | 'ol_list' | 'ul_list' | string;
}

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
};

const LIST_TYPES = ['ol_list', 'ul_list'];

// Checks if a link is currently active within the editor.
export const isLinkActive = (editor: Editor) => {
  // Searches for a link node in the editor's content.
  // It returns the first node found that matches the criteria (i.e. type is 'link').
  const [link] = Editor.nodes(editor, {
    match: (n: LinkElement) => {
      return n.type === 'link';
    },
  });

  // If a link node is found, return true (link is active), otherwise return false.
  return !!link;
};

// Removes the link formatting from the selected text in the editor.
export const unwrapLink = (editor: Editor) => {
  // Transforms the editor's content by unwrapping nodes that match the criteria (i.e. type is 'link').
  Transforms.unwrapNodes(editor, {
    match: (n: LinkElement) => {
      return (
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link'
      );
    },
  });
};

// this function is used for the Command Palette.
// removes the text of triggerText length, and inserts the `text` in its place.
export const addStringToEditor = (
  editor: Editor,
  text: string,
  triggerText: string
) => {
  if (editor.selection && Range.isCollapsed(editor.selection)) {
    const triggerKeyLength = triggerText.length;
    let startPoint = editor.selection.anchor;

    for (let i = 0; i < triggerKeyLength; i++) {
      startPoint = Editor.before(editor, startPoint);
    }

    if (startPoint) {
      const range = { anchor: startPoint, focus: editor.selection.focus };

      const beforeText = Editor.string(editor, range);

      if (beforeText === triggerText) {
        Transforms.delete(editor, { at: range });
      }
    }
  }

  Transforms.insertText(editor, text);
};

// Wrap the selected text or collapsed cursor position with a link.
export const wrapLink = (editor: Editor, url, selection = null) => {
  // If there's an active link, unwrap it.
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  // If no selection is provided, use the editor's current selection.
  const _selection = selection ?? editor.selection;

  // Check if the selection is collapsed (i.e., only a cursor position, not a range).
  const isCollapsed = _selection && Range.isCollapsed(_selection);

  // Create a link object with the provided URL.
  const link = {
    type: 'link',
    link: url,
    // If the selection is collapsed, set the link's children to display the URL as text.
    // Otherwise, leave the children empty, as the selected text will be wrapped.
    children: isCollapsed ? [{ text: url }] : [],
  };

  // If the selection is collapsed, insert the link object at the cursor position.
  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    // If the selection is a range, wrap the selected text with the link object.
    Transforms.wrapNodes(editor, link, { split: true, at: _selection });

    // After wrapping the link, collapse the selection to the end of the wrapped content.
    Transforms.collapse(editor, { edge: 'end' });
  }
};

// Finds a link element within the editor's selection.
export const findLinkElement = (editor: Editor) => {
  const { selection } = editor;

  if (selection) {
    const range = Editor.range(editor, selection);

    for (const [node, path] of Editor.nodes(editor, { at: range })) {
      if (
        SlateElement.isElement(node) &&
        (node as LinkElement).type === 'link'
      ) {
        return { linkNode: node, linkPath: path };
      }
    }
  }

  return null;
};

export const selectLinkElement = (editor: Editor) => {
  const linkElement = findLinkElement(editor);
  if (linkElement) {
    const { linkPath } = linkElement;
    const range = Editor.range(editor, linkPath);
    Transforms.select(editor, range);
  }
};

export const changeLink = (editor, newText, newUrl) => {
  try {
    const linkElement = findLinkElement(editor);
    if (linkElement) {
      const { linkPath } = linkElement;
      // Update the link property
      Transforms.setNodes(editor, { link: newUrl } as Partial<LinkElement>, {
        at: linkPath,
        match: (n) => (n as LinkElement).type === 'link',
      });

      const textPath = [...linkPath, 0];
      const textNode = Node.get(editor, textPath) as Text;

      // Delete the existing text.
      Transforms.delete(editor, {
        at: {
          anchor: { path: textPath, offset: 0 },
          focus: { path: textPath, offset: textNode.text.length },
        },
      });

      // Insert the new text.
      Transforms.insertText(editor, newText, { at: textPath });
    }
  } catch (error) {
    console.error('Error occurred in changeLink:', error);
  }
};

export const focusEditorEnd = (editor) => {
  try {
    const point = Editor.point(editor, [editor.children.length - 1], {
      edge: 'end',
    });

    const range = { anchor: point, focus: point };

    Transforms.select(editor, range);

    ReactEditor.focus(editor);
  } catch (error) {
    console.error('Error occurred in focusEditorEnd:', error);
  }
};

// Function to insert a link at the current selection or replace the selected text with a new link.
export const insertLink = (editor: Editor, url: string, linkText?: string) => {
  // Check if there is an active selection in the editor.
  if (editor.selection) {
    if (linkText) {
      // Insert the link text at the current selection.
      Transforms.insertText(editor, linkText, { at: editor.selection });

      // Create a new selection that covers the inserted link text.
      const newSelection = {
        anchor: editor.selection.anchor,
        focus: {
          path: editor.selection.anchor.path,
          offset: editor.selection.anchor.offset - linkText.length,
        },
      };

      // Wrap the new selection with the provided URL.
      wrapLink(editor, url, newSelection);
    } else {
      // If no linkText is provided, wrap the current selection with the provided URL.
      wrapLink(editor, url, editor.selection);
    }
  }
};

export const getLinkText = (editor: Editor, type: 'link' | 'text') => {
  const linkElement = findLinkElement(editor);

  if (linkElement) {
    const { linkNode } = linkElement;
    return type === 'link'
      ? (linkNode as LinkElement).link
      : (linkNode.children[0] as Text).text;
  }

  return '';
};

// Extend the editor's default behavior for handling inline elements.
export const withInlines = (editor: ReactEditor) => {
  const { insertData, insertText, isInline } = editor;

  // Extend the original isInline method to include the 'link' type as an inline element.
  editor.isInline = (element) =>
    ['link'].includes((element as LinkElement).type) || isInline(element);

  // Override the original insertText method to automatically wrap URLs with a link.
  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  // Override the original insertData method to automatically wrap URLs with a link.
  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export const isMarkActive = (editor: Editor, format) => {
  try {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  } catch (e) {
    return false;
  }
};

export const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) {
    return false;
  }

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );
  return !!match;
};

export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format, 'type');
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => {
      const node = n as CustomElement;
      return (
        !Editor.isEditor(node) &&
        SlateElement.isElement(node) &&
        LIST_TYPES.includes(node.type)
      );
    },
    split: true,
  });

  const newProperties: Partial<CustomElement> = {
    type: isActive ? 'paragraph' : isList ? 'list_item' : format,
  };

  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const getCharCount = (nodes) => {
  if (!nodes || !isArray(nodes)) {
    return 0;
  }
  return nodes
    .map((node) => {
      if (node.children) {
        return getCharCount(node.children);
      } else if (node.text) {
        return node.text.length;
      } else {
        return 0;
      }
    })
    .reduce((count, current) => count + current, 0);
};

export const onKeyDownHandler = (event, editor: ReactEditor) => {
  const { selection } = editor;

  // Custom left/right behavior
  if (selection && Range.isCollapsed(selection)) {
    const { nativeEvent } = event;
    if (isHotkey('left', nativeEvent)) {
      event.preventDefault();
      Transforms.move(editor, { unit: 'offset', reverse: true });
      return;
    }
    if (isHotkey('right', nativeEvent)) {
      event.preventDefault();
      Transforms.move(editor, { unit: 'offset' });
      return;
    }
  }

  for (const hotkey in HOTKEYS) {
    if (isHotkey(hotkey, event as never)) {
      event.preventDefault();
      const mark = HOTKEYS[hotkey];
      toggleMark(editor, mark);
    }
  }
};

export const HANDLE_REGEX = /\{{(@|\$)([^}^{^@^$^ ]+)\}}/g;

// Used for Editor decorate, e.g. adding style to {{$use}}
// Receives a node and its path as input, and extracts handle ranges based on the HANDLE_REGEX pattern
export const extractHandleRanges = ([node, path]) => {
  if (!Text.isText(node)) {
    return [];
  }

  const ranges = [];
  const text = Node.string(node);
  const matches = Array.from(text.matchAll(HANDLE_REGEX));

  for (const match of matches) {
    const [fullMatch] = match;
    const start = match.index;
    const end = start + fullMatch.length;

    // If the range matches the regex, add an `isHandle` property to the range
    // This property is later used in the `renderLeaf` function to apply styling
    ranges.push({
      isHandle: true,
      anchor: { path, offset: start },
      focus: { path, offset: end },
    });
  }

  return ranges;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const richTextToMarkdown = (value: any[]): string => {
  try {
    // Pull the children of each object in the value array to the root array
    const transformedValue = value.reduce((acc, v) => {
      return acc.concat(v.children);
    }, []);
    let result = transformedValue
      .map((v) => serialize(v))
      .join('')
      .trim();

    // Remove the trailing <br> tag
    if (result === '<br>') {
      result = '';
    }

    return result;
  } catch (error) {
    console.error('Error while converting rich text to markdown:', error);
    return '';
  }
};

const removeListItemParagraph = (nodes) => {
  return nodes.map((node) => {
    if (node.type === 'ol_list' || node.type === 'ul_list') {
      return {
        ...node,
        children: node.children.map((child) => {
          if (
            child.type === 'list_item' &&
            child.children[0].type === 'paragraph'
          ) {
            return {
              ...child,
              children: child.children[0].children,
            };
          }
          return child;
        }),
      };
    } else if (node.children) {
      return {
        ...node,
        children: removeListItemParagraph(node.children),
      };
    }
    return node;
  });
};

// alternative to useMarkdownToSlate hook for use in useEffects and other async context
export const markdownToSlatePromise = async (
  markdownText = ''
): Promise<Node[]> => {
  try {
    const decodedMarkdown = he.decode(markdownText);
    if (decodedMarkdown.trim() === '') {
      return [{ children: [{ text: '' }] }] as Node[];
    } else {
      const result = await unified()
        .use(markdown)
        .use(slate)
        .process(decodedMarkdown);
      const cleanedResult = removeListItemParagraph(result.result as Node[]);
      return [{ children: cleanedResult }];
    }
  } catch (err) {
    console.error('Error converting markdown to slate:', err);
    throw err;
  }
};

export const markdownToHtml = async (markdownText: string): Promise<string> => {
  if (typeof markdownText === 'undefined' || markdownText === null) {
    return '';
  }

  try {
    const decodedMarkdown = he.decode(markdownText);

    const result = await unified()
      .use(markdown)
      .use(breaks)
      .use(remarkRehype)
      .use(rehypeStringify)
      .process(decodedMarkdown);

    return String(result);
  } catch (err) {
    console.error('Error converting markdown to HTML:', err);
    throw err;
  }
};

interface TextObject {
  message: string;
  type: string;
}

export interface NestedObject {
  children?: NestedObject[];
  text?: TextObject;
}

export const findRichTextEditorErrorMessage = (
  obj: NestedObject | NestedObject[]
): string | null => {
  // Check if the object itself has the 'message' property
  if (obj && typeof obj === 'object' && 'message' in obj) {
    return obj.message as TextObject['message'];
  }

  // Check if the object is an array or has enumerable properties
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const message = findRichTextEditorErrorMessage(obj[key]);
      if (message) return message;
    }
  }

  // If we reach here, no message was found
  return null;
};
