import { useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import uniq from 'lodash/uniq';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AutocompleteNew from '@/components/atoms/AutocompleteNew/AutocompleteNew2';
import Chips from '@/components/atoms/Chips/Chips';
import { useConversationContext } from '@/hooks/useConversationContext';
import useDesks from '@/hooks/useDesks';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectDeskId } from '@/redux/session/selectors';
import { MAX_CONVERSATION_TAGS } from '@/util/constants';
import { capitalizeFirstLetter } from '@/util/util';
import { newTagSchema } from '@/util/validator';

import { CustomDetails } from './CustomDetails/CustomDetails';

export const ContextTagsSection = () => {
  const { t } = useTranslation();
  const { tags, addTag, removeTag } = useConversationContext();
  const deskId = useSelector(selectDeskId);
  const { desk } = useDesks(deskId);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'conversations', actions.WRITE)
  );

  const {
    formState: { errors },
    getValues,
    setValue,
    control,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      tag: null,
    },
    resolver: yupResolver(newTagSchema),
  });

  const handleChange = useCallback(
    async (_, option) => {
      const isValid = await trigger('tag');
      if (option && isValid) {
        addTag(option.value, {
          onSuccess: () => {
            setValue('tag', null);
          },
        });
      }
    },
    [addTag, setValue, trigger]
  );

  const suggestedTags =
    desk?.metadata?.conversation_tags
      ?.map((tag) => ({
        value: tag,
        label: tag,
      }))
      .filter((t) => !tags.includes(t.value)) || [];

  return (
    <section>
      <CustomDetails title={t('common.tags')}>
        <AutocompleteNew
          width={238}
          name="tag"
          control={control}
          tooltip={t('dialog.tags.add_new_tooltip')}
          options={suggestedTags}
          freeSolo
          enableNewEntry={newTagSchema.isValidSync(getValues())}
          groupByProp="type"
          groupByLabelProp={false}
          placeholder={t('rules.type_tag')}
          size="xs"
          hasError={!!errors.tag?.message}
          errorMessage={capitalizeFirstLetter(errors.tag?.message)}
          disabled={!canWrite || tags?.length >= MAX_CONVERSATION_TAGS}
          onChange={handleChange}
          onInputChange={(_, value) => {
            setValue('tag', value, {
              shouldValidate: true,
            });
          }}
          clearOnBlur
          getOptionLabel={(option) => option?.label ?? option}
        />

        <Chips
          chips={uniq(tags)}
          onClick={(tag) => {
            removeTag(tag);
          }}
        />
      </CustomDetails>
    </section>
  );
};
