import { useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/system';
import uniq from 'lodash/uniq';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import AutocompleteNew from '@/components/atoms/AutocompleteNew/AutocompleteNew2';
import { Banner } from '@/components/atoms/Banner/Banner';
import Chips from '@/components/atoms/Chips/Chips';
import useDialogs from '@/hooks/useDialogs';
import { Action } from '@/models/action';
import { OptionBase } from '@/models/common';
import { RootState } from '@/models/state';
import { updateDialogAlerts } from '@/redux/dialogAlerts/actions';
import {
  selectAllTags,
  selectNodeIdByActionId,
  selectSelectedAction,
} from '@/redux/dialogs/selectors';
import { updateAction } from '@/redux/nodes/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { MAX_CONVERSATION_TAGS } from '@/util/constants';
import { capitalizeFirstLetter } from '@/util/util';
import { newTagSchema } from '@/util/validator';

import ToolkitWrapper from '../../ToolkitWrapper';

import styles from './ToolkitActionTag.module.scss';

type SuggestedTag = OptionBase<{ type?: string }>;

type ActionTag = {
  tag: SuggestedTag | string;
};

const ToolkitActionTag = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actionId, tags, type } = useSelector((state: RootState) => {
    const selectedAction = selectSelectedAction(state) as Extract<
      Action,
      { type: 'tag' }
    >;
    return {
      actionId: selectedAction?.action_id,
      tags: selectedAction?.type === 'tag' ? selectedAction.tags : [],
      type: selectedAction?.type,
    };
  }, shallowEqual);
  const parentNodeId = useSelector(selectNodeIdByActionId(actionId));

  const brainId = useSelector(selectBrainId);
  const { dialogs } = useDialogs(brainId);
  const allTags = selectAllTags(dialogs);
  // remove duplicate tags
  const suggestedTags = allTags.filter((tag) => !tags.includes(tag));

  const autoCompleteTags =
    suggestedTags?.map((tab) => ({
      type: t('dialog.tags.auto_complete_group_by'),
      value: tab,
      label: tab,
    })) ?? [];

  const {
    control,
    formState: { errors },
    trigger,
    getValues,
    setValue,
  } = useForm<ActionTag>({
    mode: 'onChange',
    defaultValues: {
      tag: null,
    },
    resolver: yupResolver(newTagSchema) as Resolver<ActionTag>,
  });

  const saveNewTag = useCallback(
    (tag) => {
      const newTags = uniq([...tags, tag]);
      dispatch(
        updateAction({
          actionId,
          action: {
            tags: newTags,
          },
        })
      );
    },
    [actionId, dispatch, tags]
  );

  const handleOnTagDelete = useCallback(
    (deletedTag) => {
      const newTags = tags.filter((tag: string) => tag !== deletedTag);
      dispatch(
        updateAction({
          actionId,
          action: {
            tags: newTags,
          },
        })
      );
    },
    [actionId, dispatch, tags]
  );

  const handleChange = useCallback(
    (_, option) => {
      if (option) {
        // Clear the input field
        setValue('tag', {
          type: '',
          value: '',
          label: '',
        });
        saveNewTag(option.value);
      }
    },
    [saveNewTag, setValue]
  );

  const updateErrors = useCallback(
    (key: string) => (value: string) => {
      dispatch(
        updateDialogAlerts({
          dialogAlerts: {
            alertType: 'error',
            id: actionId,
            nodeId: parentNodeId,
            title: t('actions.types.tag'),
            body: capitalizeFirstLetter(value),
            type,
            alertField: key,
          },
        })
      );
    },
    [actionId, dispatch, parentNodeId, t, type]
  );

  useEffect(() => {
    trigger();
  }, [trigger]);

  // Update dialog errors
  useEffect(() => {
    const body =
      tags?.length === 0
        ? t('dialog.errors.at_least_one', {
            0: t('actions.types.tag').toLowerCase(),
          })
        : undefined;

    updateErrors('tag')(body);
  }, [t, tags?.length, updateErrors]);

  return (
    <ToolkitWrapper type="tag">
      <div className={styles.tags}>
        <AutocompleteNew
          name="tag"
          control={control}
          tooltip={t('dialog.tags.add_new_tooltip')}
          options={autoCompleteTags}
          freeSolo
          enableNewEntry={newTagSchema.isValidSync(getValues())}
          groupByProp="type"
          groupByLabelProp={false}
          label={t('common.tags')}
          placeholder={t('rules.type_tag')}
          size="xs"
          hasError={!!errors.tag?.message}
          errorMessage={errors.tag?.message}
          disabled={tags?.length >= MAX_CONVERSATION_TAGS}
          onChange={handleChange}
          onInputChange={(_, value) => {
            setValue('tag', value, {
              shouldValidate: true,
            });
          }}
          clearOnBlur
          getOptionLabel={(option) => option?.label ?? option}
        />

        <Chips
          chips={tags}
          onClick={(tag) => {
            handleOnTagDelete(tag);
          }}
        />

        {tags?.length === MAX_CONVERSATION_TAGS && (
          <Box mt="var(--space-24)">
            <Banner variant="neutral" relativePosition centered>
              {t('dialog.tags.warning')}
            </Banner>
          </Box>
        )}

        {tags?.length === 0 && (
          <Box mt="var(--space-24)">
            <Banner variant="critical" relativePosition>
              {t('dialog.errors.at_least_one', {
                0: t('actions.types.tag').toLowerCase(),
              })}
            </Banner>
          </Box>
        )}
      </div>
    </ToolkitWrapper>
  );
};

export default ToolkitActionTag;
