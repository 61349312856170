import { ChangeEvent, useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Option } from '@/components/atoms/AutoComplete/AutoComplete';
import IconizedDialog from '@/components/atoms/IconizedDialog/IconizedDialog';
import Input from '@/components/atoms/Input/Input';
import Select from '@/components/atoms/Select/Select';
import Switch from '@/components/atoms/Switch/Switch';
import { useAccount } from '@/hooks/useAccount';
import useBrains from '@/hooks/useBrains';
import useDialogNodesCache from '@/hooks/useDialogNodesCache';
import useDialogs from '@/hooks/useDialogs';
import useFocusOnInput from '@/hooks/useFocusOnInput';
import useIntents from '@/hooks/useIntents';
import { RootState } from '@/models/state';
import { updateDialogAlerts } from '@/redux/dialogAlerts/actions';
import { selectSelectedNode } from '@/redux/dialogs/selectors';
import { updateNode } from '@/redux/nodes/actions';
import { selectBrainId } from '@/redux/session/selectors';
import { capitalizeFirstLetter } from '@/util/util';
import { intentFormSchema, nodeRules } from '@/util/validator';

import ToolkitWrapper from '../../ToolkitWrapper';
import { nodeType } from '../ToolkitNodeEvent/util';

import styles from './ToolkitNodeIntent.module.scss';

type FormType = {
  name: string;
  intent: Option;
  disambiguation: boolean;
  disambiguation_label: string;
};

// Clean the intent value from the # character
const clean = (input: Option) => input?.label?.replace('#', '');

function ToolkitNodeIntent() {
  const navigate = useNavigate();
  const { slug } = useAccount();
  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);
  const { brain } = useBrains(brainId);
  const { cleanIntents } = useIntents(brainId);
  const dispatch = useDispatch();
  const { nodeId, name, intent, hasDisambiguation, disambiguationLabel } =
    useSelector((state: RootState) => {
      const selectedNode = selectSelectedNode(state);

      return {
        nodeId: selectedNode.node_id,
        name: selectedNode.name ?? '',
        intent: selectedNode.intent ?? '',
        hasDisambiguation: selectedNode?.disambiguation ?? false,
        // for older brains that have disambiguation active but no disambiguation_label we show the node name as default
        disambiguationLabel:
          (selectedNode?.disambiguation_label ?? selectedNode?.disambiguation)
            ? selectedNode.name
            : undefined,
      };
    }, shallowEqual);

  const { findUsedNodes } = useDialogNodesCache();
  const usedIn = findUsedNodes({ brainId, name: nodeId });
  const nodesLength = usedIn?.length;
  const { dialogs } = useDialogs(brainId);

  const defaultIntent = {
    value: intent,
    label: `#${intent}`,
  };

  const {
    formState: { errors },
    getValues,
    control,
    register,
    unregister,
    setValue,
    trigger,
    watch,
    setFocus,
  } = useForm<FormType>({
    mode: 'onChange',
    defaultValues: {
      name,
      intent: defaultIntent,
      disambiguation: hasDisambiguation,
      disambiguation_label: disambiguationLabel,
    },
    resolver: yupResolver(intentFormSchema) as Resolver<FormType>,
  });

  useFocusOnInput('name', errors, setFocus);

  const watchDisambiguation = watch('disambiguation');

  // Create the options for the intents select
  const options =
    cleanIntents?.map((i) => ({
      value: i.intent,
      label: `#${i.intent}`,
    })) ?? [];

  const onChange = useCallback(() => {
    const values = getValues();

    dispatch(
      updateNode({
        nodeId,
        node: {
          ...values,
          intent: clean(values.intent),
        },
      })
    );
  }, [getValues, dispatch, nodeId]);

  // Reset the form when the node id changes
  useEffect(() => {
    setValue('intent', {
      value: intent,
      label: `#${intent}`,
    });
  }, [intent, setValue]);

  const handleIntentChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      setValue('intent', {
        value: event.target.value,
        label: `#${event.target.value}`,
      });
      onChange();
    },
    [onChange, setValue]
  );

  const handleSwitchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setValue('disambiguation_label', name);
        trigger();
      } else {
        dispatch(
          updateDialogAlerts({
            dialogAlerts: {
              alertType: 'error',
              id: nodeId,
              title: t('dialog.intent'),
              body: undefined,
              type: 'intent',
              alertField: 'disambiguation_label',
            },
          })
        );
      }
      onChange();
    },
    [dispatch, name, nodeId, onChange, setValue, t, trigger]
  );

  const nameErrorMessage = errors.name?.message;
  const disambiguationLabelErrorMessage = errors.disambiguation_label?.message;

  useEffect(() => {
    dispatch(
      updateDialogAlerts({
        dialogAlerts: {
          alertType: 'error',
          id: nodeId,
          title: t('dialog.intent'),
          body: capitalizeFirstLetter(nameErrorMessage),
          type: 'intent',
          alertField: 'name',
        },
      })
    );
  }, [dispatch, nameErrorMessage, nodeId, t]);

  useEffect(() => {
    if (watchDisambiguation) {
      dispatch(
        updateDialogAlerts({
          dialogAlerts: {
            alertType: 'error',
            id: nodeId,
            title: t('dialog.intent'),
            body: capitalizeFirstLetter(disambiguationLabelErrorMessage),
            type: 'intent',
            alertField: 'disambiguation_label',
          },
        })
      );
    }
  }, [
    dispatch,
    disambiguationLabelErrorMessage,
    nodeId,
    t,
    watchDisambiguation,
  ]);

  // Register and unregister the 'disambiguation_label' field
  // based on the 'disambiguation' field
  useEffect(() => {
    if (watchDisambiguation) {
      register('disambiguation_label');
    } else {
      unregister('disambiguation_label');
    }
  }, [register, unregister, watchDisambiguation]);

  useEffect(() => {
    // Trigger validation on mount
    trigger();
  }, [trigger]);

  return (
    <ToolkitWrapper type="intent">
      <Input
        label={t('dialog.node_name')}
        placeholder={t('dialog.webview.type_name')}
        error={!!errors.name}
        errorMessage={capitalizeFirstLetter(errors.name?.message)}
        size="small"
        onChange={onChange}
        register={register('name')}
      />

      <Select
        label={t('dialog.intent')}
        options={options}
        size="small-full"
        placeholder={t('intent.select_an_intent')}
        tooltip={t('dialog.select_intent_for_node')}
        error={!!errors.intent}
        onChange={handleIntentChange}
        register={register('intent.value', nodeRules.intent)}
      />

      <Controller
        name="disambiguation"
        control={control}
        render={({ field: { value, onChange: onControllerChange } }) => {
          return (
            <Switch
              reverse
              label={t('brains.disambiguation')}
              disabled={!brain?.disambiguation}
              checked={value}
              size="medium"
              tooltip={t('dialog.mark_node_disambiguation')}
              onChange={(event) => {
                onControllerChange(event);
                handleSwitchChange(event);
              }}
            />
          );
        }}
      />

      {watchDisambiguation && (
        <div className={styles.disambiguationLabel}>
          <Input
            label={t('dialog.disambiguation_label')}
            name={t('dialog.disambiguation_label')}
            placeholder={t('dialog.disambiguation_label')}
            error={!!errors.disambiguation_label}
            errorMessage={capitalizeFirstLetter(
              errors.disambiguation_label?.message
            )}
            size="small"
            onChange={onChange}
            register={register('disambiguation_label')}
          />
        </div>
      )}
      {nodesLength > 0 && (
        <div className={styles.nodes}>
          <div className={styles.title}>
            <Typography
              variant="label-caps-large"
              color="var(--text-default-gray)"
            >
              {t('dialog.event.used_by')}
            </Typography>
          </div>
          <div className={styles.subTitle}>
            <Typography
              variant="label-regular"
              color="var(--text-default-gray)"
            >
              <Trans i18nKey="dialog.event.jump_to" />
            </Typography>
          </div>
          <div className={styles.nodes__list}>
            {usedIn.map((x) => (
              <button
                key={`${x.nodeId}-${x.label}`}
                type="button"
                onClick={() =>
                  navigate(`/${slug}/brains/${brainId}/dialogs/${x.dialogId}`)
                }
                className={cn({
                  [styles['nodes__icon--event']]:
                    nodeType(x.dialogId, dialogs) === 'event',
                })}
              >
                <IconizedDialog
                  key={x.nodeId}
                  dialogName={x.label}
                  type={nodeType(x.dialogId, dialogs)}
                />
              </button>
            ))}
          </div>
        </div>
      )}
    </ToolkitWrapper>
  );
}

export default ToolkitNodeIntent;
