import { useEffect, useState } from 'react';

import he from 'he';
import rehypeExternalLinks from 'rehype-external-links';
import rehypeStringify from 'rehype-stringify';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import { unified } from 'unified';

export const useMarkdownToHtml = (markdownText: string) => {
  const [html, setHtml] = useState<string>('');
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const convertMarkdownToHtml = async () => {
      try {
        const decodedMarkdown = he.decode(markdownText);
        const result = await unified()
          .use(remarkParse)
          .use(remarkRehype)
          // Open links in new tab
          .use(rehypeExternalLinks, {
            target: '_blank',
            rel: ['noopener', 'noreferrer'],
          })
          .use(rehypeStringify)
          .process(decodedMarkdown);

        setHtml(String(result));
      } catch (err) {
        setError(err as Error);
      }
    };

    convertMarkdownToHtml();
  }, [markdownText]);

  return { html, error };
};
