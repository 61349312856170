import { Fragment, memo, useCallback } from 'react';

import ArrowRightAltSharpIcon from '@mui/icons-material/ArrowRightAltSharp';
import Typography from '@mui/material/Typography';
import isEmpty from 'lodash/isEmpty';
import { Trans, useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { InferType } from 'yup';

import { Banner } from '@/components/atoms/Banner/Banner';
import Button from '@/components/atoms/Button/Button/Button';
import Documentation from '@/components/atoms/Icons/Global/Documentation';
import {
  MODAL_DELETE,
  MODAL_EDIT,
  MODAL_IMPORT,
  MODAL_WARN,
} from '@/components/organisms/Modals/ModalConductor';
import { useAccount } from '@/hooks/useAccount';
import useDialogNodesCache from '@/hooks/useDialogNodesCache';
import useDialogs from '@/hooks/useDialogs';
import useEntities from '@/hooks/useEntities';
import useIntents from '@/hooks/useIntents';
import { OptionBase } from '@/models/common';
import { RootState } from '@/models/state';
import { addTemporalToast } from '@/modules/notifications/redux/actions';
import { popModal, pushModal } from '@/redux/modals/actions';
import {
  clearDialogNodes,
  selectAction,
  updateDialogData,
} from '@/redux/nodes/actions';
import {
  selectNodeIds,
  selectIsNodesDirty,
  selectDraftDialogName,
} from '@/redux/nodes/selectors';
import { getDocsUrl } from '@/util/constants';
import { getRestrictedNames } from '@/util/util';
import {
  dialogRules,
  getEditableTextValidationSchema,
  MAX_DIALOG_NAME_LENGTH,
} from '@/util/validator';

import SubHeader from './SubHeader';
import { useDetectCycle } from './useDetectCycles';

type UsedNodes = OptionBase<{ dialogId: string }>[];

interface RenameProps {
  new_name: string;
}

import styles from './SubHeader.module.scss';

const SubHeaderDialog = () => {
  const { t } = useTranslation();
  const { brainId, dialogId } = useParams();
  const isDraft = dialogId === 'draft';
  const {
    dialogs,
    dialog,
    deleteDialog,
    exportDialog,
    saveDialog,
    updateDialogName,
    importDialog,
    updateStatus,
    createStatus,
  } = useDialogs(brainId, dialogId);
  const { detectCycle } = useDetectCycle(brainId);
  const isUnknown = dialog?.nodes[0]?.type === 'unknown';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useAccount();
  const { entities } = useEntities(brainId);
  const { intents } = useIntents(brainId);

  const { isDirty, draftDialogName, nodes } = useSelector(
    (state: RootState) => ({
      nodes: selectNodeIds(state),
      isDirty: selectIsNodesDirty(state),
      draftDialogName: selectDraftDialogName(state),
    }),
    shallowEqual
  );

  const { findUsedNodes } = useDialogNodesCache();

  const handleDialogNameClick = useCallback(
    (dialog_id) => {
      dispatch(popModal());
      navigate(`/${slug}/brains/${brainId}/dialogs/${dialog_id}`);
    },
    [brainId, dispatch, navigate, slug]
  );

  const handleSaveClick = useCallback(async () => {
    try {
      await saveDialog();
      const cyclicalDialogs = detectCycle();
      const isDialogInCycle = cyclicalDialogs?.find(
        (d) => d.dialog_id === dialogId
      );
      if (isDialogInCycle) {
        dispatch(
          addTemporalToast(
            'warning',
            <div className={styles.loopWrapper}>
              <Typography>{t('dialog.loop')}</Typography>
              <div className={styles.loop}>
                {cyclicalDialogs.slice(0, 3).map((n, index) => (
                  <Fragment key={n.id}>
                    <Button
                      onClick={() => {
                        navigate(
                          `/${slug}/brains/${brainId}/dialogs/${n.dialog_id}`,
                          {
                            state: { actionId: n.action_id },
                          }
                        );
                        setTimeout(
                          () =>
                            dispatch(selectAction({ actionId: n.action_id })),
                          200
                        );
                      }}
                      variant="tertiary"
                      size="small"
                    >
                      {n.dialog_name}{' '}
                      {index < 2 && index !== cyclicalDialogs.length - 1 && (
                        <ArrowRightAltSharpIcon />
                      )}
                    </Button>
                  </Fragment>
                ))}
                {cyclicalDialogs.length > 3 && (
                  <Typography>
                    {t('dialog.loop_more', {
                      count: cyclicalDialogs.length - 3,
                    })}
                  </Typography>
                )}
              </div>
              <Button
                variant="tertiary"
                target="_blank"
                isLink
                href={getDocsUrl('/docs/get_started/dialogs#limits')}
                className={styles.more}
              >
                <Documentation color="var(--icon-default-blue)" />
                {t('common.read_more')}
              </Button>
            </div>,
            {},
            20000
          )
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, [brainId, detectCycle, dialogId, dispatch, navigate, saveDialog, slug, t]);

  const onDeleteClick = useCallback(() => {
    const usedNodes = nodes.reduce((acc, nodeId) => {
      const cache = findUsedNodes({ brainId, name: nodeId }) || [];
      return [...acc, ...cache];
    }, [] as UsedNodes);
    if (
      usedNodes &&
      !isEmpty(usedNodes) &&
      !(usedNodes.length === 1 && dialogId === usedNodes[0].dialogId)
    ) {
      const warning = (
        <Banner
          relativePosition
          variant="critical"
          references={usedNodes}
          onRefClick={handleDialogNameClick}
        >
          <Trans i18nKey="dialog.delete_node" values={[dialog?.name]} />
        </Banner>
      );

      const warnProps = {
        title: t('common.warning'),
        children: warning,
        primaryButtonText: t('common.close'),
      };

      dispatch(pushModal(MODAL_WARN, warnProps));
    } else {
      const deleteProps = {
        title: t('dialog.delete_dialog'),
        subtitle: (
          <Trans
            i18nKey="dialog.delete_dialog_warning"
            values={[dialog?.name]}
          />
        ),
        onDelete: () => {
          dispatch(clearDialogNodes());
          deleteDialog(null);
        },
        secondaryButtonText: t('common.cancel'),
      };

      dispatch(pushModal(MODAL_DELETE, deleteProps));
    }
  }, [
    brainId,
    deleteDialog,
    dialog?.name,
    dialogId,
    dispatch,
    findUsedNodes,
    handleDialogNameClick,
    nodes,
    t,
  ]);

  const handleImportDialog = useCallback(
    (e) => {
      const usedNodes = nodes.reduce((acc, nodeId) => {
        const cache = findUsedNodes({ brainId, name: nodeId }) || [];
        return [...acc, ...cache];
      }, [] as UsedNodes);

      if (isEmpty(usedNodes)) {
        importDialog({ e, intents, entities });
        e.target.value = null;
      } else {
        const warning = (
          <Banner
            relativePosition
            variant="critical"
            references={usedNodes}
            onRefClick={handleDialogNameClick}
          >
            {t('dialog.import_node')}
          </Banner>
        );
        const importProps = {
          subtitle: (
            <Trans
              i18nKey="dialog.import_dialog_warning"
              values={[dialog?.name]}
            />
          ),
          warning,
          onImport: () => {
            importDialog({ e, intents, entities });
            e.target.value = null;
          },
          onBlur: () => (e.target.value = null),
        };
        dispatch(pushModal(MODAL_IMPORT, importProps));
      }
    },
    [
      brainId,
      dialog?.name,
      dispatch,
      entities,
      findUsedNodes,
      handleDialogNameClick,
      importDialog,
      intents,
      nodes,
      t,
    ]
  );

  const handleNameChange = useCallback(
    (text: string) => {
      dispatch(updateDialogData({ new_name: text }));
    },
    [dispatch]
  );

  // Updates the dialog name using the edit info modal
  const onEditClick = useCallback(() => {
    const renameProps = {
      title: t('common.dialog'),
      fieldName: 'new_name',
      fieldValue: draftDialogName,
      rules: dialogRules.name,
      onEdit: async (data: RenameProps) => {
        await updateDialogName({
          dialog_id: dialog?.dialog_id,
          name: data.new_name,
        });
        dispatch(updateDialogData(data));
      },
    };

    dispatch(pushModal(MODAL_EDIT, renameProps));
  }, [dialog?.dialog_id, dispatch, draftDialogName, updateDialogName, t]);

  // Filters out the current dialog name from the list of restricted values
  const restrictedValues = getRestrictedNames(dialogs, dialog?.name);

  const editableTextValidationSchema = getEditableTextValidationSchema(
    MAX_DIALOG_NAME_LENGTH,
    restrictedValues,
    t('intent.dialog_name')
  );

  type YupSchemaType = InferType<typeof editableTextValidationSchema>;

  return (
    <SubHeader<YupSchemaType>
      title={draftDialogName}
      onImportClick={handleImportDialog}
      onExportClick={exportDialog}
      onDeleteClick={onDeleteClick}
      onSaveClick={handleSaveClick}
      onNameChange={handleNameChange}
      disableRename={isUnknown}
      disableSave={!isDirty}
      disableDelete={isDraft || isUnknown}
      fileExtension=".json"
      isLoading={updateStatus === 'pending' || createStatus === 'pending'}
      onEditClick={onEditClick}
      editableTextValidationSchema={
        editableTextValidationSchema as YupSchemaType
      }
      type="dialog"
    />
  );
};

export default memo(SubHeaderDialog);
