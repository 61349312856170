import cn from 'classnames';
import { useSelector } from 'react-redux';

import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';

import { useChatBox } from './useChatBox';
import FileUploadChatBox from '../FileUploadChatBox/FileUploadChatBox';

import styles from './ChatBox.module.scss';

const ChatBox = () => {
  const {
    isClosed,
    isLoading,
    isUnavailable,
    setFiles,
    setUrls,
    files,
    uploading,
    setUploading,
    totalFiles,
  } = useChatBox();

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'conversations', actions.WRITE)
  );

  return (
    <section
      className={cn(styles.chatBox, {
        [styles.isUnavailable]: isUnavailable,
        [styles.isLoading]: isLoading,
        [styles.isClosed]: isClosed,
      })}
    >
      <form
        className={cn(styles.body, {
          [styles.readOnly]: !canWrite,
        })}
      >
        <FileUploadChatBox
          stream
          setFiles={setFiles}
          setUrls={setUrls}
          files={files}
          uploading={uploading}
          setUploading={setUploading}
          // FIXME: This is a hack to make the file upload work
          // accept={acceptedFiles}
          totalFiles={totalFiles}
        />
      </form>
    </section>
  );
};

export default ChatBox;
