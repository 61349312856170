import { useCallback, useEffect, useState } from 'react';

import Box from '@mui/material/Box/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import ShareIcon from '@/components/atoms/Icons/Share';
import StarIcon from '@/components/atoms/Icons/Star';
import Header from '@/components/organisms/Header/Header';
import { reportOptions } from '@/modules/analytics/components/AnalyticsNavigation/constants';
import useFavoriteFilters from '@/modules/analytics/hooks/useFavoriteFilters';

import FavoriteDropdown from '../filters/FavoriteFilters/FavoriteDropdown/FavoriteDropdown';

import styles from './AnalyticsHeader.module.scss';

const AnalyticsHeader = () => {
  const [favoritesOpen, setFavoritesOpen] = useState(false);
  const { filters, handleNewFilter, handleDeleteFilter, selectFavorite } =
    useFavoriteFilters();

  const { t } = useTranslation();
  const location = useLocation();

  const page_key = reportOptions.find((s) =>
    location.pathname.includes(s.link)
  )?.text_key;

  const handleFavoritesClick = useCallback(() => {
    setFavoritesOpen((f) => !f);
  }, []);

  useEffect(() => {
    setFavoritesOpen(false);
  }, [location.pathname]);

  const handleFavoriteClick = useCallback(
    (filter) => () => {
      setFavoritesOpen(false);
      selectFavorite(filter);
    },
    [selectFavorite]
  );

  const handleNewFilterClick = useCallback(() => {
    setFavoritesOpen(false);
    handleNewFilter();
  }, [handleNewFilter]);

  const handleDelete = useCallback(
    (index: number) => () => {
      setFavoritesOpen(false);
      handleDeleteFilter(index);
    },
    [handleDeleteFilter]
  );

  const showFavoriteIcon = !location.pathname.includes('human-chat-logs');
  return (
    <Header>
      <Header.Body>
        <Box display="flex" alignItems="center">
          <Header.Title
            title={
              <Typography
                variant="h2-semi-bold"
                mr="var(--space-24)"
                color="var(--text-default-gray)"
              >
                {t(page_key)}
              </Typography>
            }
          />
          <div className={styles.buttonContainer}>
            {showFavoriteIcon && (
              <Tooltip
                title={t('analytics.button_save_filters')}
                placement="top"
                arrow
              >
                <button
                  className={styles.button}
                  onClick={handleFavoritesClick}
                >
                  <StarIcon />
                </button>
              </Tooltip>
            )}
            {favoritesOpen && (
              <FavoriteDropdown
                onSave={handleNewFilterClick}
                onClick={handleFavoriteClick}
                onDelete={handleDelete}
                favorites={filters}
                setOpen={setFavoritesOpen}
              />
            )}
            <Tooltip placement="top" arrow title={t('analytics.button_share')}>
              <div className={styles.button}>
                <CopyButton
                  data={window.location.href}
                  hideIcon
                  iconPosition="center"
                >
                  <ShareIcon />
                </CopyButton>
              </div>
            </Tooltip>
          </div>
        </Box>
      </Header.Body>
    </Header>
  );
};

export default AnalyticsHeader;
