import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import { integrationsEndpoints as endpoints } from '@/api/endpoints';
import IntegrationsHeader from '@/components/organisms/Headers/IntegrationsHeader';
import IntegrationFacebook from '@/components/organisms/Integrations/Facebook/Facebook';
import IntegrationFront from '@/components/organisms/Integrations/Front/Front';
import IntegrationSkeleton from '@/components/organisms/Integrations/IntegrationSkeleton';
import IntegrationIntercom from '@/components/organisms/Integrations/Intercom/Intercom';
import IntegrationSunco from '@/components/organisms/Integrations/Sunco/Sunco';
import IntegrationViber from '@/components/organisms/Integrations/Viber/Viber';
import IntegrationWebWidget from '@/components/organisms/Integrations/Web/Web';
import IntegrationWhatsapp from '@/components/organisms/Integrations/Whatsapp/Whatsapp';
import IntegrationZendesk from '@/components/organisms/Integrations/Zendesk/Zendesk';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import { useIntegrations } from '@/hooks/useIntegrations';
import { actions } from '@/models/permissions';
import { PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { setIntegrationId } from '@/redux/session/actions';
import { selectDeskId } from '@/redux/session/selectors';
import { pageView } from '@/segment/segment';

function Integration() {
  const { t } = useTranslation();
  const { integrationId } = useParams();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const deskId = useSelector(selectDeskId);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'integrations', actions.WRITE)
  );

  useEffect(() => {
    pageView(PageName.INTEGRATION);
  }, []);

  useEffect(() => {
    dispatch(setIntegrationId(integrationId));
    return () => {
      dispatch(setIntegrationId(null));
    };
  }, [dispatch, integrationId]);

  const { integration, verifyIntegration } = useIntegrations(
    deskId,
    integrationId
  );

  useTitle(
    integration?.type
      ? t(`channels.${integration?.type}`)
      : t('pages.integration')
  );

  useEffect(() => {
    const queryKey = [`${endpoints.integration(deskId, integrationId)}/verify`];
    if (
      !queryClient.getQueryData(queryKey) &&
      canWrite &&
      integration?.type &&
      integration?.type !== 'web'
    ) {
      verifyIntegration();
    }
  }, [
    canWrite,
    deskId,
    integration?.type,
    integrationId,
    queryClient,
    verifyIntegration,
  ]);

  const selectIntegrationComponent = () => {
    switch (integration?.type) {
      case 'facebook':
      case 'instagram':
        return <IntegrationFacebook />;
      case 'viber':
        return <IntegrationViber />;
      case 'web':
        return <IntegrationWebWidget />;
      case 'zendesk':
        return <IntegrationZendesk />;
      case 'intercom':
        return <IntegrationIntercom />;
      case 'whatsapp':
        return <IntegrationWhatsapp />;
      case 'sunco':
        return <IntegrationSunco />;
      case 'front':
        return <IntegrationFront />;
      default:
        return <IntegrationSkeleton />;
    }
  };

  return (
    <>
      <IntegrationsHeader />
      <PageContentWrapper newPlain gutters readOnly={!canWrite} hasBreadcrumbs>
        {integration ? selectIntegrationComponent() : <IntegrationSkeleton />}
      </PageContentWrapper>
    </>
  );
}

export default Integration;
