import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import IconButton from '@/components/atoms/IconButton/IconButton';
import ArrowLeft from '@/components/atoms/Icons/ArrowLeft';
import Close from '@/components/atoms/Icons/Close';

import styles from './WizardNavbar.module.scss';

interface WizardNavbarProps {
  onBack?: () => void;
  onClose?: () => void;
}

export const WizardNavbar = (props: WizardNavbarProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      {props?.onBack && (
        <div className={cn(styles.back)}>
          <IconButton ariaLabel={t('common.back')} onClick={props.onBack}>
            <ArrowLeft />
          </IconButton>
          {t('common.back')}
        </div>
      )}
      {props?.onClose && (
        <div className={styles.close}>
          <IconButton ariaLabel={t('common.back')} onClick={props.onClose}>
            <Close color="var(--icon-default-black)" />
          </IconButton>
        </div>
      )}
    </div>
  );
};
