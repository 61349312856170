import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import { RowInfiniteLoader } from '@/components/organisms/InfiniteLoader/RowInfiniteLoader';
import useBrains from '@/hooks/useBrains';
import useHumanChatLogs from '@/hooks/useHumanChatLogs';
import useLogs from '@/hooks/useLogs';
import { useModalTryIt } from '@/hooks/useModalTryIt';
import { RootState } from '@/models/state';
import AnalyticsFieldset from '@/modules/analytics/components/fieldsets/AnalyticsFieldset/AnalyticsFieldset';
import { addLastSeen } from '@/modules/analytics/redux/actions';
import { isModalOpen } from '@/redux/modals/selectors';
import { selectBrainId } from '@/redux/session/selectors';

import SessionField from './SessionField';

import './SessionsFieldset.scss';

interface Props {
  show?: boolean;
}

const SessionsFieldset = ({ show }: Props) => {
  const { t } = useTranslation();
  const logs = useLogs();
  const humanChatLogs = useHumanChatLogs();
  const { brainId: paramBrainId } = useParams();
  const brainId = useSelector(selectBrainId);
  const { brain, getStatus } = useBrains(brainId);
  const isBrainLogs = brain && getStatus !== 'pending';
  const modalOpen = useSelector((state: RootState) => isModalOpen(state));
  const {
    data,
    numberOfData,
    isLoading,
    previewError,
    contentError,
    refetchContent,
    refetchPreview,
    loadMore,
  } = useMemo(
    () => (isBrainLogs ? logs : humanChatLogs),
    [isBrainLogs, humanChatLogs, logs]
  );
  const [visibleLogs, setVisibleLogs] = useState(data);
  const [hasMoreLogs, setHasMoreLogs] = useState(true);
  const [urlParams] = useSearchParams();
  const sessionId = urlParams.get('session_id');
  const { handleReplayClick, handleAccountReplayClick } = useModalTryIt(
    sessionId,
    !isBrainLogs
  );
  const lastSeen = useSelector((state: RootState) => state.analytics.lastSeen);
  const dispatch = useDispatch();

  const handleReplay = useMemo(() => {
    if (sessionId && getStatus !== 'pending' && brain) {
      return handleReplayClick;
    }

    if (!paramBrainId && sessionId) {
      return handleAccountReplayClick;
    }
    return null;
  }, [
    brain,
    getStatus,
    handleAccountReplayClick,
    handleReplayClick,
    paramBrainId,
    sessionId,
  ]);

  const handleReload = useCallback(() => {
    if (previewError) {
      refetchPreview();
    }
    if (contentError) {
      refetchContent();
    }
  }, [contentError, previewError, refetchContent, refetchPreview]);

  useEffect(() => {
    if (sessionId && !modalOpen) {
      handleReplay && handleReplay();
    }
  }, [handleReplay, sessionId, modalOpen]);

  useEffect(() => {
    if (data) {
      setVisibleLogs(data);
      setHasMoreLogs(true);
    }
  }, [data]);

  const loadMoreItems = useCallback(
    async (startIndex: number, stopIndex: number) => {
      if (!isLoading && stopIndex && loadMore && visibleLogs) {
        const result = await loadMore();
        const newData = result?.data;
        if (!newData || isEmpty(newData.rows)) {
          setHasMoreLogs(false);
        } else {
          setVisibleLogs([...visibleLogs, ...newData.rows]);
        }
      }
    },
    [isLoading, loadMore, visibleLogs]
  );
  const getItemSize = useCallback(
    (index: number) => {
      const mainLayoutWidth = document.querySelector(
        '.main .inner__main__content'
      )?.clientWidth;
      if (mainLayoutWidth && visibleLogs) {
        const tags = visibleLogs[index]?.tags;
        if (tags && tags.length > 6) {
          if (mainLayoutWidth < 1000) {
            return 160;
          }
          if (mainLayoutWidth < 1400) {
            return 120;
          }

          return 80;
        }
        if (mainLayoutWidth < 900) {
          return 160;
        }
      }

      return 100;
    },
    [visibleLogs]
  );

  const isItemLoaded = useCallback(
    (index: number) => {
      if (hasMoreLogs) {
        return index < visibleLogs?.length;
      }
      return true;
    },
    [hasMoreLogs, visibleLogs?.length]
  );

  const isLastSeen = useCallback(
    (index: number) => {
      return lastSeen[lastSeen.length - 1] === visibleLogs[index]?.session_id;
    },
    [lastSeen, visibleLogs]
  );

  const isRecentlySeen = useCallback(
    (index) => {
      return lastSeen.includes(visibleLogs[index]?.session_id);
    },
    [lastSeen, visibleLogs]
  );

  if (!show) {
    return null;
  }

  if (isLoading || previewError || !visibleLogs) {
    return (
      <AnalyticsFieldset
        title={t('analytics.sessions')}
        isLoading={isLoading}
        fullWidth
        onReload={handleReload}
        error={previewError}
        hasExport={false}
      />
    );
  }

  if (isEmpty(data)) {
    return (
      <AnalyticsFieldset
        title={t('analytics.sessions')}
        fullWidth
        hasFooter={false}
        isEmpty
        hasExport={false}
      />
    );
  }

  const handleClick = ({ session }) => {
    if (brainId) {
      handleReplayClick(session.session_id);
    } else {
      handleAccountReplayClick(session.session_id, session.brain_id);
    }
    if (!(lastSeen[lastSeen.length - 1] === session.session_id)) {
      dispatch(addLastSeen(session.session_id));
    }
  };

  const itemCount = visibleLogs.length + 1;
  const indicator = `${visibleLogs.length} / ${numberOfData}`;
  return (
    <>
      <AnalyticsFieldset
        fullWidth
        title={t('analytics.sessions')}
        indicator={indicator}
        hasExport={false}
      >
        <div className="logs-field field-header">
          <p className="logs-field__user">{t('analytics.fields.user')}</p>
          <p className="logs-field__session">{t('analytics.fields.session')}</p>
          {!isBrainLogs && (
            <p className="logs-field__desk">{t('common.environment')}</p>
          )}
          <p className="logs-field__attributes">
            {t('analytics.fields.attributes')}
          </p>

          {!isBrainLogs && (
            <p className="logs-field__agents-brains">
              {t('analytics.fields.agents_brains')}
            </p>
          )}
          <p className={cn('logs-field__rating', { human_logs: !isBrainLogs })}>
            {t('analytics.fields.rating')}
          </p>
          <p className={cn('logs-field__tags', { human_logs: !isBrainLogs })}>
            {t('analytics.fields.tags')}
          </p>
          <p className="logs-field__replay" />
        </div>
        <div style={{ height: '600px', overflow: 'none' }}>
          <RowInfiniteLoader
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            isItemLoaded={isItemLoaded}
            getItemSize={getItemSize}
            className="logs-list"
          >
            {(index, style) => {
              if (isItemLoaded(index) && visibleLogs[index]) {
                const lastSeen = isLastSeen(index);
                const recentlySeen = isRecentlySeen(index);
                return (
                  <SessionField
                    session={visibleLogs[index]}
                    style={style}
                    onReplayClick={handleClick}
                    index={index}
                    lastSeen={lastSeen}
                    isBrainLogs={isBrainLogs}
                    recentlySeen={recentlySeen}
                  />
                );
              }
              if (numberOfData && visibleLogs.length < numberOfData) {
                return (
                  <div className="logs-loading" style={style}>
                    <CircularProgress size={40} />
                  </div>
                );
              }
              return null;
            }}
          </RowInfiniteLoader>
        </div>
      </AnalyticsFieldset>
    </>
  );
};

export default memo(SessionsFieldset);
