import React, { useState, useRef, PropsWithChildren } from 'react';

import ChevronRight from '@mui/icons-material/ChevronRight';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

import styles from './CustomDetails.module.scss';

type CustomDetailsProps = {
  title: string;
  open?: boolean;
};

export const CustomDetails = ({
  children,
  title,
  open = true,
}: PropsWithChildren<CustomDetailsProps>) => {
  const [isOpen, setIsOpen] = useState(open);
  const detailsRef = useRef(null);

  const toggleOpen = () => {
    setIsOpen(detailsRef.current?.open ?? false);
  };

  return (
    <details ref={detailsRef} onToggle={toggleOpen} open={isOpen}>
      <summary className={styles.summary}>
        {isOpen ? (
          <ExpandMore fontSize="small" htmlColor="var(--icon-default-gray)" />
        ) : (
          <ChevronRight fontSize="small" htmlColor="var(--icon-default-gray)" />
        )}
        <Typography variant="body-semi-bold" color="var(--text-default-gray)">
          {title}
        </Typography>
      </summary>
      <div className={styles.content}>{children}</div>
    </details>
  );
};
