import { useCallback, useMemo } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Magic } from 'magic-sdk';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { validate as uuidValidate } from 'uuid';

import { userEndpoints as endpoints } from '@/api/endpoints';
import { callGet, callPost, callPut } from '@/api/fetcher';
import { User, Exists, SignUpUser } from '@/models/user';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { loadUser } from '@/redux/user/actions';
import { selectIsAuthenticated } from '@/redux/user/selectors';
import { MAGIC_PUBLIC_KEY, UI_LANGUAGES } from '@/util/constants';

export type TempUser = Pick<User, 'name' | 'avatar'> & {
  invitation_code?: string;
};

type LoginWithMagicLink = {
  email: string;
  redirectTo: string;
  isInvitation?: boolean;
};

export const API = {
  createUser: async (params: TempUser): Promise<SignUpUser> =>
    callPost(endpoints.createUser, params),
  getAgent: async (id: string): Promise<User> => callGet(endpoints.agent(id)),
  getUser: async (): Promise<User> => callGet(endpoints.user),
  logoutUser: async (): Promise<void> => callGet(endpoints.logout),
  updateUser: async (user: Partial<User>): Promise<User> =>
    callPut(endpoints.user, user),
  userExists: async (email: string): Promise<Exists> =>
    callPost(endpoints.userExists, { email }),
};

const magic = new Magic(MAGIC_PUBLIC_KEY);

const useUser = (id?: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { mutate: signUp, status: signUpStatus } = useMutation<
    SignUpUser,
    Error,
    TempUser
  >({
    mutationFn: (params) => API.createUser(params),
    onSuccess: ({ user }) => {
      dispatch(loadUser());
      queryClient.setQueryData<User>([endpoints.user], (prev) => ({
        ...prev,
        ...user,
      }));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { data: user, status: getUserStatus } = useQuery<User | null>({
    queryKey: [endpoints.user],
    queryFn: API.getUser,
    enabled: isAuthenticated,
  });

  const { data: agent, status: getAgentStatus } = useQuery<User>({
    queryKey: [endpoints.agent(id)],
    queryFn: () => API.getAgent(id),
    enabled: isAuthenticated && !!id && uuidValidate(id),
  });

  const { mutate: updateUser, status: updateStatus } = useMutation<
    User,
    Error,
    Partial<User>
  >({
    mutationFn: API.updateUser,
    onSuccess: (resp) => {
      queryClient.setQueryData<User>([endpoints.user], (prev) => ({
        ...prev,
        ...resp,
      }));
      dispatch(addTemporalToast('success', t('user.user_updated')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const clearUserCache = useCallback(() => {
    magic.user
      .logout()
      .finally(() => queryClient.removeQueries({ queryKey: [endpoints.user] }));
  }, [queryClient]);

  /**
   * Login with Magic link. If `isLogin` is true, the user needs to exist.
   * If `isLogin` is false, the user will be created as a temp user.
   */
  const loginWithMagicLink = useCallback(
    async ({ email, redirectTo, isInvitation = false }: LoginWithMagicLink) => {
      const showUI = true;
      const token = await magic.auth.loginWithMagicLink({ email, showUI });
      const res = await fetch(endpoints.magicLink(redirectTo, isInvitation), {
        method: 'POST',
        headers: { authorization: `Bearer ${token}` },
      });

      if (res.status >= 400) {
        const { error } = await res.json();
        if (error) {
          throw new Error(error);
        }
      }
    },
    []
  );

  const supportedLanguageOptions = useMemo(() => {
    const languages = UI_LANGUAGES;
    return languages.map((lang) => ({
      value: lang,
      label: t(`languages.${lang}`),
    }));
  }, [t]);

  return {
    user,
    getUserStatus,
    agent,
    getAgentStatus,
    updateUser,
    updateStatus,
    loginWithMagicLink,
    signUp,
    signUpStatus,
    clearUserCache,
    supportedLanguageOptions,
  };
};

export default useUser;
