import Typography from '@mui/material/Typography';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Input from '@/components/atoms/Input/Input';
import TextAreaAsInput from '@/components/atoms/Input/TextAreaAsInput';
import { capitalizeFirstLetter } from '@/util/util';

import { Form } from './ModalAIAgentCreate';

export const Step3 = () => {
  const { t } = useTranslation();
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<Form>();

  return (
    <>
      <Input
        {...register('company_name')}
        onChange={(e) => {
          setValue('company_name', e.target.value, {
            shouldValidate: true,
          });
        }}
        label={t('billing.company_name')}
        placeholder={t('billing.company_name')}
        error={!!errors.company_name?.message}
        errorMessage={capitalizeFirstLetter(errors.company_name?.message)}
      />

      <Typography
        variant="label-caps-large"
        color="var(--text-default-gray)"
        component="h3"
        mb="var(--space-4)"
      >
        {t('ai_agents.modal.company_info')}
      </Typography>
      <Typography
        component="p"
        variant="label-regular"
        color="var(--text-placeholder-gray)"
        mb="var(--space-8)"
      >
        {t('ai_agents.modal.company_info_description')}
      </Typography>

      <TextAreaAsInput
        {...register('company_info')}
        onChange={(e) => {
          setValue('company_info', e.target.value, {
            shouldValidate: true,
          });
        }}
        size="large"
        trimValue
        height="large"
        error={!!errors.company_info?.message}
        errorMessage={capitalizeFirstLetter(errors.company_info?.message)}
      />
    </>
  );
};
