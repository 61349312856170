import { useCallback } from 'react';

import isEmpty from 'lodash/isEmpty';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { InferType } from 'yup';

import { Banner } from '@/components/atoms/Banner/Banner';
import {
  MODAL_DELETE,
  MODAL_EDIT,
  MODAL_WARN,
} from '@/components/organisms/Modals/ModalConductor';
import { useAccount } from '@/hooks/useAccount';
import useDialogNodesCache from '@/hooks/useDialogNodesCache';
import useEntities from '@/hooks/useEntities';
import usePrompt from '@/hooks/usePrompt';
import useValues from '@/hooks/useValues';
import { pushModal, popModal } from '@/redux/modals/actions';
import { clearEntityValues, updateEntityData } from '@/redux/values/actions';
import { selectEntityDraft } from '@/redux/values/selectors';
import { getRestrictedNames, preventClickThrough } from '@/util/util';
import {
  entityRules,
  getEditableTextValidationSchema,
  LENGTH_S,
} from '@/util/validator';

import { convertSpacesToUnderscores } from './helper';
import SubHeader from './SubHeader';

const SubHeaderEntity = () => {
  const { t } = useTranslation();
  const { brainId, entityName } = useParams();

  const {
    entities,
    deleteEntity,
    handleSaveEntity,
    createStatus,
    updateStatus,
  } = useEntities(brainId, entityName);

  const { isDirty, isDraft, importEntity, exportEntity } = useValues(
    brainId,
    entityName
  );

  const { slug } = useAccount();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const entityDraft = useSelector(selectEntityDraft);
  const { findUsedNodes } = useDialogNodesCache();

  const onImportClick = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      preventClickThrough(e);
      importEntity(e.target.files[0]);
      e.target.value = '';
    },
    [importEntity]
  );

  const handleDialogNameClick = useCallback(
    (dialog_id: string) => {
      dispatch(popModal());
      navigate(`/${slug}/brains/${brainId}/dialogs/${dialog_id}`);
    },
    [brainId, dispatch, navigate, slug]
  );

  const onDeleteClick = useCallback(() => {
    const usedIn = findUsedNodes({ brainId, name: entityName });

    if (usedIn && !isEmpty(usedIn)) {
      const warning = (
        <Banner
          relativePosition
          variant="critical"
          references={usedIn}
          onRefClick={handleDialogNameClick}
        >
          <Trans i18nKey="entity.delete_warn" values={[entityName]} />
        </Banner>
      );
      const warnProps = {
        title: t('common.warning'),
        children: warning,
        primaryButtonText: t('common.close'),
      };

      dispatch(pushModal(MODAL_WARN, warnProps));
    } else {
      const deleteProps = {
        title: t('entity.delete_entity'),
        subtitle: (
          <Trans i18nKey="entity.delete_warning" values={[entityName]} />
        ),
        onDelete: () => {
          dispatch(clearEntityValues());
          deleteEntity();
        },
      };
      dispatch(pushModal(MODAL_DELETE, deleteProps));
    }
  }, [
    brainId,
    deleteEntity,
    dispatch,
    entityName,
    findUsedNodes,
    handleDialogNameClick,
    t,
  ]);

  usePrompt(isDirty, t('prompts.leave_warning'), undefined, () =>
    handleSaveEntity(false)
  );

  const handleNameChange = useCallback(
    (name) => {
      dispatch(updateEntityData({ new_name: name, previousName: entityName }));
    },
    [dispatch, entityName]
  );

  const { values } = useValues(brainId, entityName);

  const isSaveEnabled = (isDirty || isDraft) && values?.length > 0;

  // Updates the entity name using the edit info modal
  const onEditClick = useCallback(() => {
    const renameProps = {
      resource: t('common.entity'),
      fieldName: 'new_name',
      fieldValue: entityDraft?.new_entity,
      rules: entityRules.name,
      onEdit: async (data) => {
        dispatch(
          updateEntityData({
            new_name: data.new_name,
            previousName: entityName,
          })
        );
        dispatch(popModal());
      },
    };

    dispatch(pushModal(MODAL_EDIT, renameProps));
  }, [t, entityDraft?.new_entity, dispatch, entityName]);

  // Filters out the current dialog name from the list of restricted values
  const restrictedValues = getRestrictedNames(entities, entityName, 'entity');

  const editableTextValidationSchema = getEditableTextValidationSchema(
    LENGTH_S,
    restrictedValues,
    t('entity.entity_name'),
    /^[a-z0-9-_]+$/i // Matches alphanumeric, hyphen, and underscore
  );

  type YupSchemaType = InferType<typeof editableTextValidationSchema>;

  //TODO Remove loading state when cant get an entity
  return (
    <SubHeader<YupSchemaType>
      title={entityDraft?.new_entity}
      onImportClick={onImportClick}
      onExportClick={exportEntity}
      onDeleteClick={onDeleteClick}
      onSaveClick={handleSaveEntity}
      onNameChange={handleNameChange}
      disableSave={!isSaveEnabled}
      disableDelete={isDraft}
      isLoading={updateStatus === 'pending' || createStatus === 'pending'}
      fileExtension=".csv"
      onEditClick={onEditClick}
      editableTextValidationSchema={
        editableTextValidationSchema as YupSchemaType
      }
      customSanitizer={convertSpacesToUnderscores}
    />
  );
};

export default SubHeaderEntity;
