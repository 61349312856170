import { memo, useCallback, useMemo } from 'react';

import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Avatar } from '@/components/atoms/Avatar/Avatar/Avatar';
import Button from '@/components/atoms/Button/Button/Button';
import CopyButton from '@/components/atoms/CopyButton/CopyButton';
import Chat from '@/components/atoms/Icons/Chat';
import Clock from '@/components/atoms/Icons/Clock';
import Environment from '@/components/atoms/Icons/Environment';
import { integrationIconByType } from '@/components/atoms/Icons/integrationIconByType';
import Brains from '@/components/atoms/Icons/Navigation/Brains';
import Rules from '@/components/atoms/Icons/Navigation/Rules';
import Rating from '@/components/atoms/Icons/Rating';
import ToolTag from '@/components/atoms/Icons/ToolTag';
import Input from '@/components/atoms/Input/Input';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import useBrains from '@/hooks/useBrains';
import { Desk } from '@/models/desk';
import { Channel, Log } from '@/modules/analytics/models';
import { useRules } from '@/modules/rules/hooks/useRules';
import { popModal } from '@/redux/modals/actions';
import { selectAccountSlug } from '@/redux/session/selectors';
import { durationFormat } from '@/util/util';

import styles from './InformationFieldNew.module.scss';

type ValueProps =
  | string
  | number
  | boolean
  | Channel
  | Desk
  | Log['participated_agents']
  | Log['participated_brains']
  | Log['rule_ids'];

type Props = {
  label: string;
  type:
    | 'desk'
    | 'channel'
    | 'duration'
    | 'messages'
    | 'agents'
    | 'brains'
    | 'rules'
    | 'text'
    | 'rating'
    | 'tags'
    | 'feedback';
  value: ValueProps;
  deskId?: string;
  sessionId?: string;
  isLoading?: boolean;
  isTest?: boolean;
};

export const InformationFieldNew = memo(
  ({ label, type, value, deskId, sessionId, isLoading, isTest }: Props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const slug = useSelector(selectAccountSlug);
    const { brains: allBrains } = useBrains();
    const { rules: allRules } = useRules(deskId);
    const desk = useMemo(
      () =>
        type === 'desk' &&
        value && (
          <Button
            className={styles.button}
            variant="tertiary"
            size="small"
            noGutters
            onClick={() => {
              dispatch(popModal());
              navigate(`/${slug}/environments/${(value as Desk)?.desk_id}`);
            }}
          >
            <Environment color="var(--icon-default-blue)" />{' '}
            {(value as Desk)?.name}
          </Button>
        ),
      [dispatch, navigate, slug, type, value]
    );

    const Icon = useMemo(
      () => integrationIconByType[value as string] || (() => null),
      [value]
    );

    const channel = useMemo(
      () =>
        type === 'channel' &&
        value && (
          <span className={styles.valueBox}>
            <Icon size={14} />
            <div>
              <span className={styles.capitalize}>{value as string}</span>
              <span>{isTest && `(${t('try_it.modal.test')})`}</span>
            </div>
          </span>
        ),
      [Icon, isTest, t, type, value]
    );

    const duration = useMemo(
      () =>
        type === 'duration' &&
        value && (
          <span className={styles.valueBox}>
            <Clock size={14} color="var(--icon-default-gray)" />
            <>{durationFormat(t, value.toString())}</>
          </span>
        ),
      [type, value, t]
    );

    const messages = useMemo(
      () =>
        type === 'messages' &&
        value && (
          <span className={styles.valueBox}>
            <Chat size={14} color="var(--icon-default-gray)" />
            <>{value}</>
          </span>
        ),
      [type, value]
    );

    const agents = useMemo(
      () =>
        type === 'agents' &&
        value &&
        (value as Log['participated_agents'])?.map((agent) => (
          <div key={agent.agent_id} className={styles.valueBox}>
            <Avatar size="small" userId={agent.agent_id} />
            <>{agent.agent_name}</>
          </div>
        )),
      [type, value]
    );

    const brains =
      type === 'brains' &&
      value &&
      (value as Log['participated_brains'])?.map((brain) => (
        <div key={brain.brain_id} className={styles.brains}>
          <div
            className={styles.valueBox}
            title={t('try_it.visit_brain_session')}
          >
            <Button
              className={styles.button}
              variant="tertiary"
              size="small"
              noGutters
              onClick={() => {
                dispatch(popModal());
                navigate(
                  `/${slug}/brains/${brain.brain_parent_id}/logs?session_id=${sessionId}`
                );
              }}
            >
              <Brains color="var(--icon-default-blue)" />
              {
                allBrains?.find((b) => b.brain_id === brain.brain_parent_id)
                  ?.name
              }
            </Button>
            <div>
              <StatusBadge
                variant="neutral"
                label={
                  brain.brain_version === 0
                    ? t('common.draft')
                    : `v${brain.brain_version}`
                }
                withIcon={false}
              />
            </div>
          </div>
        </div>
      ));

    const rules =
      type === 'rules' &&
      value &&
      (value as Log['rule_ids'])?.map((ruleId) => (
        <div key={ruleId} className={styles.brains}>
          <div className={styles.valueBox}>
            <Button
              className={styles.button}
              variant="tertiary"
              size="small"
              noGutters
              onClick={() => {
                dispatch(popModal());
                navigate(`/${slug}/environments/${deskId}/rules/${ruleId}`);
              }}
            >
              <Rules color="var(--icon-default-blue)" />
              {allRules?.rules?.find((r) => r.rule_id === ruleId)?.name}
            </Button>
            <div></div>
          </div>
        </div>
      ));

    const tags = useMemo(
      () =>
        type === 'tags' &&
        value && (
          <div className={styles.valueBox}>
            <div className={styles.icon}>
              <ToolTag color="var(--icon-default-blue)" size={16} />
            </div>
            <div className={styles.tags}>
              {(value as Log['tags']).map((tag) => (
                <Chip key={tag} size="small" label={tag} color="primary" />
              ))}
            </div>
          </div>
        ),
      [type, value]
    );

    const generateRandomNumber = useCallback((min: number, max: number) => {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }, []);

    const renderValue = useCallback(() => {
      switch (type) {
        case 'desk':
        case 'channel':
        case 'duration':
        case 'messages':
        case 'agents':
        case 'brains':
          return value ? (
            <div className={styles.container}>
              <div className={styles.key}>{`${label}:`}</div>
              <div className={styles.value}>
                {isLoading ? (
                  <Skeleton
                    variant="text"
                    width={generateRandomNumber(80, 160)}
                    height={24}
                  />
                ) : (
                  <>
                    {desk}
                    {channel}
                    {duration}
                    {messages}
                    {agents}
                    {brains}
                    {rules}
                  </>
                )}
              </div>
            </div>
          ) : null;

        case 'tags':
        case 'rules':
          return (value as Log['tags'])?.length > 0 ? (
            <div className={styles.container}>
              <div className={styles.key}>{`${label}:`}</div>
              <div className={styles.value}>
                {isLoading ? (
                  <Skeleton
                    variant="text"
                    width={generateRandomNumber(80, 160)}
                    height={24}
                  />
                ) : (
                  <>
                    {tags}
                    {rules}
                  </>
                )}
              </div>
            </div>
          ) : null;

        case 'text':
          return isLoading ? (
            <Skeleton
              variant="text"
              width={generateRandomNumber(80, 160)}
              height={24}
            />
          ) : value ? (
            <div className={styles.text}>
              <Input
                name={label}
                label={label}
                value={value as string}
                size="small"
                readOnly
              />
              <span className={styles.copyButton}>
                <CopyButton data={value as string} />
              </span>
            </div>
          ) : null;

        case 'feedback':
          return isLoading ? (
            <Skeleton
              variant="text"
              width={generateRandomNumber(80, 160)}
              height={64}
            />
          ) : value ? (
            <div className={styles.feedback}>
              <div className={styles.label}>{label}</div>
              <div className={styles.feedbackText}>{value as string}</div>
            </div>
          ) : null;

        case 'rating':
          return isLoading ? (
            <Skeleton
              variant="text"
              width={generateRandomNumber(80, 160)}
              height={24}
            />
          ) : (
            <div className={styles.ratingWrapper}>
              <div className={styles.rating}>{label}</div>
              {value ? (
                <Rating stars={value as number} />
              ) : (
                <span>{t('common.no_rating')}</span>
              )}
            </div>
          );
        default:
          return null;
      }
    }, [
      agents,
      brains,
      channel,
      duration,
      desk,
      generateRandomNumber,
      isLoading,
      label,
      messages,
      rules,
      tags,
      type,
      value,
      t,
    ]);

    return renderValue();
  }
);

InformationFieldNew.displayName = 'InformationFieldNew';
