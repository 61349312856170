import { useEffect } from 'react';

import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Input from '@/components/atoms/Input/Input';
import Select from '@/components/atoms/Select/Select';
import { BrainLanguage } from '@/models/brain';
import { QuickLanguageOption } from '@/modules/aiAgents/components/QuickLanguageOption/QuickLanguageOption';
import { languageByCountryCode } from '@/util/constants';
import { capitalizeFirstLetter } from '@/util/util';

import { Form } from './ModalAIAgentCreate';

export const Step2 = () => {
  const { t } = useTranslation();
  const {
    watch,
    setValue,
    register,

    getValues,
    unregister,
    formState: { errors },
  } = useFormContext<Form>();

  const languageOptions = Object.keys(languageByCountryCode).map((i) => ({
    value: i.toLowerCase(),
    label: t(`languages.${i.toLowerCase()}`, {
      defaultValue: languageByCountryCode[i],
    }),
  }));

  useEffect(() => {
    unregister('company_name');
    unregister('company_info');
  }, [unregister]);

  const defaultName =
    t(`ai_agents.types.${getValues('brain_type')}.title`) +
    ' ' +
    t('analytics.fields.agent');

  const watchLanguage = watch('language');

  return (
    <>
      <Input
        {...register('name')}
        onChange={async (e) => {
          setValue('name', e.target.value, {
            shouldValidate: true,
          });
        }}
        defaultValue={defaultName}
        size="medium"
        label={t('common.name')}
        placeholder={t('signup.name_placeholder')}
        error={!!errors.name?.message}
        errorMessage={capitalizeFirstLetter(errors.name?.message)}
      />

      <Select
        {...register('language')}
        onChange={async (e) => {
          setValue('language', e.target.value, {
            shouldValidate: true,
          });
        }}
        defaultValue={getValues('language') ?? ''}
        label={t('field.language')}
        placeholder={t('common.select_an_option')}
        options={languageOptions}
        size="full"
        error={!!errors.language?.message}
        errorMessage={capitalizeFirstLetter(errors.language?.message)}
      />

      <Box display="flex" gap="var(--space-8)" alignItems="center">
        <QuickLanguageOption
          handleClick={() => {
            setValue('language', BrainLanguage.ENGLISH, {
              shouldValidate: true,
            });
          }}
          language="en"
          isActive={watchLanguage === 'en'}
        />
        <QuickLanguageOption
          handleClick={() => {
            setValue('language', BrainLanguage.PORTUGUESE_BRAZILIAN, {
              shouldValidate: true,
            });
          }}
          language="pt-br"
          isActive={watchLanguage === 'pt-br'}
        />
        <QuickLanguageOption
          handleClick={() => {
            setValue('language', BrainLanguage.GREEK, {
              shouldValidate: true,
            });
          }}
          language="gr"
          isActive={watchLanguage === 'el'}
        />
      </Box>
    </>
  );
};
