import { useMemo, forwardRef } from 'react';

import cn from 'classnames';

import Spinner from '../../Spinner/Spinner';

import './Button.scss';

type AsLink = {
  isLink: true;
  disabled?: boolean;
} & React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

type AsButton = {
  isLink?: false;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export type ButtonProps = {
  children: React.ReactNode;
  isLoading?: boolean;
  isFullWidth?: boolean;
  className?: string;
  underline?: boolean;
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'social'
    | 'danger'
    | 'toolkit';
  size?: 'xs' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
  unstyled?: boolean;
  noGutters?: boolean;
  preventOverflow?: boolean;
} & (AsLink | AsButton);

const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      children,
      variant = 'primary',
      size = 'medium',
      className = '',
      isLoading = false,
      underline,
      isLink,
      isFullWidth = false,
      unstyled,
      noGutters = false,
      preventOverflow,
      ...moreProps
    } = props;

    const fullClassName = useMemo(
      () =>
        unstyled
          ? className
          : cn(
              {
                button: true,
                button__loading: isLoading,
                button__fullWidth: isFullWidth,

                [`button__${variant}`]: !!variant,
                [`button__${size}`]: !!size,
                underline: !!underline,
                noGutters: noGutters,
                button__preventOverflow: preventOverflow,
              },
              className
            ),
      [
        unstyled,
        className,
        isLoading,
        isFullWidth,
        variant,
        size,
        underline,
        noGutters,
        preventOverflow,
      ]
    );

    if (isLink) {
      return (
        <a
          className={cn(fullClassName, { disabled: moreProps?.disabled })}
          {...(moreProps as React.DetailedHTMLProps<
            React.AnchorHTMLAttributes<HTMLAnchorElement>,
            HTMLAnchorElement
          >)}
          ref={ref as React.Ref<HTMLAnchorElement>}
          tabIndex={moreProps?.disabled || isLoading ? -1 : 0}
        >
          <span className="text_container">{children}</span>
          {isLoading && <Spinner className="button__spinner" />}
        </a>
      );
    }

    return (
      <button
        className={fullClassName}
        {...(moreProps as React.DetailedHTMLProps<
          React.ButtonHTMLAttributes<HTMLButtonElement>,
          HTMLButtonElement
        >)}
        ref={ref as React.Ref<HTMLButtonElement>}
      >
        <span className="text_container">{children}</span>
        {isLoading && <Spinner className="button__spinner" size="small" />}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
