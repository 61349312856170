import { useCallback } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { recommendationsEndpoints as endpoints } from '@/api/endpoints';
import { callGet, callPost, callPut } from '@/api/fetcher';
import {
  RecommendedIntent,
  Recommendation,
  RecommendationConfig,
  PartialRecommendation,
  TYPE,
} from '@/models/recommendations';
import { RootState } from '@/models/state';
import { addErrorTemporalToast } from '@/modules/notifications/redux/actions';
import { appendExpressions, setIntent } from '@/redux/expressions/actions';
import * as actions from '@/redux/recommendations/actions';
import {
  selectIsNewIntentDraft,
  selectFilteredExpressions,
  selectRecommendedIntent,
  selectRecommendation,
} from '@/redux/recommendations/selectors';

import { useAccount } from './useAccount';

type UpdateProps = {
  brainId: string;
} & Partial<Recommendation>;

const API = Object.freeze({
  listRecommendations: async (brainId: string): Promise<Recommendation> =>
    callGet(endpoints.recommendations(brainId)),
  updateRecommendations: async ({
    brainId,
    ...recc
  }: UpdateProps): Promise<Recommendation> =>
    callPut(endpoints.recommendations(brainId), recc),
  configure: async (
    brainId: string,
    config: RecommendationConfig
  ): Promise<Recommendation> =>
    callPost(endpoints.recommendations(brainId), config),
});

//Get all intents and expressions that dont have a flag
const filterRecommendationsResponse = ({
  intents,
  enabled = true,
}: {
  intents: RecommendedIntent[];
  enabled?: boolean;
}) => {
  if (!intents || !Array.isArray(intents) || !enabled) {
    return [];
  }
  return intents.reduce((acc: RecommendedIntent[], i: RecommendedIntent) => {
    const filteredExpressions = i.expressions.filter((e) => !e.flag);
    if (!i.flag && filteredExpressions.length > 0) {
      acc.push({
        ...i,
        expressions: filteredExpressions,
      });
    }
    return acc;
  }, []);
};

const useIntentRecommendation = (brainId: string, intentName?: string) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { slug } = useAccount();

  const isNewIntentDraft = useSelector((state: RootState) =>
    selectIsNewIntentDraft(state, { brainId, intentName })
  );

  const recommendedIntent = useSelector((state: RootState) =>
    selectRecommendedIntent(state, { brainId, intentName })
  );

  const filteredExpressions = useSelector(
    (state: RootState) =>
      selectFilteredExpressions(state, {
        brainId,
        intentName,
      }),
    shallowEqual
  );

  const {
    recommendedIntents,
    isRecommmedationsDirty,
    isRecommendationsEnabled,
  } = useSelector(
    (state: RootState) => selectRecommendation(state, brainId),
    shallowEqual
  );

  const { data: recommendation, status: listStatus } = useQuery<
    Recommendation,
    Error
  >({
    queryKey: [endpoints.recommendations(brainId)],
    queryFn: () => API.listRecommendations(brainId),
    enabled: Boolean(brainId),
  });
  type UpdateType =
    | {
        intents: RecommendedIntent[];
      }
    | {
        auto_intents: RecommendedIntent[];
      };
  //Update the recommendation object for a specific brain
  const { mutate: updateRecommendations } = useMutation<
    Recommendation,
    Error,
    UpdateType
  >({
    mutationFn: (data) => API.updateRecommendations({ brainId, ...data }),
    onSuccess: (resp) => {
      const updatedIntents = filterRecommendationsResponse(resp);
      queryClient.setQueryData<Recommendation>(
        [endpoints.recommendations(brainId)],
        () => ({ ...resp, intents: updatedIntents })
      );
      dispatch(
        actions.setRecommendations({
          brainId,
          recommendations: {
            ...resp,
            intents: updatedIntents,
          },
        })
      );
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const { mutate: configureRecommendation, status: configureStatus } =
    useMutation<Recommendation, Error, RecommendationConfig>({
      mutationFn: (config) => API.configure(brainId, config),
      onSuccess: (resp) => {
        queryClient.setQueryData<Recommendation>(
          [endpoints.recommendations(brainId)],
          (prev: Recommendation) => Object.assign(prev ?? {}, resp)
        );
      },
      onError: (error) => {
        dispatch(addErrorTemporalToast(error));
      },
    });

  const updateRecommendation = useCallback(() => {
    updateRecommendations({ intents: recommendedIntents });
  }, [recommendedIntents, updateRecommendations]);

  const acceptNewRecommendation = useCallback(() => {
    const newIntent = {
      brain_id: brainId,
      collection: '',
      description: '',
      intent: recommendedIntent?.intent,
      expressions: recommendedIntent?.expressions?.map((e) => e.expression),
    };
    dispatch(setIntent(newIntent));
    dispatch(
      actions.acceptRecommendation({
        intent: recommendedIntent?.intent,
        brainId,
      })
    );
  }, [
    brainId,
    dispatch,
    recommendedIntent?.expressions,
    recommendedIntent?.intent,
  ]);

  const dismissNewRecommendation = useCallback(() => {
    const updatedRecommedations = recommendedIntents?.map((item) =>
      item.intent === intentName
        ? {
            ...item,
            flag: 'rejected',
          }
        : item
    ) as RecommendedIntent[];
    updateRecommendations({ intents: updatedRecommedations });
    navigate(`/${slug}/brains/${brainId}/intents`);
  }, [
    brainId,
    intentName,
    navigate,
    recommendedIntents,
    slug,
    updateRecommendations,
  ]);

  //Update redux store and intent when user accepts or rejects a single expression
  const updateRecommendedExpression = (
    type: 'accept' | 'reject',
    text: string
  ) => {
    const updatedRecommendedExpressions = recommendedIntent?.expressions?.map(
      (item) =>
        item.expression === text
          ? {
              expression: item.expression,
              flag: type === 'accept' ? 'accepted' : 'rejected',
            }
          : item
    );
    dispatch(
      actions.updateDraftRecommendations({
        intentName,
        brainId,
        expressions: updatedRecommendedExpressions,
      })
    );
    if (type === 'accept') {
      const newExpression = text;
      dispatch(
        appendExpressions({
          newExpressions: [newExpression],
        })
      );
    }
  };

  //Update redux store and intent when user accepts or rejects all the expressions at once
  const updateRecommendedExpressions = (
    action: 'accept' | 'reject',
    filteredSortedExpressions: {
      text: string;
    }[]
  ) => {
    const updatedExpressions = filteredSortedExpressions.map((item) => ({
      expression: item.text,
      flag: action === 'accept' ? 'accepted' : 'rejected',
    }));

    dispatch(
      actions.updateDraftRecommendations({
        intentName,
        brainId,
        expressions: updatedExpressions,
      })
    );
    if (action === 'accept') {
      const newExpressions = updatedExpressions.map((e) => e.expression);
      dispatch(
        appendExpressions({
          newExpressions,
        })
      );
    }
  };

  const setRecommendations = (
    newRecommendations: PartialRecommendation,
    type: TYPE
  ) => {
    const updatedIntents = filterRecommendationsResponse({
      intents: newRecommendations?.intents,
      enabled: newRecommendations?.enabled,
    });

    dispatch(
      actions.setRecommendations({
        brainId,
        type,
        recommendations: {
          ...newRecommendations,
          intents: updatedIntents,
        },
      })
    );
  };

  return {
    setRecommendations,
    recommendation,
    updateRecommendations,
    updateRecommendedExpression,
    filteredExpressions,
    recommendedIntents,
    isNewIntentDraft,
    updateRecommendation,
    recommendedIntent,
    configureRecommendation,
    configureStatus,
    listStatus,
    acceptNewRecommendation,
    dismissNewRecommendation,
    updateRecommendedExpressions,
    isRecommmedationsDirty,
    isRecommendationsEnabled,
  };
};

export default useIntentRecommendation;
