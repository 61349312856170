import { useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { integrationsEndpoints as endpoints } from '@/api/endpoints';
import { callGet, callPost } from '@/api/fetcher';
import { WhatsAppIntegration } from '@/models/integration';
import { addErrorTemporalToast } from '@/modules/notifications/redux/actions';
import { selectAccountSlug } from '@/redux/session/selectors';
import {
  initializeFacebookSdk,
  performWhatsappOAuth,
} from '@/util/facebook-sdk';

import { useIntegrations } from './useIntegrations';
interface WhatsappData {
  verified_name: string;
  code_verification_status: string;
  display_phone_number: string;
  quality_rating: string;
  id: string;
}

export const endpoint = (slug: string, deskId: string, integrationId: string) =>
  `/www/api/integrations/whatsapp/${slug}/desks/${deskId}/integrations/${integrationId}/whatsapp`;

export const useWhatsapp = () => {
  const { t } = useTranslation();
  const { deskId, integrationId } = useParams();
  const dispatch = useDispatch();
  const slug = useSelector(selectAccountSlug);
  const queryClient = useQueryClient();
  const [phoneNumberId, setPhoneNumberId] = useState<string>();
  const [wabaId, setWabaId] = useState<string>();
  const [oauthCode, setOauthCode] = useState<string>();
  const [oauthError, setOauthError] = useState<string>();
  const [isConnecting, setIsConnecting] = useState<boolean>();

  const { integration, updateIntegration } =
    useIntegrations<WhatsAppIntegration>(deskId, integrationId);

  const config = integration?.config;
  const isManual = !!(config?.is_manual || config?.app_secret);
  const isConfigured = Boolean(
    config?.access_token &&
      config?.phone_number_id &&
      (config?.app_secret || config?.page_id)
  );

  const onManualClick = useCallback(() => {
    updateIntegration({
      ...integration,
      config: {
        ...integration?.config,
        is_manual: true,
      },
    });
  }, [integration, updateIntegration]);

  const onOAuthClick = useCallback(async () => {
    await performWhatsappOAuth(
      setPhoneNumberId,
      setWabaId,
      setOauthCode,
      setOauthError,
      t
    );
  }, [setPhoneNumberId, setWabaId, setOauthCode, setOauthError, t]);

  useEffect(() => {
    // Load Facebook SDK asynchronously
    initializeFacebookSdk();
  }, []);

  useEffect(() => {
    // We only have the oauthCode after a completed WhatsappOAuth flow
    if (oauthCode && wabaId && phoneNumberId) {
      // Set the "Connect" button's state to loading in the fieldset
      setIsConnecting(true);
      // Call server to get access_token, subscribe to the WABA, register the phone number and update the integration
      const callApi = async () => {
        try {
          await callPost(
            `${window?.location?.origin}/www/api/integrations/whatsapp/callback`,
            {
              code: oauthCode,
              waba_id: wabaId,
              phone_number_id: phoneNumberId,
              integration_id: integrationId,
              desk_id: deskId,
              slug,
            }
          );
          // Refetch the integation's data
          queryClient.invalidateQueries({
            queryKey: [
              endpoints.integration(deskId, integrationId),
              endpoints.integrations(deskId),
            ],
          });
        } catch (error) {
          if (error?.code === 'update-integration') {
            setOauthError(t('errors.whatsapp_duplicated'));
          } else {
            setOauthError(error.message || t('errors.generic'));
          }
        } finally {
          setIsConnecting(false);
        }
      };

      callApi();
    }
  }, [
    deskId,
    integrationId,
    oauthCode,
    phoneNumberId,
    queryClient,
    slug,
    wabaId,
  ]);

  useEffect(() => {
    if (oauthError) {
      dispatch(addErrorTemporalToast(oauthError));
      setIsConnecting(false);
      setOauthError('');
    }
  }, [dispatch, oauthError, t]);

  const { data, isLoading } = useQuery<WhatsappData>({
    queryKey: [
      endpoint(slug, deskId, integrationId),
      integration?.config?.access_token,
      integration?.config?.verify_token,
      integration?.config?.app_secret,
      integration?.config?.phone_number_id,
    ],
    queryFn: () => callGet(endpoint(slug, deskId, integrationId)),
    enabled: isConfigured,
  });

  const uri = useMemo(() => {
    const display_phone = data?.display_phone_number;
    const phone = display_phone?.replace(/(\+| |\(|\)|-)/g, '');

    if (!phone) {
      return null;
    }

    let uri = `https://wa.me/${phone}`;
    if (config?.prefilled_message) {
      uri += `?text=${encodeURIComponent(config?.prefilled_message)}`;
    }
    return uri;
  }, [config?.prefilled_message, data?.display_phone_number]);

  const connectionStatus = useMemo(() => {
    if (
      integration?.config?.access_token &&
      integration?.config?.phone_number_id &&
      integration?.config?.page_id
    ) {
      return 'connected';
    }
    return 'not-connected';
  }, [
    integration?.config?.access_token,
    integration?.config?.page_id,
    integration?.config?.phone_number_id,
  ]);

  return {
    uri,
    data,
    isConfigured,
    isLoading,
    error: !isLoading && !data?.display_phone_number,
    isManual,
    integration,
    connectionStatus,
    onManualClick,
    onOAuthClick,
    isConnecting,
  };
};
