import { useEffect } from 'react';

import Skeleton from '@mui/material/Skeleton';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import { Banner } from '@/components/atoms/Banner/Banner';
import SubHeaderEntity from '@/components/organisms/SubHeader/SubHeaderEntity';
import SubNavEntity from '@/components/organisms/SubNav/SubNavEntity';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import { useAccount } from '@/hooks/useAccount';
import useBrains from '@/hooks/useBrains';
import useEntities from '@/hooks/useEntities';
import { actions } from '@/models/permissions';
import { PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectEntityName } from '@/redux/session/selectors';
import { pageView } from '@/segment/segment';

import styles from './Entities.module.scss';

export default function Entities() {
  const { t } = useTranslation();
  const { brainId } = useParams();
  const { brain } = useBrains(brainId);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );
  useTitle(t('pages.entities', { 0: brain?.name }));

  useEffect(() => {
    pageView(PageName.ENTITIES);
  }, []);

  const { slug } = useAccount();
  const { entities, createDraftEntity } = useEntities(brainId);
  const entityName = useSelector(selectEntityName);

  useEffect(() => {
    // If there are no entities, create a new one
    // This is the empty state for entities
    if (entities?.length === 0) {
      createDraftEntity();
    }
  }, [entities, createDraftEntity]);

  if (entities?.length) {
    return (
      <Navigate
        to={`/${slug}/brains/${brainId}/entities/${
          entityName ?? entities[0].entity
        }`}
        replace
      />
    );
  }

  return (
    <>
      {!canWrite && (
        <Banner variant="neutral" relativePosition centered padding="small">
          {t('permissions.read_only')}
        </Banner>
      )}

      <div
        className={cn(styles.wrapper, {
          [styles.readOnly]: !canWrite,
        })}
      >
        <SubNavEntity />
        <SubHeaderEntity />
        <PageContentWrapper fullHeight>
          <Skeleton animation="wave" variant="rectangular" height={48} />
        </PageContentWrapper>
      </div>
    </>
  );
}
