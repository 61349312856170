import {
  MAX_THRESHOLD_PERCENTAGE,
  MIN_THRESHOLD_PERCENTAGE,
} from '@/components/organisms/Intents/Performance/PerformanceBar/utils';
import { DraftIntent, Intent } from '@/models/intent';
import { RecommendedIntent } from '@/models/recommendations';
import { TreeItem } from '@/redux/dialogs/selectors';
import { sortObjectsByIntent } from '@/redux/recommendations/selectors';
import { UsedNodes } from '@/redux/usedNodes/actions';

interface TreeItemRecommended extends TreeItem {
  hasSuggestions?: boolean;
  needsAction?: boolean;
}

export type Collections = {
  [key: string]: TreeItemRecommended[];
};

/**
 * Generates a folder name based on existing collections.
 *
 * @param {Object} collections - An object that contains a list of collections.
 * @param {String} [folderName='folder'] - The name of the folder, to be appended after the number.
 * @return {String} Returns a folder name in the format of 'XX_[folderName]', where XX is a two-digit number. If the `collections` object is empty or does not contain any keys that match the pattern `/(\d+)_[folderName]`, the function returns `'01_[folderName]'`.
 */

export const makeCollectionName = (collections, folderName = 'folder') => {
  const keys = Object.keys(collections);
  const pattern = new RegExp(`(\\d+)_${folderName}`);
  const numbers = keys
    .filter((key) => pattern.test(key))
    .map((key) => parseInt(key.match(pattern)[1]));
  const max = numbers.length > 0 ? Math.max(...numbers) : 0;
  const nextNumber = (max + 1).toString().padStart(2, '0');
  return `${nextNumber}_${folderName}`;
};

/**
 * Generates an intent/entity/dialog name based on existing items.
 *
 * @param {Array} items - An array that contains a list of intents/entities/dialogs.
 * @param {String} [key] - The key that containes the name ( intent/entity/name ).
 * @return {String} Returns a name in the format of '[itemName]_XX', where XX is a two-digit number.
 */

export const makeItemsName = (items, key, itemName = '') => {
  if (!items) {
    return '';
  }
  const pattern = new RegExp(`${itemName}(\\d+)`);
  const numbers = items
    .filter((item) => pattern.test(item[key]))
    .map((item) => parseInt(item[key].match(pattern)[1]));
  const max = numbers.length > 0 ? Math.max(...numbers) : 0;
  const nextNumber = (max + 1).toString().padStart(2, '0');
  return `${itemName}${nextNumber}`;
};

/**
 * Groups items into collections
 *
 * @param {Array} items - An array of items to be grouped into collections
 * @returns {Object} An object containing collections of items, where each key is the name of the collection and its value is an array of items that belong to that collection. If an item doesn't belong to any collection, it will be added to a collection named `_orphans`.
 */
export const makeCollections = (items) => {
  return items.reduce(
    (acc, item) => {
      if (!acc[item.collection] && item.collection !== '') {
        acc[item.collection] = [];
      }

      if (item.collection === '' || item.collection === null) {
        acc._orphans.push(item);
      } else {
        acc[item.collection].push(item);
      }
      return acc;
    },
    { _orphans: [] }
  );
};

export const groupIntents = (
  slug: string,
  brainId: string,
  intents: Intent[],
  recommendations: RecommendedIntent[],
  t: (key: string) => string,
  usedNodes: UsedNodes,
  sessionIntentName: string,
  optimalPhrases: number,
  intentDraft: DraftIntent,
  isDraft: boolean
): Collections => {
  if (!intents || !usedNodes) {
    return { _orphans: [] };
  }

  const flagsToHide = ['rejected', 'dismissed', 'modified', 'deleted'];
  const recommendationsCopy = recommendations ? [...recommendations] : [];
  const result = intents.reduce((acc, item) => {
    const existingIntentIndex = recommendationsCopy?.findIndex(
      (r) => r.intent === item.intent && !r.flag
    );

    const maxRecommendedPhrases = optimalPhrases - item.expressions.length;

    const expressions = sortObjectsByIntent(
      recommendationsCopy?.[existingIntentIndex]?.expressions,
      item.intent,
      maxRecommendedPhrases
    );

    let hasSuggestions = false;
    if (existingIntentIndex >= 0) {
      if (
        expressions?.length > 0 &&
        item?.expressions?.length <= optimalPhrases
      ) {
        hasSuggestions = true;
      }
      recommendationsCopy.splice(existingIntentIndex, 1);
    }

    const isUsed = usedNodes[`${brainId}_${item?.intent}`];

    const percentage = (item?.expressions?.length / optimalPhrases) * 100;

    const needsAction =
      percentage > MAX_THRESHOLD_PERCENTAGE ||
      percentage < MIN_THRESHOLD_PERCENTAGE;

    const isSameNameAsPrevious = item.intent === intentDraft.previous_intent;
    const displayName = isSameNameAsPrevious
      ? intentDraft.new_intent
      : item.intent;

    return [
      ...acc,
      {
        name: displayName,
        id: displayName,
        collection: item.collection,
        link: `/${slug}/brains/${brainId}/intents/${item.intent}`,
        hasSuggestions,
        needsAction: (isUsed && !isUsed.length) || needsAction,
      },
    ];
  }, [] as TreeItemRecommended[]);

  let formattedIntents = recommendationsCopy
    .reduce((acc, item) => {
      if (flagsToHide.indexOf(item.flag) !== -1) {
        return acc;
      }
      let collection = t('intent.recommendations.collection');
      if (item.flag === 'accepted') {
        collection = '';
      }
      return [
        ...acc,
        {
          name: item.intent,
          id: item.intent,
          collection,
          link: `/${slug}/brains/${brainId}/intents/${item.intent}`,
          hasSuggestions: false,
          usedInDialog: false,
        },
      ];
    }, result as TreeItemRecommended[])
    .sort((a, b) => {
      const nameA = a.name?.toLowerCase();
      const nameB = b.name?.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

  if (isDraft) {
    const previousCollection = intents?.find(
      (e) => e.intent === sessionIntentName
    )?.collection;
    formattedIntents = [
      {
        name: intentDraft.new_intent,
        id: 'draft',
        collection: previousCollection ?? '',
        link: `/${slug}/brains/${brainId}/intents/draft`,
        usedInDialog: false,
      },
      ...formattedIntents,
    ] as TreeItemRecommended[];
  }

  const newCollections = makeCollections(formattedIntents);

  return newCollections;
};
