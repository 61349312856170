import { useCallback } from 'react';

import Box from '@mui/material/Box/Box';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import Button from '@/components/atoms/Button/Button/Button';
import Tab from '@/components/atoms/Tab/Tab';
import Tabs from '@/components/atoms/Tabs/Tabs';
import Header from '@/components/organisms/Header/Header';
import { selectAccountSlug } from '@/redux/session/selectors';

interface Props {
  onSaveClick: () => void;
  isDirty: boolean;
  isLoading?: boolean;
  brainId: string;
  tabValue: string;
  setTabValue: (index: string) => void;
  readOnly?: boolean;
}

export const WebhookHeader = ({
  onSaveClick,
  isDirty,
  isLoading = false,
  brainId,
  tabValue,
  setTabValue,
  readOnly = false,
}: Props) => {
  const navigate = useNavigate();
  const slug = useSelector(selectAccountSlug);
  const { t } = useTranslation();

  const onCancel = useCallback(() => {
    navigate(`/${slug}/brains/${brainId}/webhooks`);
  }, [brainId, navigate, slug]);

  return (
    <Header>
      <Header.BreadCrumbs />

      <Box mt={1.5}>
        <Header.Navigation>
          <Tabs
            value={tabValue}
            onChange={(_, newValue) => {
              setTabValue(newValue);
            }}
            aria-label={t('webhook.header.update')}
          >
            <Tab
              value="0"
              label={t('webhook.header.webhook_setup')}
              data-testid="webhook_setup_tab"
            />
            <Tab
              value="1"
              label={t('webhook.header.advanced_test')}
              data-testid="webhook_advanced_test"
            />
          </Tabs>
          <Box mb={0.5}>
            <Header.Actions>
              <Button variant="tertiary" onClick={onCancel}>
                {t('webhook.header.cancel')}
              </Button>
              <Button
                onClick={onSaveClick}
                disabled={!isDirty || readOnly}
                isLoading={isLoading}
                data-testid="save_button"
              >
                {t('webhook.header.save')}
              </Button>
            </Header.Actions>
          </Box>
        </Header.Navigation>
      </Box>
    </Header>
  );
};

export default WebhookHeader;
