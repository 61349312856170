import { memo, useCallback, useEffect, useRef, useState } from 'react';

import pick from 'lodash/pick';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';

import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import usePrompt from '@/hooks/usePrompt';
import useWebhooks from '@/hooks/useWebhooks';
import { actions } from '@/models/permissions';
import { PageName } from '@/models/segment';
import { RootState } from '@/models/state';
import { Webhook as WebhookType, WebhookTestResults } from '@/models/webhook';
import { getPermissions } from '@/redux/permissions/selectors';
import { setBrainId } from '@/redux/session/actions';
import { pageView } from '@/segment/segment';
import { testWebhookSchemas, testWebhookValues } from '@/util/constants';

import ConfigSection from './ConfigSection';
import TestSection from './TestSection';
import WebhookHeader from './WebhookHeader';

import styles from './Webhook.module.scss';

type Form = Pick<WebhookType, 'name' | 'url' | 'token'>;

const Webhook = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { brainId, webhookId, slug } = useParams();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState('0');
  const [results, setResults] = useState<WebhookTestResults | null>(null);
  const [hasUpdatedContext, setHasUpdatedContext] = useState(false);
  const [hasSavedChanges, setHasSavedChanges] = useState(false);
  const [defaultValue, setDefaultValue] = useState(testWebhookValues);
  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );

  useEffect(() => {
    if (brainId) {
      dispatch(setBrainId({ brainId }));
    }
  }, [brainId, dispatch]);

  useEffect(() => {
    pageView(PageName.WEBHOOK);
  }, []);

  const {
    updateWebhook,
    createWebhook,
    testWebhook,
    webhook,
    updateStatus,
    createStatus,
    testStatus,
  } = useWebhooks(brainId, webhookId !== 'draft' ? webhookId : undefined);
  useTitle(t('pages.webhook', { 0: webhook?.name || t('common.draft') }));
  const [headersObj, setHeadersObj] = useState<{ [key: string]: string }>({});
  const [isHeadersChecked, setIsHeadersChecked] = useState(
    webhook?.headers?.length > 0
  );
  const [isHeadersDirty, setIsHeadersDirty] = useState(false);
  const [editorErrors, setEditorErrors] = useState<{ [key: string]: string }[]>(
    []
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    getValues,
  } = useForm<Form>({
    mode: 'onChange',
  });
  const editorRef = useRef(null);
  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;

    // https://github.com/suren-atoyan/monaco-react#monaco-instance
    // https://microsoft.github.io/monaco-editor/playground.html#extending-language-services-configure-json-defaults
    // https://json-schema.org/learn/miscellaneous-examples.html
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      schemas: testWebhookSchemas,
    });
  };

  const handleEditorChange = useCallback((value) => {
    try {
      const jsonObject = JSON.parse(value);
      setDefaultValue(jsonObject);
    } catch (e) {
      console.warn(e, 'Invalid JSON');
    }
  }, []);

  useEffect(() => {
    if (webhook) {
      setIsHeadersChecked(webhook?.headers?.length > 0);
      reset({
        name: webhook?.name,
        url: webhook?.url,
        token: webhook?.token,
      });
    }
  }, [reset, webhook]);

  useEffect(() => {
    if (webhook) {
      setHeadersObj(
        webhook?.headers?.reduce((acc, header) => {
          return {
            ...acc,
            [header.name]: header.value,
          };
        }, {}) || {}
      );
    }
  }, [webhook]);

  useEffect(() => {
    if (webhook?.headers?.length == 0 && !isHeadersChecked) {
      setIsHeadersDirty(false);
    }
  }, [isHeadersChecked, webhook?.headers?.length]);

  const handleSetHeadersObj = useCallback(
    (obj) => {
      if (!isHeadersDirty) {
        setIsHeadersDirty(true);
      }
      setHeadersObj(obj);
    },
    [isHeadersDirty]
  );

  const handleSaveClick = useCallback(() => {
    const values = getValues();
    const headers = Object.entries(headersObj).map(([key, value]) => ({
      name: key,
      value,
    }));
    const test_schema = pick(results, ['context']);
    if (webhookId === 'draft') {
      createWebhook(
        {
          brain_id: brainId,
          ...values,
          headers: isHeadersChecked ? headers : [],
          test_schema: test_schema ? test_schema : undefined,
        },
        {
          onSuccess: () => {
            setIsHeadersDirty(false);
            setHasSavedChanges(true);
            reset({
              name: '',
              url: '',
              token: '',
            });
          },
        }
      );
    } else {
      updateWebhook(
        {
          brain_id: brainId,
          webhook_id: webhookId,
          ...values,
          headers: isHeadersChecked ? headers : [],
        },
        {
          onSuccess: () => {
            setIsHeadersDirty(false);
            setHasSavedChanges(true);
            reset({
              name: '',
              url: '',
              token: '',
            });
          },
        }
      );
    }
  }, [
    brainId,
    createWebhook,
    getValues,
    headersObj,
    isHeadersChecked,
    reset,
    results,
    updateWebhook,
    webhookId,
  ]);

  const updateContext = useCallback(() => {
    const test_schema = pick(results, ['context']);
    updateWebhook(
      {
        brain_id: brainId,
        webhook_id: webhookId,
        test_schema: test_schema ? test_schema : undefined,
      },
      {
        onSuccess: () => {
          setHasUpdatedContext(true);
        },
      }
    );
  }, [brainId, results, updateWebhook, webhookId]);

  const handleToggleHeaders = useCallback(() => {
    if (isHeadersChecked && webhook?.headers?.length > 0) {
      setIsHeadersDirty(true);
    }
    setIsHeadersChecked((prev) => !prev);
  }, [isHeadersChecked, webhook?.headers?.length]);

  const handleTestClick = useCallback(() => {
    const values = getValues();
    const headers = Object.entries(headersObj).map(([key, value]) => ({
      name: key,
      value,
    }));

    testWebhook(
      {
        webhook: {
          ...values,
          headers: isHeadersChecked ? headers : [],
        },
        test_parameters:
          tabValue === '0'
            ? testWebhookValues
            : JSON.parse(editorRef.current.getValue()),
      },
      {
        onSuccess: (resp) => {
          setResults(resp);
          setHasUpdatedContext(false);
        },
      }
    );
  }, [getValues, headersObj, isHeadersChecked, tabValue, testWebhook]);

  usePrompt(
    (isDirty || isHeadersDirty) && !hasSavedChanges,
    t('common.header_prompt'),
    undefined,
    handleSubmit(handleSaveClick)
  );

  useEffect(() => {
    if (hasSavedChanges) {
      navigate(`/${slug}/brains/${brainId}/webhooks`);
    }
  }, [brainId, hasSavedChanges, slug, navigate]);

  return (
    <>
      <WebhookHeader
        onSaveClick={handleSubmit(handleSaveClick)}
        isDirty={(isDirty || isHeadersDirty) && isValid}
        isLoading={updateStatus === 'pending' || createStatus === 'pending'}
        brainId={brainId}
        setTabValue={setTabValue}
        tabValue={tabValue}
        readOnly={!canWrite}
      />
      <PageContentWrapper
        newPlain2
        whiteBackground
        noGutters
        readOnly={!canWrite}
      >
        <div className={styles.wrapper}>
          <ConfigSection
            readOnly={!canWrite}
            testStatus={testStatus}
            register={register}
            errors={errors}
            webhook={webhook}
            isHeadersChecked={isHeadersChecked}
            headersObj={headersObj}
            handleTestClick={handleSubmit(handleTestClick)}
            handleToggleHeaders={handleToggleHeaders}
            handleSetHeadersObj={handleSetHeadersObj}
            handleEditorDidMount={handleEditorDidMount}
            handleEditorChange={handleEditorChange}
            setEditorErrors={setEditorErrors}
            editorErrors={editorErrors}
            defaultValue={defaultValue}
            tabIndex={tabValue}
          />
          <TestSection
            testResults={results}
            testStatus={testStatus}
            udpateContext={updateContext}
            updateDisabled={hasUpdatedContext || webhookId === 'draft'}
          />
        </div>
      </PageContentWrapper>
    </>
  );
};

export default memo(Webhook);
