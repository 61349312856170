import { memo, useCallback } from 'react';

import cn from 'classnames';
import { useDispatch } from 'react-redux';

import { WizardNavbar } from '@/modules/onboarding/OldOnboarding/components/WizardNavbar/WizardNavbar';
import { popModal } from '@/redux/modals/actions';

import styles from './ModalBrainCreationNew.module.scss';
type Props = {
  children: React.ReactNode;
  step: number;
  setStep: (step: (x: number) => number) => void;
};

const Wrapper = memo(({ children, step, setStep }: Props) => {
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(popModal());
  }, [dispatch]);

  const onBack = useCallback(() => {
    setStep((prev: number) => prev - 1);
  }, [setStep]);

  const getWrapperClass = useCallback(() => {
    return cn(styles.wrapper, {
      [styles.step1]: step === 1,
      [styles.step2]: step === 2,
      [styles.step3]: step === 3,
    });
  }, [step]);

  return (
    <div className={getWrapperClass()}>
      <WizardNavbar
        onClose={onClose}
        onBack={step !== 1 ? onBack : undefined}
      />
      {children}
    </div>
  );
});

Wrapper.displayName = 'Wrapper';

export { Wrapper };
