import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputBase from '@mui/material/InputBase';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DropdownList from '@/components/atoms/DropdownList/DropdownList';
import Close from '@/components/atoms/Icons/Close';
import Create from '@/components/atoms/Icons/Create';
import StatusBadge from '@/components/atoms/StatusBadge/StatusBadge';
import useBrains from '@/hooks/useBrains';
import useCollections from '@/hooks/useCollections';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import { RootState } from '@/models/state';
import { useBundles } from '@/modules/bundles/hooks/useBundles';
import { useDepartments } from '@/modules/departments/hooks/useDepartments';
import { Action, ActionType } from '@/modules/rules/model';
import {
  replaceAction,
  isActionError,
  removeAction,
  revertIsActionOpen,
} from '@/modules/rules/redux/actions';
import {
  selectActionIsOpen,
  selectActionsOptions,
  selectRuleActions,
} from '@/modules/rules/redux/selectors';
import { selectDeskId } from '@/redux/session/selectors';
import { isKeyEnter, isKeyTab, preventClickThrough } from '@/util/util';
import { isValidSlug } from '@/util/validator';

import ActionConfiguration, {
  BrainVersion,
} from '../ActionConfiguration/ActionConfiguration';
import NewActionOptions from '../ActionsModal/NewActionOptions';
import ConditionsModal from '../ConditionsModal/ConditionsModal';

import styles from './ActionDropdownButton.module.scss';

const muiStyles = {
  root: {
    borderRadius: 'var(--border-radius-base)',
    border: '1px solid var(--border-default-gray)',
    height: '30px',
    paddingLeft: '16px',
    fontfamily: 'var(--font-primary)',
    fontSize: 'var(--space-14)',
    lineHeight: 'var(--space-16)',
    width: '160px',
    '&.Mui-focused': {
      border: '1px solid var(--border-default-blue)', // focus
    },
    '&.Mui-error': {
      border: '1px solid red',
    },
  },
};

type Option = {
  value?: string;
  label?: string;
};

export type ButtonProps = {
  brain_parent_id?: string;
  brain_version?: string;
  sla_id?: string;
  tag?: string;
  index?: number;
  labelText?: string;
  grouped?: boolean;
  disabled?: boolean;
  actionType: string;
  version?: number;
  conditionComparison?: string;
  bundleId?: string;
  departmentId?: string;
  collectionId?: string;
};

// Filters an array based on a matching type in another array
function filterArrayByType(arr1: Action[], arr2: { type: string }[]) {
  return arr2.filter((obj2) => !arr1.some((obj1) => obj1.type === obj2.type));
}

const ActionDropdownButton: React.FC<ButtonProps> = forwardRef(
  (
    {
      actionType,
      disabled = false,
      grouped = false,
      labelText,
      index,
      brain_parent_id,
      version,
      tag,
      bundleId,
      departmentId,
      collectionId,
    }: ButtonProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { t } = useTranslation();
    const features = useFeatureFlag();
    const [assignedBrainName, setAssignedBrainName] = useState('');
    const [showActionsOptions, setShowActionsOptions] = useState(false);
    const [input, setInput] = useState<string | null>('');
    const [variant, setVariant] = useState<'normal' | 'active' | 'error'>(
      'normal'
    );
    const [bundle, setBundle] = useState<Option>({});
    const [department, setDepartment] = useState<Option>({});

    const [collection, setCollection] = useState<Option>({});
    const [inputHasError, setInputHasError] = useState(false);
    const [hasClickedBundle, setHasClickedBundle] = useState(false);
    const [hasClickedDepartment, setHasClickedDepartment] = useState(false);
    const [hasClickedCollection, setHasClickedCollection] = useState(false);
    const deskId = useSelector(selectDeskId);
    const actions = useSelector(selectRuleActions);
    const actionsOptions = useSelector(selectActionsOptions);
    const isActionOpen = useSelector((state: RootState) =>
      selectActionIsOpen(state, index)
    );
    const { brain } = useBrains(brain_parent_id);
    const { collections } = useCollections();
    const { bundles } = useBundles(deskId);
    const { departments } = useDepartments(deskId);

    const dispatch = useDispatch();

    useEffect(() => {
      if (brain) {
        setAssignedBrainName(brain?.name);
      }
    }, [brain, brain_parent_id]);

    useEffect(() => {
      const bundleIndex = bundles?.findIndex((x) => {
        return x.bundle_id === bundleId;
      });
      if (bundles?.length > 0) {
        setBundle({ label: bundles[bundleIndex]?.name });
      }
    }, [bundleId, bundles]);

    useEffect(() => {
      if (actionType === ActionType.ADD_TAG) {
        if (!tag) {
          setVariant('error');
          dispatch(isActionError({ index, errorState: true }));
          return;
        }
        setVariant('normal');
      }
      if (actionType === ActionType.ASSIGN_BRAIN) {
        if (!brain_parent_id) {
          setVariant('error');
          dispatch(isActionError({ index, errorState: true }));
          return;
        }
        setVariant('normal');
      }
      if (actionType === ActionType.ASSIGN_DEPARTMENT) {
        if (!departmentId) {
          setVariant('error');
          dispatch(isActionError({ index, errorState: true }));
          return;
        }
        setVariant('normal');
      }
      if (actionType === ActionType.ASSIGN_COLLECTION) {
        if (!collectionId) {
          setVariant('error');
          dispatch(isActionError({ index, errorState: true }));
          return;
        }
        setVariant('normal');
      }
      if (actionType === ActionType.APPLY_BUNDLE) {
        if (!bundleId) {
          setVariant('error');
          dispatch(isActionError({ index, errorState: true }));
          return;
        }
        setVariant('normal');
      }
    }, [
      actionType,
      brain_parent_id,
      dispatch,
      index,
      tag,
      bundleId,
      collectionId,
      departmentId,
    ]);

    const bundleOptions = bundles?.map((bundle) => ({
      label: bundle.name,
      value: bundle.bundle_id,
    }));

    const departmentOptions = departments?.map((department) => ({
      label: department.name,
      value: department.department_id,
    }));

    const collectionOptions = collections?.map((collection) => ({
      label: collection.name,
      value: collection.collection_id,
    }));

    useEffect(() => {
      // // Delete collection in case of de-assigned a brain
      const isCollectionWithoutBrain =
        actions?.some((obj) => obj.type === ActionType.ASSIGN_COLLECTION) &&
        !actions?.some((obj) => obj.type === ActionType.ASSIGN_BRAIN);

      if (isCollectionWithoutBrain) {
        dispatch(
          removeAction(
            actions.findIndex((x) => x.type === ActionType.ASSIGN_COLLECTION)
          )
        );
      }
    }, [actions, dispatch]);

    const handleClose = useCallback(
      (e) => {
        preventClickThrough(e);
        dispatch(removeAction(index));
      },
      [dispatch, index]
    );

    const onKeyPress = useCallback(
      (e) => {
        if (isKeyEnter(e)) {
          handleClose(e);
        }
      },
      [handleClose]
    );

    const handleButtonClick = useCallback(
      (e) => {
        preventClickThrough(e);
        if (actionType === ActionType.CLOSE_CONVERSATION) {
          return;
        }
        dispatch(revertIsActionOpen({ index, isOpenState: !isActionOpen }));
      },
      [actionType, dispatch, index, isActionOpen]
    );

    const handleAddClick = useCallback(() => {
      setShowActionsOptions(true);
    }, []);

    const handleKeyPress = useCallback(
      (e) => {
        if (isKeyTab(e)) {
          // Allow the default Tab behavior to continue
          return;
        }
        preventClickThrough(e);

        if (isKeyEnter(e)) {
          handleAddClick();
        }
      },
      [handleAddClick]
    );

    const calculateActionsOptions = useMemo(() => {
      let result = filterArrayByType(actions, actionsOptions);

      if (!features?.enable_collections_for_rules) {
        result = result.filter((x) => x.type !== ActionType.ASSIGN_COLLECTION);
      }

      const foundTag = result.find((x) => x.type === ActionType.ADD_TAG);

      if (!foundTag) {
        result.unshift({
          type: ActionType.ADD_TAG,
        });
      }
      return result;
    }, [actions, actionsOptions, features]);

    const buttonText = useCallback(() => {
      if (actionType === ActionType.ASSIGN_BRAIN) {
        return !brain_parent_id ? (
          t('brains.select_a_brian')
        ) : (
          <>
            <span className={styles.bold}>{t(`rules.${actionType}`)}</span>{' '}
            {assignedBrainName}
            <StatusBadge
              withIcon={false}
              label={version === 0 ? t('common.draft') : `v${version}`}
              variant="neutral"
            />
          </>
        );
      }

      if (actionType === ActionType.ASSIGN_COLLECTION) {
        return (
          <>
            <span className={styles.bold}>{t('rules.assign_collection')}</span>{' '}
            {collections?.find((x) => x.collection_id === collectionId)?.name}
          </>
        );
      }

      if (actionType === ActionType.ASSIGN_DEPARTMENT) {
        return (
          <>
            <span className={styles.bold}>{t('rules.assign_department')}</span>{' '}
            {departments?.find((x) => x.department_id === departmentId)?.name}
          </>
        );
      }

      if (actionType === ActionType.CLOSE_CONVERSATION) {
        return (
          <>
            <span className={styles.bold}>{t(`rules.${actionType}`)}</span>
          </>
        );
      }

      if (actionType === ActionType.ADD_TAG) {
        return (
          <>
            <span className={styles.bold}>{t('rules.apply_tag')}</span>{' '}
            <span className={styles.tag}>{tag}</span>
          </>
        );
      }

      if (actionType === ActionType.APPLY_BUNDLE) {
        return (
          <>
            <span className={styles.bold}>{t('rules.apply_bundle')}</span>{' '}
            {bundles?.find((x) => x.bundle_id === bundleId)?.name}
          </>
        );
      }
    }, [
      actionType,
      assignedBrainName,
      brain_parent_id,
      bundleId,
      bundles,
      collectionId,
      collections,
      departmentId,
      departments,
      t,
      tag,
      version,
    ]);

    const onInputChange = useCallback(
      (e) => {
        if (actionType === ActionType.ADD_TAG && e.target.value) {
          isValidSlug(e.target.value.trim())
            ? setInputHasError(false)
            : setInputHasError(true);
        }
        e.preventDefault();
        setInput(e.target.value.trim());
      },
      [actionType]
    );

    const handleApplyClick = useCallback(() => {
      const data = {
        index,
        type: ActionType.ADD_TAG,
        tag: input,
        isOpen: false,
      };
      dispatch(replaceAction(data));
    }, [dispatch, index, input]);

    const handleApplyBundle = useCallback(() => {
      const data = {
        index,
        type: ActionType.APPLY_BUNDLE,
        bundle_id: bundle.value,
        isOpen: false,
      };
      dispatch(replaceAction(data));
    }, [bundle, dispatch, index]);

    const handleApplyDepartment = useCallback(() => {
      const data = {
        index,
        type: ActionType.ASSIGN_DEPARTMENT,
        department_id: department.value,
        isOpen: false,
      };
      dispatch(replaceAction(data));
    }, [department, dispatch, index]);

    const handleBrainSubmit = useCallback(
      (data: BrainVersion) => {
        const finalData = {
          ...data,
          index,
          type: ActionType.ASSIGN_BRAIN,
        };
        dispatch(replaceAction(finalData));
        dispatch(revertIsActionOpen({ index, isOpenState: false }));
      },
      [dispatch, index]
    );

    const handleAssignCollection = useCallback(() => {
      const data = {
        index,
        type: ActionType.ASSIGN_COLLECTION,
        collection_id: collection.value,
        isOpen: false,
      };
      dispatch(replaceAction(data));
    }, [collection.value, dispatch, index]);

    const handleBundleClick = useCallback((data) => {
      setHasClickedBundle(true);
      setBundle(data);
    }, []);

    const handleDepartmentClick = useCallback((data) => {
      setHasClickedDepartment(true);
      setDepartment(data);
    }, []);

    const handleCollectionClick = useCallback((data) => {
      setHasClickedCollection(true);
      setCollection(data);
    }, []);

    const onKeyUp = useCallback(
      (e) => {
        if (isKeyEnter(e)) {
          e.target.blur();
          handleApplyClick();
        }
      },
      [handleApplyClick]
    );

    const bundlePlaceholder = useMemo(() => {
      if (hasClickedBundle) {
        return bundle.label;
      }
      if (actionType === ActionType.APPLY_BUNDLE && bundleId) {
        return bundles?.find((x) => x.bundle_id === bundleId)?.name;
      }
      return t('rules.bundle_dot');
    }, [actionType, bundle.label, bundleId, bundles, hasClickedBundle, t]);

    const departmentPlaceholder = useMemo(() => {
      if (hasClickedDepartment) {
        return department.label;
      }
      if (actionType === ActionType.ASSIGN_DEPARTMENT && departmentId) {
        return departments?.find((x) => x.department_id === departmentId)?.name;
      }
      return t('departments.department_dot');
    }, [
      actionType,
      department.label,
      departmentId,
      departments,
      hasClickedDepartment,
      t,
    ]);

    const collectionPlaceholder = useMemo(() => {
      if (hasClickedCollection) {
        return collection.label;
      }
      if (actionType === ActionType.ASSIGN_COLLECTION && collectionId) {
        return collections?.find((x) => x.collection_id === collectionId)?.name;
      }
      return t('rules.collection_dot');
    }, [
      actionType,
      collection.label,
      collectionId,
      collections,
      hasClickedCollection,
      t,
    ]);

    const renderConfiguration = useCallback(() => {
      if (actionType === ActionType.ADD_TAG) {
        return (
          <ConditionsModal
            title="tag"
            buttonText={t('common.apply')}
            handleSubmit={handleApplyClick}
            ref={ref}
            isDirty={!input || inputHasError}
          >
            <div className={styles.inputWrapper}>
              <InputBase
                placeholder={t('rules.type_tag')}
                onChange={onInputChange}
                onKeyUp={onKeyUp}
                sx={muiStyles.root}
                defaultValue={tag || null}
                autoFocus
                error={inputHasError}
              />
              {inputHasError && (
                <p className={styles.errorMessage}>{t('rules.tags_rules')}</p>
              )}
            </div>
          </ConditionsModal>
        );
      }

      if (actionType === ActionType.ASSIGN_BRAIN) {
        return (
          <ActionConfiguration
            version={version}
            initiallySelected={brain_parent_id}
            onSubmit={handleBrainSubmit}
          />
        );
      }

      if (actionType === ActionType.ASSIGN_COLLECTION) {
        return (
          <ConditionsModal
            title={t('rules.collection')}
            buttonText={t('common.apply')}
            handleSubmit={handleAssignCollection}
            ref={ref}
            isDirty={!hasClickedCollection}
          >
            <div className={styles.inputWrapper}>
              <DropdownList
                disabled={!collectionOptions?.length}
                optionClick={handleCollectionClick}
                options={collectionOptions}
                size="xlarge"
                selected={collectionPlaceholder}
              >
                {collectionPlaceholder}
              </DropdownList>
            </div>
          </ConditionsModal>
        );
      }

      if (actionType === ActionType.APPLY_BUNDLE) {
        return (
          <ConditionsModal
            title={t('rules.bundle')}
            buttonText={t('common.apply')}
            handleSubmit={handleApplyBundle}
            ref={ref}
            isDirty={!hasClickedBundle}
          >
            <div className={styles.inputWrapper}>
              <DropdownList
                disabled={!bundleOptions?.length}
                optionClick={handleBundleClick}
                options={bundleOptions}
                size="xlarge"
                selected={bundlePlaceholder}
              >
                {bundlePlaceholder}
              </DropdownList>
            </div>
          </ConditionsModal>
        );
      }
      if (actionType === ActionType.ASSIGN_DEPARTMENT) {
        return (
          <ConditionsModal
            title={t('departments.department')}
            buttonText={t('common.apply')}
            handleSubmit={handleApplyDepartment}
            ref={ref}
            isDirty={!hasClickedDepartment}
          >
            <div className={styles.inputWrapper}>
              <DropdownList
                disabled={!departmentOptions?.length}
                optionClick={handleDepartmentClick}
                options={departmentOptions}
                size="xlarge"
                selected={departmentPlaceholder}
              >
                {departmentPlaceholder}
              </DropdownList>
            </div>
          </ConditionsModal>
        );
      }
    }, [
      actionType,
      brain_parent_id,
      bundleOptions,
      bundlePlaceholder,
      collectionOptions,
      collectionPlaceholder,
      departmentOptions,
      departmentPlaceholder,
      handleApplyBundle,
      handleApplyClick,
      handleApplyDepartment,
      handleAssignCollection,
      handleBrainSubmit,
      handleBundleClick,
      handleCollectionClick,
      handleDepartmentClick,
      hasClickedBundle,
      hasClickedCollection,
      hasClickedDepartment,
      input,
      inputHasError,
      onInputChange,
      onKeyUp,
      ref,
      t,
      tag,
      version,
    ]);

    return (
      <>
        <div
          className={cn(styles.wrapper, {
            [styles.grouped]: !grouped,
          })}
          ref={ref}
        >
          <button
            className={cn(styles.button, [styles.variant], {
              [styles.disable]: disabled,
              [styles.grouped]: grouped,
              [styles.error]: variant === 'error' && !isActionOpen,
            })}
            onClick={handleButtonClick}
            aria-label={labelText}
          >
            {buttonText()}
            <span
              className={cn(styles.closeButton, { [styles.disable]: disabled })}
              role="button"
              onClick={handleClose}
              onKeyDown={onKeyPress}
              tabIndex={0}
            >
              <Close
                size={14}
                color={
                  variant === 'error' && !isActionOpen
                    ? 'var(--icon-default-error)'
                    : 'var(--icon-default-blue)'
                }
              />
            </span>
          </button>

          {grouped && (
            <span
              className={cn(styles.create, {
                [styles.disable]: disabled,
              })}
              role="button"
              onClick={handleAddClick}
              onKeyDown={handleKeyPress}
              tabIndex={0}
            >
              <Create
                color={
                  variant === 'normal' || variant === 'active'
                    ? 'var(--icon-default-blue)'
                    : 'var(--icon-disabled-gray)'
                }
              />
            </span>
          )}

          {isActionOpen && (
            <ClickAwayListener
              onClickAway={() =>
                dispatch(revertIsActionOpen({ index, isOpenState: false }))
              }
            >
              {renderConfiguration()}
            </ClickAwayListener>
          )}

          {showActionsOptions && (
            <NewActionOptions
              options={calculateActionsOptions}
              handleOptionClick={() => setShowActionsOptions(false)}
              index={index}
            />
          )}
        </div>
      </>
    );
  }
);

ActionDropdownButton.displayName = 'ActionDropdownButton';

export default ActionDropdownButton;
