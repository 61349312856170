import Chip from '@mui/material/Chip';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import { TagAction } from '@/models/action';

import styles from './Tag.module.scss';

interface TagProps {
  action: TagAction;
}

const Tag = ({ action }: TagProps) => {
  const { t } = useTranslation();
  const { tags = [] } = action;

  return (
    <>
      {tags.map((tab) => (
        <Chip
          className={styles.tag}
          key={tab}
          size="small"
          label={tab}
          color="primary"
        />
      ))}
      {isEmpty(tags) && <p>{t('dialog.no_tags')}</p>}
    </>
  );
};

export default Tag;
