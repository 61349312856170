/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { Session } from '@/models/session';

const defaultState: Session = {
  account_id: null,
  account_slug: null,
  brain_id: null,
  desk_id: null,
  rule_id: null,
  integration_id: null,
  session_id: null,
  intent_name: null,
  dialog_id: null,
  entity_name: null,
  version: 0,
  collection_id: null,
  bundle_id: null,
};

const slice = createSlice({
  name: 'session',
  initialState: defaultState,
  reducers: {
    setBrainId: (state, action) => {
      const { brainId, version = 0 } = action.payload;
      state.brain_id = brainId;
      state.version = version;
    },
    setDeskId: (state, action) => {
      state.desk_id = action.payload;
    },
    setCollectionId: (state, action) => {
      state.collection_id = action.payload;
    },
    setBroadcastId: (state, action) => {
      state.broadcast_id = action.payload;
    },
    setDatasourceId: (state, action) => {
      state.datasource_id = action.payload;
    },
    setDialogId: (state, action) => {
      state.dialog_id = action.payload;
    },
    setRuleId: (state, action) => {
      state.rule_id = action.payload;
    },
    setBundleId: (state, action) => {
      state.bundle_id = action.payload;
    },
    setSessionId: (state, action) => {
      state.session_id = action.payload;
    },
    setIntegrationId: (state, action) => {
      state.integration_id = action.payload;
    },
    setIntentName: (state, action) => {
      state.intent_name = action.payload;
    },
    setEntityName: (state, action) => {
      state.entity_name = action.payload;
    },
    setAccountId: (state, action) => {
      state.account_id = action.payload;
    },

    setAccountSlug: (state, action) => {
      state.account_id = null;
      state.account_slug = action.payload;
      state.brain_id = null;
      state.desk_id = null;
      state.version = 0;
      window.X_MOVEO_ACCOUNT_SLUG = state.account_slug;
    },

    // This method bypasses the setAccountSlug method during onboarding as it resets the desk_id value, which needs to be preserved.
    setOnboardingSlug: (state, action) => {
      state.account_slug = action.payload;
      window.X_MOVEO_ACCOUNT_SLUG = state.account_slug;
    },

    resetBrain: (state) => {
      state.brain_id = defaultState.brain_id;
      state.dialog_id = defaultState.dialog_id;
      state.entity_name = defaultState.entity_name;
      state.intent_name = defaultState.intent_name;
    },
  },
});
export const {
  setAccountId,
  setAccountSlug,
  setBrainId,
  setDeskId,
  setIntegrationId,
  setCollectionId,
  setBroadcastId,
  setDatasourceId,
  setSessionId,
  setRuleId,
  setBundleId,
  setOnboardingSlug,
  setIntentName,
  setDialogId,
  setEntityName,
  resetBrain,
} = slice.actions;
export default slice.reducer;
