import { useCallback, useMemo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useTitle } from 'react-use';

import Button from '@/components/atoms/Button/Button/Button';
import DialogImage from '@/components/atoms/Images/DialogImage';
import SubHeaderDialog from '@/components/organisms/SubHeader/SubHeaderDialog';
import SubNavDialog from '@/components/organisms/SubNav/SubNavDialog';
import EmptyPage from '@/components/templates/EmptyPage/EmptyPage';
import PageContentWrapper from '@/components/templates/PageContentWrapper/PageContentWrapper';
import useBrains from '@/hooks/useBrains';
import useDialogs from '@/hooks/useDialogs';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import { getPermissions } from '@/redux/permissions/selectors';
import {
  selectAccountSlug,
  selectBrainId,
  selectDialogId,
} from '@/redux/session/selectors';

import styles from './Dialogs.module.scss';

const greetingStrings = ['greeting', 'greetings', 'hello', 'initial', 'hi'];

export default function Dialogs() {
  const { t } = useTranslation();
  const brainId = useSelector(selectBrainId);
  const { brain } = useBrains(brainId);
  const slug = useSelector(selectAccountSlug);
  const dialogId = useSelector(selectDialogId);
  const { dialogs, listStatus, createDraftDialog } = useDialogs(brainId);

  const canWrite = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.WRITE)
  );
  useTitle(t('pages.dialogs', { 0: brain?.name }));

  const greetingDialog = useMemo(
    () =>
      dialogs?.find((dialog) =>
        greetingStrings.includes(dialog.name.toLowerCase())
      ),
    [dialogs]
  );

  const onCreateClick = useCallback(() => {
    createDraftDialog();
  }, [createDraftDialog]);

  // Send the user to the 'greeting' dialog or the first dialog if it exists
  if (listStatus === 'success' && dialogs.length) {
    return (
      <Navigate
        to={`/${slug}/brains/${brainId}/dialogs/${
          dialogId || greetingDialog?.dialog_id || dialogs[0]?.dialog_id
        }`}
        replace
      />
    );
  }

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.readOnly]: !canWrite,
      })}
    >
      <SubNavDialog />
      <SubHeaderDialog />
      <PageContentWrapper fullHeight>
        {listStatus === 'pending' ? (
          <Skeleton animation="wave" variant="rectangular" height={48} />
        ) : (
          <EmptyPage>
            <DialogImage />
            <h2>{t('dialog.empty_title')}</h2>
            <Button
              variant="tertiary"
              disabled={!canWrite}
              onClick={onCreateClick}
            >
              {t('dialog.empty_create_plus')}
            </Button>
          </EmptyPage>
        )}
      </PageContentWrapper>
    </div>
  );
}
