import { useCallback, useMemo } from 'react';

import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import {
  intentsEndpoints as endpoints,
  recommendationsEndpoints,
} from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import { MODAL_CONFIRM_CHANGES } from '@/components/organisms/Modals/ModalConductor';
import useDialogs from '@/hooks/useDialogs';
import useIntentRecommendation from '@/hooks/useIntentRecommendation';
import { Intents, Intent, PartialIntent } from '@/models/intent';
import { Recommendation } from '@/models/recommendations';
import { RootState } from '@/models/state';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { getRandomElement } from '@/redux/dialogs/helper';
import { clearIntentExpressions, setIntent } from '@/redux/expressions/actions';
import { selectIntentDraft } from '@/redux/expressions/selectors';
import { showHelper } from '@/redux/helpers/actions';
import { popModal, pushModal } from '@/redux/modals/actions';
import {
  resetDirty,
  setRecommendations,
} from '@/redux/recommendations/actions';
import { selectVisibleExpressions } from '@/redux/recommendations/selectors';
import { selectIntentName } from '@/redux/session/selectors';
import { MIN_TRAINING_PHRASES, SYSTEM_INTENTS } from '@/util/constants';
import { sameCollection, getMedian } from '@/util/util';

import { useAccount } from './useAccount';
import useDialogNodesCache from './useDialogNodesCache';
import useHomeCheckList, {
  AccountUserPrefsEnum,
  INAPPHELP_KEYS,
} from './useHomeCheckList';

interface CollectionProps {
  collection: string;
  new_collection: string;
}
interface UpdateIntentProps {
  name: string;
  intent:
    | PartialIntent
    | {
        new_intent: string;
        expressions: {
          id: string;
          text: string;
        }[];
      };
}

export const API = Object.freeze({
  listIntents: async (brainId: string): Promise<Intents> =>
    callGet(endpoints.intents(brainId)),

  getIntent: async (brainId: string, intentName: string): Promise<Intent> =>
    callGet(endpoints.intent(brainId, intentName)),

  createIntent: async ({
    brainId,
    newIntent,
  }: {
    brainId: string;
    newIntent: Partial<Intent>;
  }): Promise<Intent> => callPost(endpoints.intents(brainId), newIntent),

  updateCollection: async (
    brainId: string,
    data: CollectionProps
  ): Promise<Intents> => callPut(endpoints.collection(brainId), data),

  updateIntent: async (
    brainId: string,
    { name, intent }: UpdateIntentProps
  ): Promise<Intent> => callPut(endpoints.intent(brainId, name), intent),

  deleteIntent: async ({
    brainId,
    intentName,
  }: {
    brainId: string;
    intentName: string;
  }): Promise<Intent> => callDelete(endpoints.intent(brainId, intentName)),
});

export const onIntentCreated = (
  queryClient: QueryClient,
  resp: Intent,
  brainId: string
) => {
  queryClient.setQueryData<Intents>([endpoints.intents(brainId)], (prev) => ({
    intents: [...(prev?.intents || []), resp],
  }));

  queryClient.setQueryData<Intent>(
    [endpoints.intent(brainId, resp.intent)],
    () => resp
  );
};

export const onIntentRemoved = (
  queryClient: QueryClient,
  brainId: string,
  intentName: string
) => {
  queryClient.setQueryData<Recommendation>(
    [recommendationsEndpoints.recommendations(brainId)],
    (prev: Recommendation) => ({
      ...prev,
      intents: prev?.intents?.filter((acc) => acc.intent !== intentName),
    })
  );
  queryClient.setQueryData<Intents>(
    [endpoints.intents(brainId)],
    (prev: Intents) => ({
      intents: (prev?.intents || []).filter((acc) => acc.intent !== intentName),
    })
  );

  queryClient.invalidateQueries({
    queryKey: [endpoints.intent(brainId, intentName)],
  });
};

export const onIntentUpdated = (
  queryClient: QueryClient,
  intentName: string,
  intent: Intent
) => {
  queryClient.setQueryData<Intent>(
    [endpoints.intent(intent.brain_id, intentName)],
    (prev: Intent) => {
      if (prev) {
        return { ...prev, ...intent };
      }
      return intent;
    }
  );

  queryClient.setQueryData<Intents>(
    [endpoints.intents(intent.brain_id)],
    (prev: Intents) => {
      return {
        intents: prev?.intents.map((item) => {
          if (item.intent === intentName) {
            return { ...item, ...intent };
          }
          return item;
        }),
      };
    }
  );
  if (intent.intent !== intentName) {
    queryClient.setQueryData<Intent>(
      [endpoints.intent(intent.brain_id, intent.intent)],
      () => intent
    );
  }
};

export const onIntentCollectionUpdated = (
  queryClient: QueryClient,
  brainId: string,
  oldCollectionName: string,
  newCollectionName: string
) => {
  queryClient.setQueryData<Intents>(
    [endpoints.intents(brainId)],
    (prev: Intents) => ({
      intents: prev?.intents.map((item) => {
        if (item.collection === oldCollectionName) {
          return { ...item, collection: newCollectionName };
        }
        return item;
      }),
    })
  );
};

export const OPTIMAL_PHRASES = 10;
export const MIN_UPPER_THRESHOLD = 12;

/**
 * Finds the median number of the number of expressions.
 * Returns the optimal phrases number - its between the median and the maximum phrases allowed
 *If this number is smaller than OPTIMAL_PHRASES, then return OPTIMAL_PHRASES
 */

export const getOptimalPhrases = (intents: Intent[]) => {
  const expressionsArray = intents
    ?.filter((i) => i?.expressions?.length > 0)
    ?.map((i) => i?.expressions?.length);

  const median = getMedian(expressionsArray, MIN_TRAINING_PHRASES);

  const maximumPhrases =
    median + median / 2 < MIN_UPPER_THRESHOLD
      ? MIN_UPPER_THRESHOLD
      : Math.ceil(median + median / 2);

  const optimalPhrases = Math.ceil((maximumPhrases + median) / 2);

  return Math.max(optimalPhrases, OPTIMAL_PHRASES);
};

const useIntents = (brainId: string, intentName?: string) => {
  const navigate = useNavigate();
  const { findUsedNodes } = useDialogNodesCache();
  const { updateIntentNameOnDialogs } = useDialogs(brainId);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { slug } = useAccount();
  const { markAsComplete, accountUserChecklist } = useHomeCheckList();

  const [dismissVersionHelp, _] = useLocalStorage(
    INAPPHELP_KEYS[AccountUserPrefsEnum.CREATE_VERSION],
    accountUserChecklist[AccountUserPrefsEnum.CREATE_VERSION]
  );

  const queryClient = useQueryClient();
  const {
    isNewIntentDraft,
    recommendedIntent,
    updateRecommendation,
    updateRecommendations,
    recommendedIntents,
    isRecommmedationsDirty,
  } = useIntentRecommendation(brainId, intentName);
  const { intentName: paramsIntent } = useParams();

  const intentDraft = useSelector(selectIntentDraft, shallowEqual);

  const isDraft = paramsIntent === 'draft';
  const sessionIntent = useSelector(selectIntentName);

  const { data: intents, status: listStatus } = useQuery<Intents, Error>({
    queryKey: [endpoints.intents(brainId)],
    queryFn: () => API.listIntents(brainId),
    enabled: !!brainId,
  });

  const { data: intent, isLoading } = useQuery<Intent, Error>({
    queryKey: [endpoints.intent(brainId, intentName)],
    queryFn: () => API.getIntent(brainId, intentName),
    enabled:
      !!brainId &&
      !!intentName &&
      !isDraft &&
      !!intents?.intents?.find((e) => intentName === e.intent),
    initialData: intents?.intents?.find((e) => intentName === e.intent),
  });

  const { mutate: updateIntent, status: updateStatus } = useMutation<
    Intent,
    Error,
    UpdateIntentProps
  >({
    mutationFn: (data) => API.updateIntent(brainId, { ...data }),
    onSuccess: (resp, variables) => {
      if ('collection' in variables.intent) {
        onIntentUpdated(queryClient, variables.name, resp);
      } else {
        // When we rename an intent that has recommendation , rename recommendation too
        if (
          'new_intent' in variables.intent &&
          intentName !== variables.intent.new_intent &&
          recommendedIntent
        ) {
          const newIntent = variables.intent.new_intent;
          queryClient.setQueryData<Recommendation>(
            [recommendationsEndpoints.recommendations(resp.brain_id)],
            (prev: Recommendation) => {
              const newIntents = prev.intents.map((i) =>
                i.intent === intentName ? { ...i, intent: newIntent } : i
              );
              dispatch(
                setRecommendations({
                  brainId,
                  recommendations: { ...prev, intents: newIntents },
                })
              );

              return { ...prev, intents: newIntents };
            }
          );
        }
      }

      const intentToUpdate = variables.name;

      onIntentUpdated(queryClient, intentToUpdate, resp);

      dispatch(
        addTemporalToast(
          'success',
          t('intent.intent_saved', { 0: intentToUpdate })
        )
      );
    },
    onError: (error: Error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: createIntent, status: createStatus } = useMutation({
    mutationFn: API.createIntent,
    onSuccess: (resp) => {
      dispatch(setIntent(resp));
      onIntentCreated(queryClient, resp, brainId);
    },
    onError: (error: Error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: deleteIntent } = useMutation<
    Intent,
    Error,
    { brainId: string; intentName: string } | void
  >({
    mutationFn: (intent) => {
      // If the user specifies the intent, delete that one rather than
      // the one selected in the hook
      if (intent) {
        return API.deleteIntent(intent);
      }
      return API.deleteIntent({ brainId, intentName });
    },
    onSuccess: (resp, variables) => {
      dispatch(popModal());

      const variablesIntentName = variables && variables?.intentName;

      const intentToDelete = intentName ?? variablesIntentName ?? resp?.intent;

      const intentsInCollection = sameCollection(
        'intents',
        'intent',
        intentToDelete,
        intents
      );

      onIntentRemoved(queryClient, brainId, intentToDelete);
      dispatch(
        addTemporalToast(
          'success',
          t('intent.intent_deleted', { 0: intentName ?? variablesIntentName })
        )
      );
      dispatch(clearIntentExpressions());

      const url = `/${slug}/brains/${brainId}/intents`;
      if (intentsInCollection.length === 0) {
        navigate(url);
      } else {
        navigate(`${url}/${intentsInCollection[0].intent}`);
      }
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const createNewIntent = useCallback(
    async (newIntent?: Partial<Intent>) => {
      return new Promise<Intent>((resolve, reject): void => {
        createIntent(
          { brainId, newIntent },
          { onSuccess: (data) => resolve(data), onError: reject }
        );
      });
    },
    [brainId, createIntent]
  );

  const { mutate: updateCollection } = useMutation<
    Intents,
    Error,
    CollectionProps
  >({
    mutationFn: (data) => API.updateCollection(brainId, data),
    onSuccess: (resp) => {
      const newCollectionName = resp.intents[0].collection;
      queryClient.setQueryData<Intents>(
        [endpoints.intents(brainId)],
        (prev: Intents) => ({
          intents: prev?.intents.map((item) => {
            const isUpdated = resp.intents.find(
              (e) => e.intent === item.intent
            );
            return isUpdated
              ? { ...item, collection: newCollectionName }
              : item;
          }),
        })
      );
      dispatch(
        addTemporalToast(
          'success',
          t('dialog.collection_updated', { 0: newCollectionName })
        )
      );
    },
  });

  const saveIntent = useCallback(
    (shouldRedirect = true) => {
      // Save a draft intent
      if (isDraft) {
        const previousCollection = intents?.intents?.find(
          (e) => e.intent === sessionIntent
        )?.collection;

        const payload = {
          brainId,
          newIntent: {
            intent: intentDraft.new_intent,
            expressions: intentDraft.expressions,
            collection: previousCollection ?? '',
          },
        };

        return new Promise<Intent>((resolve, reject): void => {
          createIntent(payload, {
            onSuccess: (resp) => {
              markAsComplete(AccountUserPrefsEnum.CREATE_INTENT);
              if (
                !accountUserChecklist[AccountUserPrefsEnum.CREATE_VERSION] &&
                !dismissVersionHelp
              ) {
                dispatch(
                  showHelper(
                    INAPPHELP_KEYS[AccountUserPrefsEnum.CREATE_VERSION]
                  )
                );
              }

              dispatch(
                addTemporalToast(
                  'success',
                  t('intent.intent_saved', { 0: resp?.intent })
                )
              );
              if (shouldRedirect) {
                navigate(`/${slug}/brains/${brainId}/intents/${resp?.intent}`);
              }

              resolve(resp);
            },
            onError: reject,
          });
        });
      }
      return new Promise<void>((resolve, reject): void => {
        //Save an intent that was created from a new recommendation
        if (isNewIntentDraft) {
          updateRecommendation();
          const payload = {
            brainId,
            newIntent: {
              intent: intentDraft?.new_intent,
              expressions: intentDraft?.expressions,
            },
          };
          createIntent(payload, {
            onSuccess: (resp) => {
              dispatch(resetDirty({ brainId }));
              if (shouldRedirect) {
                navigate(`/${slug}/brains/${brainId}/intents/${resp.intent}`);
              }

              resolve();
            },
            onError: (error) => reject(error),
          });
        } else {
          //Save an existing intent that had recommendations
          if (isRecommmedationsDirty) {
            updateRecommendations({ intents: recommendedIntents });
          }
          const isSystemIntent = SYSTEM_INTENTS.includes(
            intentDraft.new_intent
          );
          const updatePayload =
            paramsIntent === intentDraft.new_intent
              ? {
                  name: intentName,
                  intent: {
                    expressions: intentDraft.expressions,
                  },
                }
              : {
                  name: paramsIntent ?? intentDraft.new_intent,
                  intent: {
                    new_intent: !isSystemIntent
                      ? intentDraft.new_intent
                      : undefined,
                    expressions: intentDraft.expressions,
                  },
                };

          updateIntent(updatePayload, {
            onSuccess: (resp) => {
              if (resp.intent !== intentName) {
                updateIntentNameOnDialogs(
                  findUsedNodes({ brainId, name: intentName }),
                  { intent: resp.intent }
                );
                queryClient.removeQueries({
                  queryKey: [endpoints.intent(brainId, intentName)],
                });
                if (shouldRedirect) {
                  setTimeout(() => {
                    // This is a workaround to prevent "Unsaved changes" modal from showing up
                    navigate(
                      `/${slug}/brains/${brainId}/intents/${resp.intent}`
                    );
                  }, 100);
                }
              }
              resolve();
            },
            onError: reject,
          });
        }
      });
    },
    [
      isDraft,
      intents?.intents,
      brainId,
      intentDraft.new_intent,
      intentDraft.expressions,
      sessionIntent,
      createIntent,
      markAsComplete,
      accountUserChecklist,
      dismissVersionHelp,
      dispatch,
      t,
      slug,
      isNewIntentDraft,
      updateRecommendation,
      isRecommmedationsDirty,
      paramsIntent,
      intentName,
      updateIntent,
      updateRecommendations,
      recommendedIntents,
      updateIntentNameOnDialogs,
      findUsedNodes,
      queryClient,
      navigate,
    ]
  );

  const handleSaveIntent = useCallback(
    (shouldRedirect?: boolean) => {
      const expressions = intentDraft?.expressions;
      if (expressions.length === 0) {
        setTimeout(() => {
          dispatch(
            pushModal(MODAL_CONFIRM_CHANGES, {
              onSave: () => saveIntent(shouldRedirect),
              onDiscard: () => {
                dispatch(popModal());
              },
              title: t('prompts.unsaved_changes.title'),
              subtitle: t('intent.empty'),
              secondaryText: t('common.cancel'),
              primaryText: t('common.save_anyway'),
            })
          );
        }, 0);
      } else {
        saveIntent(shouldRedirect);
      }
    },
    [dispatch, intentDraft?.expressions, saveIntent, t]
  );

  const createDraftIntent = useCallback(() => {
    navigate(`/${slug}/brains/${brainId}/intents/draft`);
  }, [brainId, slug, navigate]);

  type CollectionUpdate = {
    id: string;
    collection: string;
  };

  const handleCollectionUpdate = useCallback(
    ({ id, collection }: CollectionUpdate) => {
      updateIntent({ name: id, intent: { collection } });
    },
    [updateIntent]
  );

  const optimalPhrases = useMemo(
    () => getOptimalPhrases(intents?.intents),
    [intents?.intents]
  );

  const filteredSortedExpressions = useSelector(
    (state: RootState) =>
      selectVisibleExpressions(state, {
        brainId,
        intentName,
        optimalPhrases,
      }),
    shallowEqual
  );

  const intentNames = useMemo(
    () => intents?.intents?.map((i) => i.intent.toLowerCase()),
    [intents]
  );

  // Intents without offtopics
  const cleanIntents = useMemo(
    () => intents?.intents?.filter((i) => i.intent !== 'offtopics'),
    [intents]
  );

  const randomIntent = useMemo(
    () => getRandomElement(cleanIntents),
    [cleanIntents]
  );

  return {
    createNewIntent,
    createDraftIntent,
    deleteIntent,
    isLoading,
    createStatus,
    intent,
    intents: intents?.intents,
    cleanIntents,
    listStatus,
    filteredExpressions: filteredSortedExpressions,
    handleCollectionUpdate,
    optimalPhrases: getOptimalPhrases(intents?.intents),
    saveIntent,
    handleSaveIntent,
    updateCollection,
    updateIntent,
    updateStatus,
    intentNames,
    randomIntent,
  };
};

export default useIntents;
