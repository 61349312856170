import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import IntentIcon from '@/components/atoms/Icons/Intent';
import Intents from '@/components/atoms/Icons/Intents';
import { useAccount } from '@/hooks/useAccount';
import { Standalone as StandaloneIcon } from '@/modules/TryIt/Icons/Standalone';
import { popModal } from '@/redux/modals/actions';

import styles from '../../LogSections/DetailsSection/DetailsSection.module.scss';

interface Props {
  brainId: string;
  isPlannerIntentOfftopic: boolean;
  intentName?: string;
}

export const PossibleMatchedIntents = ({
  brainId,
  isPlannerIntentOfftopic,
  intentName,
}: Props) => {
  const { slug } = useAccount();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(popModal());
  };

  return (
    <>
      {isPlannerIntentOfftopic && (
        <div className={styles.title}>
          <IntentIcon color="var(--icon-default-gray)" />
          <Typography
            variant="body-regular"
            color="var(--text-default-gray)"
            component="div"
          >
            {t('try_it.no_intent_ai_agent')}
          </Typography>
        </div>
      )}
      {intentName && (
        <div className={styles.title}>
          <Intents />
          <Typography
            variant="body-regular"
            color="var(--text-default-gray)"
            component="div"
            className={styles.plannerIntentContainer}
          >
            {t('common.intent')}{' '}
            <Typography variant="body-regular">
              <Box className={styles.plannerIntent}>
                <StandaloneIcon />
                <NavLink
                  to={`/${slug}/brains/${brainId}/intents/${intentName}`}
                  className={styles.link}
                  onClick={closeModal}
                >
                  {intentName}
                </NavLink>
              </Box>
            </Typography>{' '}
            {t('try_it.selected_ai_agent')}
          </Typography>
        </div>
      )}
    </>
  );
};
