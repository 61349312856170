import type { RequireAtLeastOne } from 'type-fest';

import { Statuses } from './api';
import { Dialog } from './dialog';
import { Entity } from './entity';
import { Intent } from './intent';
import { Webhook } from './webhook';

export type Brains = {
  brains: Brain[];
};

export type BrainMetadata = {
  onboarding_status?: string;
  onboarding_type?: string;
};

export type BrainGuidelines = Partial<{
  name: string;
  response_size: 'short' | 'long';
  humor_level: number;
  formality_level: number;
  respectfulness_level: number;
  creativity_level: number;

  /**
   * True if the customer has a handover mechanism
   */
  support_handover: boolean;

  /**
   * Up to 3 reminders with the number of seconds of inactivity
   * before the reminder is sent
   */
  reminders: number[];

  // Guidelines
  goal: string;
  overview: string;
  features: string;
  loyalty: string;
  objections: { message: string; response: string }[];
  custom_instructions: string;
}>;

export enum BrainLanguage {
  ENGLISH = 'en',
  GREEK = 'el',
  SPANISH = 'es',
  ROMANIAN = 'ro',
  PORTUGUESE_BRAZILIAN = 'pt-br',
  ITALIAN = 'it',
  FRENCH = 'fr',
  DANISH = 'da',
  GERMAN = 'de',
  BULGARIAN = 'bg',
  CZECH = 'cs',
  POLISH = 'pl',
  INDONESIAN = 'id',
  DUTCH = 'nl',
  SERBIAN = 'sr',
  SWEDISH = 'sv',
  RUSSIAN = 'ru',
  ALBANIAN = 'sq',
  ARABIC = 'ar',
  JAPANESE = 'ja',
  TURKISH = 'tr',
  CHINESE = 'zh',
}

export enum BrainType {
  SUPPORT = 'support',
  SALES_PRODUCT = 'sales_product',
  SALES_SERVICE = 'sales_service',
  SALES_FINTECH = 'sales_fintech',
  EARLY_ENGAGEMENT = 'engagement',
  ACTIVATION = 'activation',
  UPSELL = 'upsell',
  DEBT_COLLECTION = 'debt_collection',
}

export type BrainTypeValues = `${BrainType}`;

export type Brain = {
  readonly account_id: string;
  readonly brain_id: string;
  readonly brain_parent_id: string;
  readonly created_by: string;

  collection_id?: string;
  confidence_threshold: number;
  created: string;
  description: string;
  dialog_count: number;
  dialogs?: Dialog[];
  brain_type: BrainType;
  disambiguation_options: number;
  disambiguation_prompt: string;
  disambiguation: boolean;
  entity_count: number;
  entities?: Entity[];
  greeklish: boolean;
  inactivity_timeout: number;
  intent_count: number;
  intents?: Intent[];
  language: BrainLanguage;
  language_model_id?: string;
  last_trained: string;
  last_used_at?: string;
  metadata: BrainMetadata;
  guidelines: BrainGuidelines;
  name: string;
  status: Statuses;
  updated: string;
  version_count: number;
  version: number;
  versions: number;
  webhooks?: Webhook[];
};

export type AIAgent = Brain;

export type BrainVersion = Omit<
  Brain,
  'metadata' | 'name' | 'versions' | 'account_id'
>;

export type BrainFromLogs = {
  name: string;
  language: string;
  type: string;
  integration_id?: string;
  logs_file?: string;
};

export type PartialBrain = RequireAtLeastOne<Partial<Brain>, 'brain_id'>;
