import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Bar from '@/components/organisms/Analytics/Bar/Bar';
import Pie from '@/components/organisms/Analytics/Pie/Pie';
import { RootState } from '@/models/state';
import AnalyticsFieldset from '@/modules/analytics/components/fieldsets/AnalyticsFieldset/AnalyticsFieldset';
import { AccountAnalytics, AnalyticsType } from '@/modules/analytics/models';
import { selectFiltersByType } from '@/modules/TryIt/redux/selectors';
import { selectAccountSlug } from '@/redux/session/selectors';

import useContainment from '../../../hooks/useContainment';
import { applyFilters } from '../../../redux/actions';

import styles from '../CoverageFieldset/CoverageFieldset.module.scss';

const Containment = () => {
  const { t } = useTranslation();
  const { isLoading, error, pie, bar, PieTooltip, BarTooltips } =
    useContainment();
  const title = t('analytics.containment');

  const dispatch = useDispatch();
  const filters = useSelector((state: RootState) =>
    selectFiltersByType(state, AnalyticsType.ACCOUNT)
  );

  const slug = useSelector(selectAccountSlug);

  const filteredBrains = useMemo(
    () =>
      (filters as AccountAnalytics[]).find((filt) => filt.type === 'brainIds')
        ?.brainIds,
    [filters]
  );

  if (isLoading || error) {
    return (
      <AnalyticsFieldset
        title={title}
        isLoading={isLoading}
        error={error}
        fullWidth
      >
        <p>{`${error}`}</p>
      </AnalyticsFieldset>
    );
  }

  const isEmpty = pie && pie[0]?.count === 0 && pie[1]?.count === 0;

  return (
    <AnalyticsFieldset
      title={title}
      fullWidth
      tooltip={t('analytics.contained_tooltip')}
      hasFooter={false}
      isEmpty={isEmpty}
      data={bar}
    >
      <div className={styles.container}>
        <div className={styles.pie}>
          <Pie data={pie} Tooltip={PieTooltip} />
        </div>
        <div className={styles.bar}>
          <Bar
            data={bar}
            keys={['primary', 'secondary']}
            indexBy="date"
            axisX={t('common.date')}
            axisY={t('common.conversations')}
            groupMode="stacked"
            BarTooltips={BarTooltips}
          />
        </div>
      </div>
      {filteredBrains?.length > 0 && (
        <div className={styles.showLogs}>
          <p className={styles.title}>{t('analytics.view_uncontained')}</p>
          <div className={styles.list}>
            {filteredBrains?.map((brain, index) => (
              <NavLink
                onClick={() => {
                  dispatch(
                    applyFilters({
                      filters: [
                        {
                          isContained: false,
                          type: 'isContained',
                        },
                      ],
                      type: AnalyticsType.LOGS,
                    })
                  );
                }}
                key={brain.value}
                to={`/${slug}/brains/${brain.value}/logs`}
              >
                {brain.label}
                {index === filteredBrains.length - 1 ? '' : ','}
              </NavLink>
            )) || null}
          </div>
        </div>
      )}
    </AnalyticsFieldset>
  );
};

export default Containment;
