import { useSelector } from 'react-redux';

import { RootState } from '@/models/state';
import ModalAddCard from '@/modules/billing/components/ModalAddCard/ModalAddCard';
import ModalUpgradePlan from '@/modules/billing/components/ModalUpgradePlan/ModalUpgradePlan';
import ModalBundleCreation from '@/modules/bundles/components/ModalBundleCreation';
import ModalBundleDelete from '@/modules/bundles/components/ModalBundleDelete';
import ModalMergeConflicts from '@/modules/bundles/components/ModalMergeConflicts/ModalMergeConflicts';
import { ModalDepartmentCreate } from '@/modules/departments/components/ModalDepartmentCreate/ModalDepartmentCreate';
import { ModalAPIKeyCreated } from '@/modules/developerTools/components/ModalAPIKeyCreated/ModalAPIKeyCreated';
import { ModalTryIt } from '@/modules/TryIt/components/ModalTryIt/ModalTryIt';

import Modal from './Modal';
import ModalAccountTransfer from './ModalAccountTransfer';
import { ModalAIAgentCreate } from './ModalAIAgentCreate/ModalAIAgentCreate';
import ModalAutoBrain from './ModalAutoBrain/ModalAutoBrain';
import ModalBrainCreationNew from './ModalBrainCreation/ModalBrainCreationNew';
import ModalBrainDelete from './ModalBrainDelete';
import ModalCannedResponses from './ModalCannedResponses/ModalCannedResponses';
import ModalConfirmation from './ModalConfirmation/ModalConfirmation';
import ModalConfirmChanges from './ModalConfirmChanges/ModalConfirmChanges';
import ModalConnectIntent from './ModalConnectIntent/ModalConnectIntent';
import ModalDatasourceAdd from './ModalDatasourceAdd/ModalDatasourceAdd';
import ModalDelete from './ModalDelete';
import ModalDeskCreation from './ModalDeskCreation';
import ModalEdit from './ModalEdit';
import ModalEditMultipleValues from './ModalEditMultipleValues';
import ModalForm from './ModalForm';
import ModalImageCrop from './ModalImageCrop';
import ModalImport from './ModalImport';
import ModalIngestionReport from './ModalIngestionReport';
import { ModalLanguageModelCreate } from './ModalLanguageModelCreate/ModalLanguageModelCreate';
import { ModalLanguageModelDelete } from './ModalLanguageModelDelete';
import { ModalMediaPreview } from './ModalMediaPreview/ModalMediaPreview';
import ModalMemberInvite from './ModalMemberInvite/ModalMemberInvite';
import ModalMessage from './ModalMessage';
import ModalPrompt from './ModalPrompt';
import ModalPublishVersion from './ModalPublishVersion/ModalPublishVersion';
import ModalRoleEdit from './ModalRoleEdit/ModalRoleEdit';
import ModalRuleCreation from './ModalRuleCreation';
import ModalSmallScreen from './ModalSmallScreen/ModalSmallScreen';
import ModalTeam from './ModalTeam/ModalTeam';
import ModalTeamMembers from './ModalTeamMembers/ModalTeamMembers';
import ModalVersionDelete from './ModalVersionDelete';
import ModalVideo from './ModalVideo';
import ModalWarn from './ModalWarn';
import ModalWhatsAppTest from './ModalWhatsAppTest/ModalWhatsAppTest';

export const MODAL_BASIC = 'MODAL_BASIC';
export const DESK_CREATE = 'DESK_CREATE';
export const BRAIN_ADD = 'BRAIN_ADD';
export const BRAIN_DELETE = 'BRAIN_DELETE';

export const RULE_CREATE = 'RULE_CREATE';
export const BUNDLE_CREATE = 'BUNDLE_CREATE';
export const BUNDLE_DELETE = 'BUNDLE_DELETE';
export const INTEGRATION_CREATE = 'INTEGRATION_CREATE';

export const VERSION_DELETE = 'VERSION_DELETE';

export const SETTINGS_SELECT_PLAN = 'SETTINGS_SELECT_PLAN';
export const MODAL_DELETE = 'MODAL_DELETE';
export const MODAL_IMAGE_CROP = 'MODAL_IMAGE_CROP';
export const MODAL_MEMBER_INVITE = 'MODAL_MEMBER_INVITE';
export const MODAL_TRYIT = 'MODAL_TRYIT';
export const MODAL_EDIT = 'MODAL_EDIT';
export const MODAL_EDIT_MULTIPLE = 'MODAL_EDIT_MULTIPLE';
export const MODAL_FORM = 'MODAL_FORM';
export const WEBHOOK_CREATE = 'WEBHOOK_CREATE';
export const ADD_CARD = 'ADD_CARD';
export const MODAL_CONFIRMATION = 'MODAL_CONFIRMATION';
export const MODAL_PROMPT = 'MODAL_PROMPT';
export const MODAL_CONFIRM_CHANGES = 'MODAL_CONFIRM_CHANGES';
export const MODAL_SHOWCASE = 'MODAL_SHOWCASE';
export const MODAL_CANNED_RESPONSES = 'MODAL_CANNED_RESPONSES';
export const MODAL_ROLE_EDIT = 'MODAL_ROLE_EDIT';
export const MODAL_IMPORT = 'MODAL_IMPORT';
export const BRAIN_IMPORT = 'BRAIN_IMPORT';
export const MODAL_TEAM = 'MODAL_TEAM';
export const MODAL_TEAM_MEMBERS = 'MODAL_TEAM_MEMBERS';
export const MODAL_MERGE_CONFLICTS = 'MODAL_MERGE_CONFLICTS';
export const MODAL_MESSAGE = 'MODAL_MESSAGE';
export const MODAL_CONNECT_INTENT = 'MODAL_CONNECT_INTENT';
export const MODAL_AUTO_BRAIN = 'MODAL_AUTO_BRAIN';
export const MODAL_WARN = 'MODAL_WARN';
export const MODAL_VIDEO = 'MODAL_VIDEO';
export const MODAL_WHATSAPP_TEST = 'MODAL_WHATSAPP_TEST';
export const MODAL_DATASOURCE_ADD = 'MODAL_DATASOURCE_ADD';
export const MODAL_DEPARTMENT_CREATE = 'MODAL_DEPARTMENT_CREATE';
export const MODAL_ACCOUNT_TRANSFER = 'MODAL_ACCOUNT_TRANSFER';
export const MODAL_INGESTION_REPORT = 'MODAL_INGESTION_REPORT';
export const MODAL_UPGRADE_PLAN = 'MODAL_UPGRADE_PLAN';
export const MODAL_PUBLISH_VERSION = 'MODAL_PUBLISH_VERSION';
export const MODAL_API_KEY_CREATED = 'MODAL_API_KEY_CREATED';
export const MODAL_SMALL_SCREEN = 'MODAL_SMALL_SCREEN';
export const MODAL_MEDIA_PREVIEW = 'MODAL_MEDIA_PREVIEW';
export const MODAL_LANGUAGE_MODEL_CREATE = 'MODAL_LANGUAGE_MODEL_CREATE';
export const MODAL_AI_AGENT_CREATE = 'MODAL_AI_AGENT_CREATE';
export const MODAL_LANGUAGE_MODEL_DELETE = 'MODAL_LANGUAGE_MODEL_DELETE';

export const MODAL_KINDS = {
  [MODAL_SMALL_SCREEN]: ModalSmallScreen,
  [ADD_CARD]: ModalAddCard,
  [BRAIN_ADD]: ModalBrainCreationNew,
  [BRAIN_DELETE]: ModalBrainDelete,
  [DESK_CREATE]: ModalDeskCreation,
  [RULE_CREATE]: ModalRuleCreation,
  [BUNDLE_CREATE]: ModalBundleCreation,
  [BUNDLE_DELETE]: ModalBundleDelete,
  [VERSION_DELETE]: ModalVersionDelete,
  [MODAL_BASIC]: Modal,
  [MODAL_UPGRADE_PLAN]: ModalUpgradePlan,
  [MODAL_CONFIRMATION]: ModalConfirmation,
  [MODAL_FORM]: ModalForm,
  [MODAL_DELETE]: ModalDelete,
  [MODAL_EDIT]: ModalEdit,
  [MODAL_EDIT_MULTIPLE]: ModalEditMultipleValues,
  [MODAL_IMAGE_CROP]: ModalImageCrop,
  [MODAL_INGESTION_REPORT]: ModalIngestionReport,
  [MODAL_MEMBER_INVITE]: ModalMemberInvite,
  [MODAL_TRYIT]: ModalTryIt,
  [MODAL_PROMPT]: ModalPrompt,
  [MODAL_CONFIRM_CHANGES]: ModalConfirmChanges,
  [MODAL_CANNED_RESPONSES]: ModalCannedResponses,
  [MODAL_ROLE_EDIT]: ModalRoleEdit,
  [MODAL_IMPORT]: ModalImport,
  [MODAL_TEAM]: ModalTeam,
  [MODAL_TEAM_MEMBERS]: ModalTeamMembers,
  [MODAL_MERGE_CONFLICTS]: ModalMergeConflicts,
  [MODAL_MESSAGE]: ModalMessage,
  [MODAL_CONNECT_INTENT]: ModalConnectIntent,
  [MODAL_AUTO_BRAIN]: ModalAutoBrain,
  [MODAL_WARN]: ModalWarn,
  [MODAL_VIDEO]: ModalVideo,
  [MODAL_WHATSAPP_TEST]: ModalWhatsAppTest,
  [MODAL_DATASOURCE_ADD]: ModalDatasourceAdd,
  [MODAL_DEPARTMENT_CREATE]: ModalDepartmentCreate,
  [MODAL_ACCOUNT_TRANSFER]: ModalAccountTransfer,
  [MODAL_PUBLISH_VERSION]: ModalPublishVersion,
  [MODAL_API_KEY_CREATED]: ModalAPIKeyCreated,
  [MODAL_MEDIA_PREVIEW]: ModalMediaPreview,
  [MODAL_LANGUAGE_MODEL_CREATE]: ModalLanguageModelCreate,
  [MODAL_AI_AGENT_CREATE]: ModalAIAgentCreate,
  [MODAL_LANGUAGE_MODEL_DELETE]: ModalLanguageModelDelete,
};

const ModalConductor = () => {
  const modals = useSelector((state: RootState) => state.modals);
  if (!modals || modals.length === 0) {
    return null;
  }

  const { kind, props } = modals[modals.length - 1];

  const Component = MODAL_KINDS[kind];
  if (!Component) {
    return null;
  }
  return <Component {...props} />;
};

export default ModalConductor;
