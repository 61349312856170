import { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { useAccount } from '@/hooks/useAccount';
import { popModal } from '@/redux/modals/actions';
import { selectBrainId } from '@/redux/session/selectors';

export const useMessageBubble = () => {
  const navigate = useNavigate();
  const { slug } = useAccount();
  const brainId = useSelector(selectBrainId);
  const dispatch = useDispatch();

  const onGoToEntity = useCallback(
    (entity: string) => {
      dispatch(popModal());
      navigate(`/${slug}/brains/${brainId}/entities/${entity}`);
    },
    [dispatch, navigate, slug, brainId]
  );

  return {
    onGoToEntity,
  };
};
