import { useEffect, useMemo } from 'react';

import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Routes, Route, Outlet, useParams } from 'react-router';
import { useTitle } from 'react-use';

import PermissionsWrapper from '@/components/templates/PermissionsWrapper';
import { useRtmBrain } from '@/hooks/rtm/useRtmBrain';
import { resetFilters } from '@/modules/analytics/redux/actions';
import { resetTryIt } from '@/modules/TryIt/redux/actions';
import { resetBrain, setBrainId } from '@/redux/session/actions';
import { selectAccountSlug } from '@/redux/session/selectors';

import { NavigationSidebar } from './components/NavigationSidebar/NavigationSidebar';
import { Overview } from './components/Overview/Overview';

import styles from './AIAgents.module.scss';

const AIAgentRouter: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { brainId } = useParams();

  const slug = useSelector(selectAccountSlug);
  const title = useMemo(() => t('pages.brain', { 0: slug }), [t, slug]);
  useTitle(title);
  useRtmBrain(brainId);

  useEffect(() => {
    if (brainId) {
      dispatch(setBrainId({ brainId }));
      dispatch(resetTryIt());
    }

    return () => {
      dispatch(resetBrain());
      dispatch(resetFilters());
    };
  }, [dispatch, brainId]);

  return (
    <>
      <PermissionsWrapper module="brains" resource_id={brainId}>
        <Box sx={{ display: 'flex', flexGrow: 1, height: '100%' }}>
          <NavigationSidebar />

          <main className={styles.content}>
            <Routes>
              <Route path="/" element={<Navigate to="overview" replace />} />
              {/* <Route path="/intents/:intentName" element={<Intent />} />
          <Route path="/intents" element={<Intents />} />
          <Route path="/dialogs/:dialogId" element={<Dialog />} />
           */}

              <Route path="/overview" element={<Overview />} />
              {/*
          <Route path="/collections" element={<BrainCollection />} />
          <Route path="/webhooks" element={<Webhooks />} />
          <Route path="/entities/:entityName" element={<Entity />} />
          <Route path="/entities" element={<Entities />} />
          <Route path="/logs" element={<Logs />} />
          <Route path="/settings" element={<BrainSettings />} />
          <Route path="/versions" element={<Versions />} />
              <Route path="*" element={<NotFound />} />
          */}
            </Routes>
            <Outlet />
          </main>
        </Box>
      </PermissionsWrapper>
    </>
  );
};
AIAgentRouter.displayName = 'AIAgentRouter';

export default AIAgentRouter;
