import { Attachment, Context } from '../../../ts/models/chat';
import { OptionBase } from '../../../ts/models/common';
import {
  Correction,
  DebugStep,
  Entity,
  Fragment,
  ResponseMessage,
  TryItCollection,
} from '../../../ts/models/tryIt';

export type Channel =
  | 'facebook'
  | 'viber'
  | 'web'
  | 'zendesk'
  | 'intercom'
  | 'sunco'
  | 'front';

export type UserInfo = {
  channel: Channel;
  user_id: string;
  display_name?: string;
  email?: string;
  city?: string;
  locale?: string;
  region?: string;
  latitude?: string;
  longitude?: string;
};

export type Log = {
  brain_id: string;
  brain_parent_id: string;
  brain_version: number;
  channel: Channel;
  channel_user_id: string | null;
  user_id: string | null;
  user_name: string | null;
  user_email?: string;
  desk_id: string;
  start_time: string;
  end_time: string;
  external_user_id: string | null;
  integration_id: string | null;
  is_contained: boolean;
  is_covered: boolean;
  is_test: boolean;
  participated_agents: { agent_id: string; agent_name: string }[];
  participated_brains: {
    brain_id: string;
    brain_version: number;
    brain_parent_id: string;
  }[];
  rule_ids: string[];
  rating: 1 | 2 | 3 | 4 | 5;
  feedback: string;
  session_id: string;
  tags: string[] | null;
  total_user_messages: number;
};

export type LogMessage = {
  time: string;
  tags: string[] | null;
  context: Context | null;
  agent_id: string | null;
  request_id: string;
  first_message: boolean;
  response_time: number | null;
  brain_id: string | null;
  brain_parent_id: string | null;
  rule_ids: string[] | null;
  brain_version: number | null;
  collection_id: string | null;
  collection_request_code: number | null;
  collection_response_code: number | null;
  collection_sources: Fragment[] | null;
  new_brain_session: boolean | null;
  corrections?: Correction[];
} & (
  | {
      event: 'message:brain_send';
      message: {
        debug: {
          steps: DebugStep[];
          logs: string[];
          nodes_stack: {
            node_id: string;
            name: string;
            type: 'intent' | 'event';
            request_id: string;
          }[];
          standalone_question?: string;
          corrections?: Correction[];
          collection?: TryItCollection;
        };
        intents: { intent: string; confidence: number }[];
        entities: Entity[];
        responses: ResponseMessage[];
        collection?: TryItCollection;
        planner_intents?: string[];
        processed_text?: string;
      };
    }
  | {
      event: 'message:broadcast_send';
      message: {
        debug: {
          broadcast: {
            broadcast_id: string;
            external_message_id: string;
          };
        };
        responses: ResponseMessage[];
      };
    }
  | {
      event:
        | 'message:received'
        | 'message:send'
        | 'session:rating'
        | 'conversation:closed'
        | 'session:closed'
        | 'session:expired';
      message: {
        text: string;
        type: string;
        attachments?: Attachment[];
      } | null;
    }
);

export type LogsFilterType =
  | 'isCovered'
  | 'isTest'
  | 'isContained'
  | 'isMeaningful'
  | 'minNumUserMessages'
  | 'channels'
  | 'sessionId'
  | 'tags'
  | 'ratings'
  | 'maxConfidence'
  | 'userId'
  | 'channelUserId'
  | 'externalUserId';

export type AccountAnalyticsFilterType =
  | 'isTest'
  | 'channels'
  | 'brainIds'
  | 'deskIds'
  | 'agentIds'
  | 'minNumUserMessages';

export type AccountAnalytics = {
  type: AccountAnalyticsFilterType;
  isTest?: boolean;
  channels?: string[];
  deskIds?: OptionBase[];
  brainIds?: OptionBase[];
  agentIds?: OptionBase[];
  minNumUserMessages?: number;
};

export const enum AnalyticsType {
  LOGS = 'logs',
  ACCOUNT = 'accountAnalytics',
}

export type LogsAnalytics = {
  type: LogsFilterType;
  isCovered?: boolean;
  isTest?: boolean;
  isContained?: boolean;
  isMeaningful?: boolean;
  minNumUserMessages?: string;
  channels?: string[];
  sessionId?: string;
  tags?: string[];
  ratings?: string[];
  maxConfidence?: string;
  userId?: string;
  channelUserId?: string;
  externalUserId?: string;
};

export type Analytics = {
  endDate?: string | null;
  startDate?: string | null;
  lastSeen?: string[];
  accountAnalytics?: AccountAnalytics[];
  logs?: LogsAnalytics[];
  filtersLoaded?: boolean;
};

export type QueryVariables = {
  endDate?: string | null;
  startDate?: string | null;
  channels?: string | null;
};

export type AnalyticsQueryVariables = {
  limit?: number;
  brainIds: string | null;
  isTest?: boolean | null;
} & QueryVariables;

export type LogsQueryVariables = {
  limit?: number;
  deskIds?: string | null;
  brainIds?: string | null;
  integrationIds?: string | null;
  brainVersions?: string | null;
  dateRange?: string;
  minNumUserMessages?: number | null;
  maxConfidence?: number | null;
  ratings?: string | null;
  tags?: string | null;
  sessionId?: string | null;
  userId?: string | null;
  userEmail?: string | null;
  channelUserId?: string | null;
  externalUserId?: string | null;
  isContained?: boolean | null;
  isCovered?: boolean | null;
  isMeaningful?: boolean | null;
  isTest?: boolean | null;
  agentIds?: string | null;
} & QueryVariables;
