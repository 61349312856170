import { forwardRef, useCallback } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ActionType } from '@/modules/rules/model';
import { addAction, revertIsActionOpen } from '@/modules/rules/redux/actions';
import { selectIsAssignBrainSelected } from '@/modules/rules/redux/selectors';

import styles from './NewActionOptions.module.scss';

type Props = {
  options: { type: string }[];
  index: number;
  handleOptionClick: () => void;
};

const NewActionOptions = forwardRef<HTMLDivElement, Props>(
  ({ options, handleOptionClick, index }, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isAssignBrainSelected = useSelector(selectIsAssignBrainSelected);

    const handleClick = useCallback(
      (x) => {
        const data = {
          type: x,
          isOpen: x === ActionType.CLOSE_CONVERSATION ? false : true,
        };
        dispatch(addAction(data));

        if (x === ActionType.CLOSE_CONVERSATION) {
          dispatch(revertIsActionOpen({ index, isOpenState: false }));
        }

        handleOptionClick();
      },
      [dispatch, handleOptionClick, index]
    );

    return (
      <ClickAwayListener onClickAway={() => handleOptionClick()}>
        <div className={styles.modal} ref={ref}>
          {options?.map((x) => (
            <span
              aria-disabled={
                x.type === ActionType.ASSIGN_COLLECTION &&
                !isAssignBrainSelected
              }
              key={x.type}
              className={styles.option}
              onClick={() => handleClick(x.type)}
              onKeyDown={() => handleClick(x.type)}
              role="button"
              tabIndex={0}
            >
              {t(`rules.${x.type}`)}
            </span>
          ))}
        </div>
      </ClickAwayListener>
    );
  }
);

NewActionOptions.displayName = 'NewActionOptions';

export default NewActionOptions;
