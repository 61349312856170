import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { brainsEndpoints as endpoints } from '@/api/endpoints';
import { callDelete, callGet, callPost, callPut } from '@/api/fetcher';
import { Brain, BrainFromLogs, Brains, PartialBrain } from '@/models/brain';
import { actions } from '@/models/permissions';
import { RootState } from '@/models/state';
import {
  addErrorTemporalToast,
  addTemporalToast,
} from '@/modules/notifications/redux/actions';
import { getPermissions } from '@/redux/permissions/selectors';
import { selectAccountId } from '@/redux/session/selectors';
import { CUSTOM_INSTRUCTIONS_LENGTH } from '@/util/constants';
import { exportBrain as downloadBrain } from '@/util/file-manager';

import { useAccount } from './useAccount';

const ONE_SECOND = 1000;
const TEN_SECONDS = 10 * ONE_SECOND;
const THIRTY_SECONDS = 30 * ONE_SECOND;

export const API = Object.freeze({
  listBrains: async (): Promise<Brains> => callGet(endpoints.brains),

  getBrain: async (brainId: string): Promise<Brain> =>
    callGet(endpoints.brain(brainId)),

  exportBrain: async (brainId: string): Promise<Brain> =>
    callGet(`${endpoints.brain(brainId)}?export=true`),

  createBrain: async (newBrain: Partial<Brain>): Promise<Brain> =>
    callPost(endpoints.brains, newBrain),

  updateBrain: async ({ brain_id, ...brain }: PartialBrain): Promise<Brain> =>
    callPut(endpoints.brain(brain_id), brain),

  deleteBrain: async (brainId: string): Promise<Brain> =>
    callDelete(endpoints.brain(brainId)),

  createBrainFromLogs: async (newBrain: BrainFromLogs): Promise<Brain> =>
    callPost(endpoints.onboarding, newBrain),

  getTemplate: async (lang: string): Promise<Brain> =>
    callGet(endpoints.templates(lang)),
});

export const onBrainUpdated = (queryClient: QueryClient, brain: Brain) => {
  queryClient.setQueryData<Brain>(
    [endpoints.brain(brain.brain_id)],
    (prev: Brain) => ({ ...prev, ...brain })
  );
  const queryKey = [endpoints.brains, brain.account_id];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Brains>(queryKey, (prev: Brains) => ({
      brains: (prev?.brains || []).map((item) =>
        item.brain_id === brain.brain_id ? { ...item, ...brain } : item
      ),
    }));
  }
};

export const onBrainCreated = (queryClient: QueryClient, brain: Brain) => {
  queryClient.setQueryData<Brain>(
    [endpoints.brain(brain.brain_id)],
    (prev: Brain) => ({ ...prev, ...brain })
  );

  const queryKey = [endpoints.brains, brain.account_id];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Brains>(queryKey, (prev) => ({
      brains: [
        ...(prev?.brains || []).filter(
          (acc) => acc.brain_id !== brain.brain_id
        ),
        brain,
      ],
    }));
  }
};

export const onBrainRemoved = (
  queryClient: QueryClient,
  account_id: string,
  brain_id: string
) => {
  const queryKey = [endpoints.brains, account_id];
  if (queryClient.getQueryData(queryKey)) {
    queryClient.setQueryData<Brains>(queryKey, (prev: Brains) => ({
      brains: (prev?.brains || []).filter((acc) => acc.brain_id !== brain_id),
    }));
  }
  queryClient.removeQueries({ queryKey: [endpoints.brain(brain_id)] });
};

const useBrains = (brainId?: string) => {
  const { t } = useTranslation();
  const [refetchIntervall, setRefetchIntervall] = useState(THIRTY_SECONDS);

  const canRead = useSelector((state: RootState) =>
    getPermissions(state, 'brains', actions.READ)
  );
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const accountId = useSelector(selectAccountId);
  const { account } = useAccount();

  const { data: brains, status: listStatus } = useQuery<Brains, Error>({
    queryKey: [endpoints.brains, accountId],
    queryFn: () => API.listBrains(),
    enabled: !!accountId && canRead,
    staleTime: THIRTY_SECONDS * 2, // 1 minute
  });

  const {
    data: brain,
    status: getStatus,
    error: brainError,
  } = useQuery<Brain, Error>({
    queryKey: [endpoints.brain(brainId)],
    queryFn: () => API.getBrain(brainId),
    enabled: !!brainId && canRead,
    refetchInterval: refetchIntervall,
    staleTime: THIRTY_SECONDS * 2, // 1 minute
  });

  useEffect(() => {
    if (brainError) {
      console.error(brainError, 'Error loading brain');
      setRefetchIntervall(THIRTY_SECONDS);
    }
  }, [brainError, setRefetchIntervall]);

  useEffect(() => {
    if (brain?.status) {
      if (brain.status === 'training') {
        setRefetchIntervall(ONE_SECOND);
      } else if (brain.status === 'unavailable') {
        setRefetchIntervall(TEN_SECONDS);
      } else {
        setRefetchIntervall(THIRTY_SECONDS);
      }
    }
  }, [brain?.status]);

  const { mutate: updateBrain, status: updateStatus } = useMutation<
    Brain,
    Error,
    PartialBrain
  >({
    mutationFn: API.updateBrain,
    onSuccess: (resp) => {
      onBrainUpdated(queryClient, resp);
      dispatch(
        addTemporalToast('success', t('brains.updated', { 0: resp.name }))
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const { mutate: createBrain, status: createStatus } = useMutation<
    Brain,
    Error,
    Partial<Brain>
  >({
    mutationFn: API.createBrain,
    onSuccess: (resp) => {
      onBrainCreated(queryClient, resp);
      dispatch(
        addTemporalToast('success', t('brains.created', { 0: resp?.name }))
      );
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const {
    mutate: deleteBrain,
    mutateAsync: deleteBrainAsync,
    status: deleteStatus,
  } = useMutation<Brain, Error, string>({
    mutationFn: (id) => API.deleteBrain(id),
    onSuccess: (resp) => {
      onBrainRemoved(queryClient, accountId, resp.brain_id);
      dispatch(addTemporalToast('success', t('brains.deleted')));
    },
    onError: (error) => {
      dispatch(addErrorTemporalToast(error));
    },
  });

  const exportBrain = async (id: string) => {
    const eBrain = await callGet(`/www/api/v1/brains/${id}?export=true`);
    downloadBrain(eBrain);
    dispatch(
      addTemporalToast('success', t('brains.exported', { 0: eBrain.name }))
    );
  };

  const brainsByLanguage = useCallback(
    (language: string) => {
      return brains?.brains?.filter((b) => b.language === language);
    },
    [brains?.brains]
  );

  const hasSellerBrain = useMemo(
    () => brains?.brains?.some((brain) => brain.brain_type !== 'support'),
    [brains?.brains]
  );

  return {
    brains: brains?.brains,
    brain,
    maxBrains: account?.max_brains,
    maxBrainsReached: brains?.brains?.length >= account?.max_brains,
    maxCustomInstructions:
      brain?.language === 'en' ? 10000 : CUSTOM_INSTRUCTIONS_LENGTH,
    getStatus,
    listStatus,
    createStatus,
    deleteStatus,
    updateStatus,
    createBrain,
    deleteBrain,
    deleteBrainAsync,
    updateBrain,
    exportBrain,
    brainsByLanguage,
    getTemplate: (lang: string) => API.getTemplate(lang),
    hasSellerBrain,
  };
};

export default useBrains;
