import { useWhatsapp } from '@/hooks/useWhatsapp';

import Settings from './Settings';
import Connect from '../Facebook/Connect';

const Whatsapp = () => {
  const {
    integration,
    connectionStatus,
    isManual,
    onManualClick,
    onOAuthClick,
    isConnecting,
  } = useWhatsapp();

  if (!integration) {
    return null;
  }

  // If the integration is connected, or the user is managing it manually,
  // show the settings page
  if (connectionStatus === 'connected' || isManual) {
    return <Settings isManual={isManual} />;
  }

  return (
    <Connect
      onOAuthClick={onOAuthClick}
      onManualClick={onManualClick}
      isConnecting={isConnecting}
    />
  );
};

export default Whatsapp;
