import Tooltip from '@mui/material/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';

import Table from '@/components/atoms/Table/Table';
import AnalyticsFieldset from '@/modules/analytics/components/fieldsets/AnalyticsFieldset/AnalyticsFieldset';
import { convertTimestampToDate } from '@/util/analytics';
import { numberFormatter } from '@/util/util';

import { sortColumns } from './MostUsedDesksFieldset';
import useMostUsedBrains from '../../../hooks/useMostUsedBrains';

const getColumns = (t) => [
  {
    Header: t('brains.brain_name'),
    accessor: 'name',
  },
  {
    Header: t('common.conversations'),
    accessor: 'num_sessions',
    sortType: sortColumns,
    Cell: ({ value }) => {
      const formattedValue = numberFormatter(value);
      return (
        <Tooltip arrow title={formattedValue !== value ? value : ''}>
          <span>{formattedValue}</span>
        </Tooltip>
      );
    },
  },
  {
    Header: t('common.messages'),
    accessor: 'num_requests',
    sortType: sortColumns,
    Cell: ({ value }) => {
      const formattedValue = numberFormatter(value);
      return (
        <Tooltip arrow title={formattedValue !== value ? value : ''}>
          <span>{formattedValue}</span>
        </Tooltip>
      );
    },
  },
  {
    Header: t('common.customers'),
    accessor: 'num_users',
    sortType: sortColumns,
    Cell: ({ value }) => {
      const formattedValue = numberFormatter(value);
      return (
        <Tooltip arrow title={formattedValue !== value ? value : ''}>
          <span>{formattedValue}</span>
        </Tooltip>
      );
    },
  },
  {
    Header: t('common.last_used'),
    accessor: 'last_used',
    sortType: sortColumns,
    Cell: ({ value }) => {
      return (
        <Tooltip arrow title={value}>
          <span key={value}>{value ? convertTimestampToDate(value) : '-'}</span>
        </Tooltip>
      );
    },
  },
];

const MostUsedBrainFieldset = () => {
  const { isLoading, data: brainsData, error, refetch } = useMostUsedBrains();
  const { t } = useTranslation();
  const title = t('analytics.most_brains');

  const handleReload = () => {
    refetch();
  };

  return (
    <AnalyticsFieldset
      fullWidth
      title={title}
      hasFooter={false}
      isEmpty={!brainsData && !isLoading}
      isLoading={isLoading}
      error={error}
      onReload={handleReload}
      data={brainsData}
    >
      {brainsData && (
        <Table
          data={brainsData}
          columns={getColumns(t)}
          sortable
          filterable={brainsData.length > 10}
          sortBy="last_used"
        />
      )}
    </AnalyticsFieldset>
  );
};

export default MostUsedBrainFieldset;
