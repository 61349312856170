import React from 'react';

import { shallowEqual, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router';

import { selectUser } from '@/redux/user/selectors';

import ErrorBoundary from '../ErrorBoundary';

export interface RouteElementProps {
  auth?: boolean;
  comp: React.ComponentType;
}

const RouteElement = (props: RouteElementProps) => {
  const { comp: OriginalComponent, auth = true } = props;
  const location = useLocation();
  const user = useSelector(selectUser, shallowEqual);
  if (!user && auth) {
    return (
      <Navigate
        to={{ pathname: '/login' }}
        state={`${location.pathname}${location?.search}${location.hash}`}
      />
    );
  }

  return (
    <ErrorBoundary>
      <OriginalComponent />
    </ErrorBoundary>
  );
};

export default RouteElement;
